import { Amplify, Auth, Logger } from 'aws-amplify';

import baseUrl from 'utils/baseUrl';
import cookieDomain from 'utils/cookieDomain';

interface PasswordProtectionSettings {
  passwordPolicyMinLength: string;
  passwordPolicyCharacters: string[];
}

interface AwsConfig {
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {
    domain: string;
    redirectSignIn: string;
    redirectSignOut: string;
  };
  aws_cognito_username_attributes: string[];
  aws_cognito_social_providers: string[];
  aws_cognito_signup_attributes: string[];
  aws_cognito_mfa_configuration: string;
  aws_cognito_mfa_types: string[];
  aws_cognito_password_protection_settings: PasswordProtectionSettings;
  aws_cognito_verification_mechanisms: string[];
}

const awsConfig = JSON.parse(process.env.NEXT_PUBLIC_AWS_EXPORT) as AwsConfig;

// Override the oauth domain as Cognito overrides it with the Cognito domain whenever we pull the config from the cloud
// Value without https:// and no trailing slash
// https://github.com/aws-amplify/amplify-cli/issues/1834
awsConfig.oauth.domain = process.env.NEXT_PUBLIC_AUTH_URL.replace('https://', '');
awsConfig.oauth.redirectSignIn = `${baseUrl}/account/provider-login-redirect/`;
awsConfig.oauth.redirectSignOut = `${baseUrl}/account/provider-logout-redirect/`;

type ConfigureOptions = {
  ssr?: boolean;
};

const configureAmplify = (opts?: ConfigureOptions) => {
  const date = new Date().toISOString().split('T')[0]; // e.g. 2022-02-17
  const logGroupName = `store-www`;
  const logStreamName = `${logGroupName}_${date}.log`;

  const { oauth, ...amplifyConfig } = awsConfig;

  // Set the redirect paths for OAuth

  // Configure AWS with SSR and cookie storage
  Amplify.configure({
    ...amplifyConfig,
    /**
     * 06-05-2024: Removing `ssr: true` because it copied all cookies into localStorage for some reason
     * After testing, it seems that everything stays working even when not provided.
     * This is probably because cookieStorage is configured below
     *
     * 10-07-2024: Re-adding `ssr: true` with param because it's required for API routes to work (e.g. pet-profiles get-owner)
     */
    ...(opts?.ssr ? { ssr: true } : {}),
    Logging: {
      logGroupName,
      logStreamName,
    },
  });

  Auth.configure({
    oauth,
    cookieStorage: { domain: cookieDomain, sameSite: 'lax' },
  });

  /**
   * Don't log AWS errors during build step
   */
  if (process.env.CI) {
    /**
     * A little bit hacky but for now I don't find any other way to
     * turn off the error logging from AWS during build time
     */
    // eslint-disable-next-line no-underscore-dangle
    Logger.prototype._log = () => {};
  }
};

export default configureAmplify;
