import { IncomingMessage } from 'http';

import { serialize, parse } from 'cookie';
import Cookies from 'js-cookie';
import { NextApiResponse, NextApiRequest } from 'next';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { CookieNames } from 'utils/constants';

export const MAX_AGE = 1000 * 365 * 24 * 60 * 60; // 1 year

const parseCookies = (
  req:
    | NextApiRequest
    // GetServerSideProps context
    | (IncomingMessage & {
        cookies: NextApiRequestCookies;
      }),
): { [key: string]: string } => {
  // For API Routes we don't need to parse the cookies.
  if (req.cookies) return req.cookies;

  // For pages we do need to parse the cookies.
  const cookie = req.headers?.cookie;
  return parse(cookie || '');
};

export const getClientSideCookie = (cookieName: CookieNames): string => Cookies.get(cookieName);

type CookieOptions = {
  /**
   * Days until the cookie expires.
   * Setting this to `undefined` will result in a session cookie
   */
  expires: number | Date | undefined;
};

export const setClientSideCookie = (
  cookieName: CookieNames,
  cookieValue: string,
  options?: CookieOptions,
): void => {
  Cookies.set(cookieName, cookieValue, { expires: 365, ...options });
};

export const removeClientSideCookie = (cookieName: CookieNames): void => {
  Cookies.remove(cookieName);
};

export const setServerSideCookie = (
  res: NextApiResponse,
  cookieName: CookieNames,
  cookieValue: string,
): void => {
  const cookie = serialize(cookieName, cookieValue, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE),
    httpOnly: true,
    secure: process.env.VERCEL_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });

  res.setHeader('Set-Cookie', cookie);
};

export const removeServerSideCookie = (res: NextApiResponse, cookieName: CookieNames): void => {
  const cookie = serialize(cookieName, '', {
    maxAge: -1,
    path: '/',
  });

  res.setHeader('Set-Cookie', cookie);
};

export const getServerSideCookie = (
  req:
    | NextApiRequest
    // GetServerSideProps context
    | (IncomingMessage & {
        cookies: NextApiRequestCookies;
      }),
  cookieName: CookieNames,
): string => {
  const cookies = parseCookies(req);
  return cookies[cookieName];
};

export const getCookieConsentCategoriesFromCookie = () => {
  const cookieValue = getClientSideCookie(CookieNames.CookieConsent);
  const decodedValue = decodeURIComponent(cookieValue);
  const keyValuePairs = decodedValue
    .slice(1, -1)
    .split(',')
    .map((pair) => pair.split(':'));
  const parsedValue = Object.fromEntries(
    keyValuePairs.map(([key, value]) => [key, value?.replace(/'/g, '')]),
  );
  return {
    preferences: parsedValue.preferences === 'true',
    statistics: parsedValue.statistics === 'true',
    marketing: parsedValue.marketing === 'true',
  };
};
