// https://github.com/segmentio/snippet
import * as snippet from '@segment/snippet';

const segmentSnippet = (): string => {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    page: false,
  };

  // The replace function removes the analytics load, we will only load the analytics when we have cookie consent present
  // We will load it in TrackingProvider
  return snippet.min(opts).replace(/analytics\.load\("[^"]+"\);/g, '');
};

export default segmentSnippet;
