// can be extended if needed
const mediaQueries = {
  0: 0,
  s: 640,
  m: 768,
  md: 768,
  lg: 1024,
} as const;

export default mediaQueries;
