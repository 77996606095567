import { Image } from 'api/generated-types';
import ECImage from 'components/ui/ECImage';
import Icon from 'components/ui/Icon';

export interface Props {
  title: string;
  image: Image;
  className: string;
}

const MenuItem: React.FC<Props> = ({ title, image, className }) => (
  <div
    className={`transition flex grow p-4 lg:p-6 m-2 uppercase rounded-xl
      justify-center items-center transform hover:scale-105 ${className}`}
  >
    <ECImage img={image} className="h-12 mr-8" />
    <div className="text-white text-2xl inline-flex items-center flex-gap-x-2">
      <span>{title}</span>
      <Icon name="arrow-right" className="w-5" />
    </div>
  </div>
);

export default MenuItem;
