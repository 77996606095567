import { elementScrollIntoView } from 'seamless-scroll-polyfill';

const scrollIntoViewIfNeeded = (element: HTMLElement): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((element as any).scrollIntoViewIfNeeded) {
    // Not supported on all browsers
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (element as any).scrollIntoViewIfNeeded(true);
  } else {
    // Fallback
    elementScrollIntoView(element, { block: 'center' });
  }
};

export default scrollIntoViewIfNeeded;
