import { gql } from 'graphql-request';

import { bffRequest } from 'api/client/bff-request';
import { Query } from 'api/generated-types';

const query = gql`
  query isProductVariantInStock($countryCode: String!, $universalKeys: [String!]!) {
    productVariants(countryCode: $countryCode, locale: "en", universalKeys: $universalKeys) {
      actuals {
        isInStock
      }
    }
  }
`;

interface QueryVariables {
  countryCode: string;
  universalKeys: string[];
}

const isProductVariantInStock = async (
  countryCode: string,
  universalKey: string,
): Promise<boolean> =>
  bffRequest<Query, QueryVariables>({
    query,
    variables: { countryCode, universalKeys: [universalKey] },
  }).then((data) => data.productVariants?.[0]?.actuals?.isInStock);

export default isProductVariantInStock;
