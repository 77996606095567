import React from 'react';

import classNames from 'classnames';

import Label, { Props as LabelProps } from 'components/ui/Label';

export interface Props {
  /** Content to display inside the connected */
  children?: React.ReactNode;
  /** Error to display */
  error?: string | boolean;
  /** Additional hint text to display */
  helpText?: string;
  /** A unique identifier for the label */
  id: LabelProps['id'];
  /** Text for the label */
  label: string;
  /** Element next to the label */
  labelAside?: React.ReactNode;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Different color */
  isCat?: boolean;
}

const Labelled: React.FC<Props> = ({
  children,
  id,
  label,
  labelAside,
  labelHidden,
  helpText,
  error,
  isCat,
}: Props) => {
  const labelMarkup = label ? (
    <>
      <Label id={id} hidden={labelHidden} className={classNames(isCat && 'text-purple-700')}>
        {label}
      </Label>
    </>
  ) : null;

  const labelAsideMarkup = labelAside ? <>{labelAside}</> : null;

  const errorMarkup = error && typeof error !== 'boolean' && (
    <div className="text-red-400">{error}</div>
  );

  const helpTextMarkup = helpText ? <div className="text-gray-200 mt-0.5">{helpText}</div> : null;

  const descriptionMarkup =
    helpTextMarkup || errorMarkup ? (
      <div className="text-base mt-1">
        {errorMarkup}
        {helpTextMarkup}
      </div>
    ) : null;

  return (
    <div className="flex flex-col grow">
      {labelAsideMarkup ? (
        <div className="flex">
          {labelMarkup}
          {labelAsideMarkup}
        </div>
      ) : (
        labelMarkup
      )}
      {children}
      {descriptionMarkup}
    </div>
  );
};

export default Labelled;
