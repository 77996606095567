import React from 'react';

import queryString from 'query-string';

import { Image } from 'api/generated-types';

export interface SrcOptions {
  w?: number;
  h?: number;
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
  q?: number;
}

export interface Props {
  img: Image;
  className?: string;
  pictureClassName?: string;
  style?: React.CSSProperties;
  width?: string | number;
  height?: string | number;
  srcOptions?: SrcOptions;
  /** @default 'lazy' */
  loading?: 'lazy' | 'eager';
  overrideMobile?: boolean;
}

const ECImage: React.FC<Props> = ({
  img,
  srcOptions,
  pictureClassName,
  className,
  loading = 'lazy',
  overrideMobile,
  ...props
}: Props) => {
  if (!img) {
    return <></>;
  }

  const getImageUrl = (image: Image, type: 'webp', srcOps: SrcOptions): string =>
    queryString.stringifyUrl({
      url: image.src,
      query:
        image.src?.endsWith('.gif') && srcOptions?.w === 800
          ? undefined
          : {
              q: 80,
              fm: image.src.endsWith('.gif') ? undefined : type,
              ...srcOps,
            },
    });

  /**
   * fallback mobile width was adjusted from 600 to 1200
   */
  let mobileWidth =
    /* eslint-disable-next-line no-nested-ternary */
    srcOptions?.w && srcOptions?.w < 600 ? srcOptions.w : overrideMobile ? srcOptions?.w : 1200;
  let desktopWidth = srcOptions?.w || 800;
  /**
   * Reset to original width if the image had set a height.
   */
  if (srcOptions?.h) {
    mobileWidth = srcOptions?.w;
    desktopWidth = srcOptions?.w;
  }

  const imgUrl = getImageUrl(img, undefined, { ...srcOptions, w: desktopWidth });

  if (img.src.includes('svg')) {
    return (
      <div className={pictureClassName} {...props}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={img.src} alt={img.alt} className={className} {...props} loading={loading} />
      </div>
    );
  }

  return (
    <picture
      className={pictureClassName}
      // This is used by lightgallery
      data-src={imgUrl}
    >
      <source
        media="(max-width: 600px)"
        srcSet={`${getImageUrl(img, 'webp', { ...srcOptions, w: mobileWidth })}`}
        type="image/webp"
      />
      <source
        media="(min-width: 600px)"
        srcSet={`${getImageUrl(img, 'webp', { ...srcOptions, w: desktopWidth })}`}
        type="image/webp"
      />
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={getImageUrl(img, undefined, { ...srcOptions, w: desktopWidth })}
        alt={img.alt}
        className={className}
        {...props}
        loading={loading}
      />
    </picture>
  );
};

export default ECImage;
