import { z } from 'zod';

import { getClientSideCookie, setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

const cartIdSchema = z.string().startsWith('gid://shopify/Cart/');

const getCartIdFromCookie = (): string | undefined => {
  let cartId;
  try {
    cartId = cartIdSchema.parse(getClientSideCookie(CookieNames.CartId));
  } catch (e) {
    // Do nothing, cookie wasn't valid
  }
  return cartId;
};

/**
 * @private Hook that returns a function to fetch cart from Shopify
 */
const useFetchCartFromShopify = () => {
  const fetchCartFromShopify = async () => {
    /**
     * Read out cart id from cookie
     */
    const cartId = getCartIdFromCookie();

    /**
     * Fetch cart from Shopify if available,
     * if not: create new cart
     */
    let shopifyCart:
      | Awaited<ReturnType<typeof storefrontSDK.cartCreate>>['cartCreate']['cart']
      | Awaited<ReturnType<typeof storefrontSDK.getCart>>['cart'];

    let shouldCreateCart = false;

    if (!cartId) {
      shouldCreateCart = true;
    } else {
      /**
       * If cart id, Use cart id to fetch Shopify Cart (Storefront API)
       * If call should fail, let it fail and throw error, fetchCart will be retried 3 times.
       */
      shopifyCart = await storefrontSDK
        .getCart({
          id: cartId,
        })
        .then((res) => {
          // Cart was completed case, create new cart
          if (res.cart === null) {
            shouldCreateCart = true;
          }

          return res.cart;
        });
    }

    if (shouldCreateCart) {
      /**
       * Create new cart in Shopify
       * Hold userErrors into account (throw Error)
       */
      shopifyCart = await storefrontSDK.cartCreate().then((res) => {
        if (res.cartCreate.userErrors.length > 0) {
          throw new Error(
            `Could not create cart due to userErrors: ${JSON.stringify(res.cartCreate.userErrors)}`,
          );
        }

        return res.cartCreate.cart;
      });

      setClientSideCookie(CookieNames.CartId, shopifyCart.id);
    }

    if (!shopifyCart.id) {
      throw new Error(`Shopify cart id is empty: ${shopifyCart}`);
    }

    return shopifyCart;
  };

  return fetchCartFromShopify;
};

export default useFetchCartFromShopify;
