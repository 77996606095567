import i18n, { TFunction } from 'i18next';

import layoutTranslations from 'layouts/translations';
import logger from 'utils/logger';

const initI18n = async (locale: string): Promise<TFunction> =>
  i18n.init({
    lng: locale,
    fallbackLng: 'en',
    parseMissingKeyHandler: (key) => {
      logger.warn(`Missing translation key: ${key}`);
      return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '' : key;
    },
    interpolation: {
      format(value, format) {
        if (!value) return value;
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
    resources: layoutTranslations,
    // https://www.i18next.com/how-to/faq#process
    appendNamespaceToCIMode: process.env.VERCEL_ENV !== 'production',
  });

export default initI18n;
