import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
  useCallback,
} from 'react';

import * as Sentry from '@sentry/nextjs';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import { useCountryContext } from 'context/CountryContextProvider';
import handleSignInUser from 'services/auth/handleSignInUser';
import identify from 'services/klaviyo/identify';
import { CurrentAuthenticatedUser } from 'services/session/types/CurrentAuthenticatedUser';
import { CookieNames } from 'utils/constants';
import logger from 'utils/logger';
import { capitalizeFirstLetter } from 'utils/stringUtils';

import { getClientSideCookie, setClientSideCookie } from './cookies';

interface ContextType {
  user: Partial<CurrentAuthenticatedUser>;
  setCurrentAuthenticatedUser: Dispatch<SetStateAction<Partial<CurrentAuthenticatedUser>>>;
  sessionId: string;
  isFederatedUser: boolean;
  provider: string;
  prevPath: string;
  currentPath: string;
  isLoading: boolean;
}
const Context = createContext<ContextType>(null);

export const useSessionContext: () => ContextType = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useSessionContext must be used within a SessionContextProvider tag');
  }
  return contextState;
};

/**
 * The session id is useful for tracking the journey of a user in our logs
 * With this id, we can filter the logs for a particular session,
 * e.g. an error occurred and we want to see the previous calls that happened.
 *
 * This session id is also stored in a session cookie,
 * as we want to also be able to access it in our back-end environment,
 * or when we don't have easy access to the useSessionContext hook.
 */
const getSessionIdAndSetCookie = () => {
  const sessionId = getClientSideCookie(CookieNames.SessionId) || uuidv4();
  setClientSideCookie(CookieNames.SessionId, sessionId, { expires: undefined });
  return sessionId;
};

/**
 * Provides the app with `email` of the authenticated user. This is null if there is no authenticated user or
 * if we are in a loading/error state.
 * @param children
 * @constructor
 */
const SessionContextProvider: FC<{ children }> = ({ children }) => {
  // unique session id, used to track the user journey
  const sessionIdRef = useRef<string>(getSessionIdAndSetCookie());

  const [currentAuthenticatedUser, setCurrentAuthenticatedUser] =
    useState<Partial<CurrentAuthenticatedUser>>(null);

  const [currentPath, setCurrentPath] = useState(null);
  const [prevPath, setPrevPath] = useState(null);
  const [isFederatedUser, setIsFederatedUser] = useState(null);
  const [provider, setProvider] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const { locale } = router;
  const { countryCode, isLoading: isLoadingCountryContext } = useCountryContext();

  const checkCurrentAuthenticatedUser = useCallback(() => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser({
      bypassCache:
        !currentAuthenticatedUser?.customShopifyUserId || !currentAuthenticatedUser?.userLocale,
    })
      .then(async (user) => {
        // Try to link shopify id if user doesn't have it
        // This is a fix for logged in users that don't have a linked shopify id
        // had to add locale !== 'default' to prevent calling this on the callback url from provider login flow
        // also added the case when the locale isn't filled in, to fix the cases without a userlocale
        // added case when userLocale is default to update it to valid locale
        if (
          user &&
          (!user?.attributes?.['custom:shopifyUserId'] ||
            user?.attributes?.['custom:userLocale'] !== locale ||
            user?.attributes?.['custom:userCountryCode'] !== countryCode) &&
          /** Check if currentAuthenticatedUser has been updated with the previously mssing attributes,
           * because otherwise it will trigger handleSignInUser twice because the user in Cognito has yet to be updated
           * after current currentAuthenticatedUser has been updated
           * NOTE: this could not be changed in the authentication service itself because of this comment in the function’s response:
           * ---
           * We're adding the customShopifyUserId in the response because it's already added to Cognito in the line above,
           * but not in the response.
           * When this function (handleSignInUser) is called the first time during registration,
           * we need the customShopifyUserId to identify the user in Segment.
           * ---
           */
          (!currentAuthenticatedUser?.customShopifyUserId ||
            currentAuthenticatedUser?.userLocale !== locale ||
            currentAuthenticatedUser?.userCountryCode !== countryCode) &&
          locale !== 'default'
        ) {
          return handleSignInUser(locale, isLoadingCountryContext ? null : countryCode)
            .then(({ customShopifyUserId, userLocale }) => {
              logger.info(
                `Succesfully linked shopifyUserId for ${user?.attributes?.email} on routeChange`,
              );
              // Return result in same format as above "user"
              return {
                username: user?.username,
                attributes: {
                  ...user?.attributes,
                  'custom:shopifyUserId': customShopifyUserId,
                  'custom:userLocale': userLocale,
                },
              };
            })
            .catch((error) => {
              logger.error(
                `Failed to link shopifyUserId for ${user?.attributes?.email} on routeChange`,
                {
                  error,
                },
              );
            });
        }
        // If not relinked, just pass down user
        return user;
      })
      .then((user) => {
        if (user?.attributes) {
          // Set user for this session
          setCurrentAuthenticatedUser({
            ...user?.attributes,
            emailVerified: user?.attributes?.email_verified || false,
            customShopifyUserId: user?.attributes?.['custom:shopifyUserId'],
            userLocale: user?.attributes?.['custom:userLocale'],
            userCountryCode: user?.attributes?.['custom:userCountryCode'],
            username: user?.username,
          });
        } else {
          setCurrentAuthenticatedUser(null);
        }
      })
      .catch(() => {
        setCurrentAuthenticatedUser(null);
      })
      .finally(() => setIsLoading(false));
  }, [countryCode, currentAuthenticatedUser, locale, isLoadingCountryContext]);

  // checks if the user still has valid credentials on every page transition. Think of multiple tabs open scenarios etc.
  useEffect(() => {
    router.events.on('routeChangeComplete', checkCurrentAuthenticatedUser);

    return () => {
      router.events.off('routeChangeComplete', checkCurrentAuthenticatedUser);
    };
  }, [checkCurrentAuthenticatedUser, router.events]);

  /**
   * Check authentication first time on start up
   */
  useEffect(() => {
    checkCurrentAuthenticatedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCountryContext]);

  useEffect(() => {
    setPrevPath(currentPath);
    setCurrentPath(global.location?.pathname + global.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    Sentry.setUser(currentAuthenticatedUser);
    if (currentAuthenticatedUser) {
      identify(currentAuthenticatedUser.email);
    }
  }, [currentAuthenticatedUser]);

  useEffect(() => {
    setIsFederatedUser(
      currentAuthenticatedUser &&
        (currentAuthenticatedUser.username?.includes('facebook_') ||
          currentAuthenticatedUser.username?.includes('google_')),
    );
    if (currentAuthenticatedUser) {
      setProvider(
        currentAuthenticatedUser?.username?.split('_')?.[0]
          ? capitalizeFirstLetter(currentAuthenticatedUser?.username?.split('_')?.[0])
          : 'Cognito',
      );
    }
  }, [currentAuthenticatedUser]);

  return (
    <Context.Provider
      value={{
        user: currentAuthenticatedUser,
        setCurrentAuthenticatedUser,
        isFederatedUser,
        provider,
        sessionId: sessionIdRef.current,
        prevPath,
        currentPath,
        isLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SessionContextProvider;
