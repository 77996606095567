import classNames from 'classnames';
import { ReactSVG, Props as ReactSVGProps } from 'react-svg';

import styles from './icon.module.css';

export type Props = {
  /** The SVG contents to display in the icon */
  src?: string;
  /** The name of the icon in our icons lib */
  name?: string;
  /** Additional classNames */
  className?: string;
  /** A aria-label for SEO purposes */
  label?: string;
} & Pick<ReactSVGProps, 'aria-hidden'>;

const Icon: React.FC<Props> = ({ src, name, className = 'w-6', label, ...props }: Props) => (
  <ReactSVG
    {...props}
    className={classNames(styles.icon, className)}
    src={src || `/icons/${name}.svg`}
    aria-hidden={!label}
    aria-label={label}
  />
);

export default Icon;
