import { createWrapper, resolveWhen } from '@segment/analytics-consent-tools';

import { getClientSideCookie } from 'services/session';
import { getCookieConsentCategoriesFromCookie } from 'services/session/cookies';
import { CookieNames } from 'utils/constants';

const analyticsWrapperThatWaitsForCookieConsent = createWrapper({
  // Wait to load wrapper or call "shouldLoadSegment" until CookieConsent cookie exists.
  shouldLoadWrapper: async () => {
    await resolveWhen(() => getClientSideCookie(CookieNames.CookieConsent) != null, 500);
  },

  // Allow for control over wrapper + analytics initialization.
  // Delay any calls to analytics.load() until this function returns / resolves.
  shouldLoadSegment: async (ctx) => {
    await resolveWhen(() => getClientSideCookie(CookieNames.CookieConsent) != null, 500);
    return ctx.load({ consentModel: 'opt-in' });
  },

  getCategories: () => getCookieConsentCategoriesFromCookie(),

  registerOnConsentChanged: (_setCategories) => {
    // we don't use this functionality, when user refreshes browser and the settings changed it will be fixed automatically
  },
});

export default analyticsWrapperThatWaitsForCookieConsent;
