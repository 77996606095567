const translations = {
  en: {
    error500: {
      title: 'An error occurred',
      description: 'Something went wrong on our side, sorry for the inconvenience.',
      button: 'Go to home',
    },
    notFound: {
      title: 'Page not found',
      description: "We couldn't find the page you were looking for, sorry for the inconvenience.",
      button: 'Go back',
    },
    error: {
      message: 'Oops! Something went wrong...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'This page is currently unavailable due to maintenance. It should be available again shortly. We apologize for any inconvenience caused.',
    },
    shipping: {
      'deliveryPromise.tomorrow': 'Order before {{cutOffTime}}, get it tomorrow!',
      'deliveryPromise.onDay.beforeCutOff': 'Order before {{cutOffTime}} and get it on {{day}}!',
      'deliveryPromise.onDay.afterCutOff': 'Order now, get it on {{day}}!',
      'day.0': 'Sunday',
      'day.1': 'Monday',
      'day.2': 'Tuesday',
      'day.3': 'Wednesday',
      'day.4': 'Thursday',
      'day.5': 'Friday',
      'day.6': 'Saturday',
      deliveryInfo: `We are doing our best to deliver your order on time.
      However, there may be occasional delays due to unforeseen circumstances with the delivery service.
      We appreciate your understanding!`,
    },
    header: {
      dogs: 'Shop dogs',
      cats: 'Shop cats',

      allProducts: 'All products',
      dryFood: 'Dry food',
      wetFood: 'Wet food',
      treats: 'Treats',
      dental: 'Dental',
      bundle: 'Bundles',
      forPuppies: 'For puppies',
      forKittens: 'For kittens',
      festive: 'Christmas',

      storeFinder: 'Stores',
      learn: 'Learn',
      ourFood: 'Our food',
      ourStory: 'Our story',
      ourPlanet: 'Our planet',
      account: 'My account',
      login: 'Log in',

      impactReport: 'Impact report 23',

      countryLanguage: 'Select country and language',
      cart: 'Shopping cart',

      productQuizTitle: 'Find the best recipes for {{ animal }}',
      productQuizTitleAnimalDog: 'your dog',
      productQuizTitleAnimalCat: 'your cat',
      productQuizCompletedTitle: "{{name}}'s recommended recipes",
      productQuizCta: 'Start quiz',
      productQuizCompletedCta: 'View recipes',

      stickerSave5: 'Save 5%',
      stickerNew: 'New',
    },
    footer: {
      titleShop: 'Shop',
      dogs: 'Shop dogs',
      cats: 'Shop cats',
      titleLearn: 'Learn',
      ourFood: 'Our food',
      ourStory: 'Our story',
      ourPlanet: 'Our planet',
      impactReport: 'Impact report 23',
      titleSupport: 'Support',
      contact: 'Contact',
      shippingAndReturns: 'Delivery & returns',
      faq: 'FAQ',
      careers: 'Careers',
      hiring: "We're hiring",
      foundersLetter: "Founder's letter",

      followInstagram: 'Follow us on Instagram',
      followFacebook: 'Follow us on Facebook',
      followTiktok: 'Follow us on TikTok',
      followLinkedin: 'Follow us on LinkedIn',

      termsAndConditions: 'Terms & conditions',
      privacyPolicy: 'Privacy policy',
      bCorpCertificationNotice: 'B Corp Certification Notice',
      countryLanguage: 'Select country and language',
    },
    localeModal: {
      title: 'Select your country and language',
      country: 'Your country',
      language: 'Your language',
    },
    navigation: {
      title: 'Navigation',
      productQuizTitle: 'Find the right food for your furry friend',
    },
    authentication: {
      goBack: 'Go back',
    },
    cartDrawer: {
      title: 'Your selection',
      earnedFreeShipping: "You've earned free shipping!",
      xAwayFromFreeShipping: 'You are {{amount}} away from free shipping.',
      freeShippingFrom: 'Free shipping on orders over {{amount}}.',
      cartEmpty: 'It seems like your cart is empty.',
      cartEmptyWhyNotFill: 'Why not fill it up?',
      summarySubtotal: 'Subtotal',
      oneItem: 'item',
      numberOfItems: '{{amount}} items',
      free: 'Free',
      shipping: 'Shipping',
      goToCart: 'Go to cart',
      outOfStock: 'Out of stock',
    },
    cartGlobal: {
      errorMessageDefault:
        'We encountered an issue retrieving your cart. Please refresh the page. If the problem persists, contact our customer service at help@edgardcooper.com or via live chat.',
    },
  },
  nl: {
    error500: {
      title: 'Er is een fout opgetreden',
      description: 'Er is iets verkeerd gegaan aan onze kant, excuses voor het ongemak.',
      button: 'Naar homepagina',
    },
    notFound: {
      title: 'Pagina niet gevonden',
      description: 'Sorry! De pagina die je zocht, konden we niet vinden.',
      button: 'Ga terug',
    },
    error: {
      message: 'Oeps! Er ging iets mis...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'Deze pagina is momenteel niet beschikbaar wegens onderhoud. Hij zou binnenkort weer beschikbaar moeten zijn. Onze excuses voor het ongemak.',
    },
    shipping: {
      'deliveryPromise.tomorrow': 'Besteld voor {{cutOffTime}}, morgen ontvangen.',
      'deliveryPromise.onDay.beforeCutOff': 'Besteld voor {{cutOffTime}}, {{day}} ontvangen!',
      'deliveryPromise.onDay.afterCutOff': 'Nu besteld, {{day}} ontvangen!',
      'day.0': 'zondag',
      'day.1': 'maandag',
      'day.2': 'dinsdag',
      'day.3': 'woensdag',
      'day.4': 'donderdag',
      'day.5': 'vrijdag',
      'day.6': 'zaterdag',
      deliveryInfo: `We doen ons best om je bestelling op tijd te bezorgen.
      Toch kunnen er soms vertragingen optreden door onvoorziene omstandigheden bij de bezorgdienst. We hopen op je begrip!`,
    },
    header: {
      dogs: 'Shop hond',
      cats: 'Shop kat',
      bundle: 'Bundels',
      allProducts: 'Alle producten',
      dryFood: 'Brokjes',
      wetFood: 'Natvoer',
      treats: 'Snacks',
      dental: 'Dental',
      forPuppies: 'Voor puppies',
      forKittens: 'Voor kittens',
      festive: 'Kerst',

      storeFinder: 'Winkelpunten',
      learn: 'Ontdekken',
      ourFood: 'Onze voeding',
      ourStory: 'Ons verhaal',
      ourPlanet: 'Onze planeet',
      account: 'Mijn account',
      login: 'Inloggen',

      impactReport: 'Impact report 23',

      countryLanguage: 'Selecteer land en taal',
      cart: 'Winkelwagen',

      productQuizTitle: 'Vind de beste recepten voor {{ animal }}',
      productQuizTitleAnimalDog: 'jouw hond',
      productQuizTitleAnimalCat: 'jouw kat',
      productQuizCompletedTitle: 'Aanbevolen recepten voor {{name}}',
      productQuizCta: 'Quiz starten',
      productQuizCompletedCta: 'Toon recepten',

      stickerSave5: 'Bespaar 5%',
      stickerNew: 'Nieuw',
    },
    footer: {
      titleShop: 'Shop',
      dogs: 'Shop voor je hond',
      cats: 'Shop voor je kat',
      titleLearn: 'Ontdekken',
      ourFood: 'Onze voeding',
      ourStory: 'Ons verhaal',
      ourPlanet: 'Onze planeet',
      impactReport: 'Impact report 23',
      titleSupport: 'Klantenservice',
      contact: 'Contact',
      shippingAndReturns: 'Bezorgen & retourneren',
      faq: 'FAQ',
      careers: 'Jobs',
      hiring: 'Medewerkers gezocht',
      foundersLetter: 'Brief van de oprichters',

      followInstagram: 'Volg ons op Instagram',
      followFacebook: 'Volg ons op Facebook',
      followTiktok: 'Volg ons op TikTok',
      followLinkedin: 'Volg ons op LinkedIn',

      termsAndConditions: 'Algemene voorwaarden',
      privacyPolicy: 'Privacybeleid',
      bCorpCertificationNotice: 'Kennisgeving van Certificering B Corp',
      countryLanguage: 'Selecteer land en taal',
    },
    localeModal: {
      title: 'Selecteer je land en taal',
      country: 'Uw land',
      language: 'Uw taal',
    },
    navigation: {
      title: 'Menu',
      productQuizTitle: 'Vind de juiste maaltijd voor jouw viervoeter',
    },
    authentication: {
      goBack: 'Ga terug',
    },
    cartDrawer: {
      title: 'Uw keuze',
      earnedFreeShipping: 'Je hebt gratis verzending verdiend!',
      xAwayFromFreeShipping: 'Je bent {{ amount }} verwijderd van gratis verzending.',
      freeShippingFrom: 'Gratis verzending vanaf {{amount}}.',
      cartEmpty: 'Het lijkt erop dat je winkelwagentje leeg is.',
      cartEmptyWhyNotFill: 'Waarom niet opvullen?',
      summarySubtotal: 'Subtotaal',
      oneItem: 'artikel',
      numberOfItems: '{{amount}} artikelen',
      free: 'Gratis',
      shipping: 'Verzending',
      goToCart: 'Naar winkelwagen',
      outOfStock: 'Niet op voorraad',
    },
    cartGlobal: {
      errorMessageDefault:
        // eslint-disable-next-line max-len
        'Er is een probleem opgetreden bij het ophalen van je winkelwagen. Ververs de pagina. Als het probleem blijft, neem dan contact op met onze klantenservice via help@edgardcooper.com of via de live chat.',
    },
  },
  fr: {
    error500: {
      title: 'Une erreur est survenue',
      description: "Quelque chose s'est mal passé de notre côté, excuses pour la gêne occasionnée.",
      button: "Vers la page d'accueil",
    },
    notFound: {
      title: 'Page introuvable',
      description: "Désolé ! Nous n'avons pas trouvé la page que vous cherchiez.",
      button: 'Retour',
    },
    error: {
      message: 'Oups ! Quelque chose a mal tourné...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'Cette page est actuellement indisponible pour cause de maintenance. Elle devrait être à nouveau disponible sous peu.',
    },
    shipping: {
      'deliveryPromise.tomorrow':
        'Commandez avant {{cutOffTime}} et recevez votre commande demain !',
      'deliveryPromise.onDay.beforeCutOff':
        'Commandez avant {{cutOffTime}} et recevez votre commande {{day}} !',
      'deliveryPromise.onDay.afterCutOff': 'Commandez maintenant et soyez livré {{day}} !',
      'day.0': 'dimanche',
      'day.1': 'lundi',
      'day.2': 'mardi',
      'day.3': 'mercredi',
      'day.4': 'jeudi',
      'day.5': 'vendredi',
      'day.6': 'samedi',
      deliveryInfo: `Nous faisons de notre mieux pour livrer votre commande à temps.
      Cependant, il peut y avoir des retards occasionnels dus à des circonstances imprévues avec le service de livraison. Nous vous remercions de votre compréhension !`,
    },
    header: {
      dogs: 'Boutique chiens',
      cats: 'Boutique chats',
      bundle: 'Packs',
      allProducts: 'Tous les produits',
      dryFood: 'Croquettes',
      wetFood: 'Nourriture humide',
      treats: 'Friandises',
      dental: 'Dentaire',
      forPuppies: 'Pour les chiots',
      forKittens: 'Pour les chatons',
      festive: 'Noël',

      storeFinder: 'Nos magasins',
      learn: 'Découvrir',
      ourFood: 'Notre nourriture',
      ourStory: 'Notre histoire',
      ourPlanet: 'Notre planète',
      account: 'Mon compte',
      login: 'Se connecter',

      impactReport: 'Rapport sur notre impact 23',

      countryLanguage: 'Sélectionnez le pays et la langue',
      cart: "Panier d'achats",

      productQuizTitle: 'Trouvez les meilleures recettes pour {{ animal }}',
      productQuizTitleAnimalDog: 'votre chien',
      productQuizTitleAnimalCat: 'votre chat',
      productQuizCompletedTitle: 'Les recettes recommandées pour {{name}}',
      productQuizCta: 'Commencer le quiz',
      productQuizCompletedCta: 'Voir les recettes',

      stickerSave5: 'Économisez 5%',
      stickerNew: 'Nouveau',
    },
    footer: {
      careers: 'Carrières',
      hiring: 'On recrute',
      titleSupport: 'Service Client',
      shippingAndReturns: 'Livraison & retour',
      termsAndConditions: 'Conditions générales',
      privacyPolicy: 'Politique de confidentialité',
      bCorpCertificationNotice: 'Note d’information sur la Certification B Corp',
      faq: 'FAQ',
      contact: 'Contactez-nous',
      titleShop: 'Acheter Nos Produits',
      dogs: 'Boutique chiens',
      cats: 'Boutique chats',
      followInstagram: 'Suivez-nous sur Instagram',
      followFacebook: 'Suivez-nous sur Facebook',
      followTiktok: 'Suivez-nous sur TikTok',
      followLinkedin: 'Suivez-nous sur LinkedIn',
      foundersLetter: 'Lettre des fondateurs',

      titleLearn: 'Découvrir',
      ourFood: 'Notre nourriture',
      ourStory: 'Notre histoire',
      ourPlanet: 'Notre planète',
      impactReport: 'Rapport sur notre impact 23',
      countryLanguage: 'Sélectionnez le pays et la langue',
    },
    localeModal: {
      title: 'Sélectionnez votre pays et votre langue',
      country: 'Votre pays',
      language: 'Votre langue',
    },
    navigation: {
      title: 'La navigation',
      productQuizTitle: 'Trouvez le bon repas pour votre animal',
    },
    authentication: {
      goBack: 'Retour',
    },
    cartDrawer: {
      title: 'Votre sélection',
      earnedFreeShipping: 'Vous avez gagné la gratuité des frais de port!',
      xAwayFromFreeShipping: 'Vous êtes à {{ amount }} de la livraison gratuite.',
      freeShippingFrom: 'Livraison gratuite à partir de {{amount}}.',
      cartEmpty: 'Il semble que votre panier soit vide.',
      cartEmptyWhyNotFill: 'Pourquoi ne pas le remplir?',
      summarySubtotal: 'Sout-total',
      oneItem: 'article',
      numberOfItems: '{{amount}} articles',
      free: 'Gratuit',
      shipping: 'Livraison',
      goToCart: 'Au panier',
      outOfStock: 'En rupture de stock',
    },
    cartGlobal: {
      errorMessageDefault:
        // eslint-disable-next-line max-len
        "Une erreur s'est produite lors de la récupération de votre panier. Veuillez rafraîchir la page. Si le problème persiste, contactez notre service client à l'adresse help@edgardcooper.com ou via le chat en direct.",
    },
  },
  de: {
    error500: {
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bei uns ist etwas schief gelaufen, entschuldigen Sie die Unannehmlichkeiten.',
      button: 'Zurück',
    },
    notFound: {
      title: 'Seite nicht gefunden',
      description: 'Entschuldigung! Wir konnten die von dir gesuchte Seite nicht finden.',
      button: 'Zurück',
    },
    error: {
      message: 'Ups! Da ist etwas schief gelaufen...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'Diese Seite ist derzeit wegen Wartungsarbeiten nicht verfügbar. Sie sollte in Kürze wieder verfügbar sein. Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.',
    },
    shipping: {
      'deliveryPromise.tomorrow':
        'Bestelle vor {{cutOffTime}} Uhr und deine Bestellung wird morgen geliefert!',
      'deliveryPromise.onDay.beforeCutOff':
        'Bestelle vor {{cutOffTime}} Uhr und deine Bestellung wird {{day}} geliefert!',
      'deliveryPromise.onDay.afterCutOff': 'Bestelle jetzt und erhalte deine Bestellung {{day}}!',
      'day.0': 'am Sonntag',
      'day.1': 'am Montag',
      'day.2': 'am Dienstag',
      'day.3': 'am Mittwoch',
      'day.4': 'am Donnerstag',
      'day.5': 'am Freitag',
      'day.6': 'am Samstag',
      deliveryInfo: `Wir geben unser Bestes, um deine Bestellung rechtzeitig zu liefern.
      Es kann jedoch gelegentlich zu Verzögerungen kommen, die durch unvorhergesehene Umstände mit Paketdiensten auftreten. Danke für dein Verständnis!`,
    },
    header: {
      dogs: 'Für Hunde',
      cats: 'Für Katzen',
      bundle: 'Pakete',
      allProducts: 'Alle Produkte',
      dryFood: 'Trockenfutter',
      wetFood: 'Nassfutter',
      treats: 'Leckerlis',
      dental: 'Dental',
      forPuppies: 'Für Welpen',
      forKittens: 'Für Kätzchen',
      festive: 'Weihnachten',

      storeFinder: 'Filialen',
      learn: 'Entdecken',
      ourFood: 'Unser Futter',
      ourStory: 'Unsere Geschichte',
      ourPlanet: 'Unser Planet',
      account: 'Mein Konto',
      login: 'Anmelden',

      impactReport: 'Nachhaltigkeitsbericht 2023',

      countryLanguage: 'Land und Sprache auswählen',
      cart: 'Warenkorb',

      productQuizTitle: 'Finde die passenden Rezepte für {{ animal }}',
      productQuizTitleAnimalDog: 'deinen Hund',
      productQuizTitleAnimalCat: 'deine Katze',
      productQuizCompletedTitle: 'Empfohlene Rezepte für {{name}}',
      productQuizCta: 'Quiz starten',
      productQuizCompletedCta: 'Rezepte anzeigen',

      stickerSave5: 'Spare 5%',
      stickerNew: 'Neu',
    },
    footer: {
      careers: 'Karriere',
      hiring: 'Offene Stellen',
      titleSupport: 'Service',
      shippingAndReturns: 'Lieferung & Rückgabe',
      termsAndConditions: 'Allgemeine Geschäftsbedingungen',
      privacyPolicy: 'Datenschutzerklärung',
      bCorpCertificationNotice: 'Certification Notice für B Corp',
      faq: 'FAQ',
      contact: 'Kontaktiere uns',
      titleShop: 'Einkaufen',
      dogs: 'Für Hunde',
      cats: 'Für Katzen',
      followInstagram: 'Folgen Sie uns auf Instagram',
      followFacebook: 'Folgen Sie uns auf Facebook',
      followTiktok: 'Folgen Sie uns auf TikTok',
      followLinkedin: 'Folgen Sie uns auf LinkedIn',
      foundersLetter: 'Gründerbrief',

      titleLearn: 'Entdecken',
      ourFood: 'Unser Futter',
      ourStory: 'Unsere Geschichte',
      ourPlanet: 'Unser Planet',

      impactReport: 'Nachhaltigkeitsbericht 2023',
      countryLanguage: 'Land und Sprache auswählen',
    },
    localeModal: {
      title: 'Wählen Sie Ihr Land und Ihre Sprache',
      country: 'Dien Land',
      language: 'Ihre Sprache',
    },
    navigation: {
      title: 'Navigation',
      productQuizTitle: 'Finden Sie das richtige Futter Futter für Ihr Haustier',
    },
    authentication: {
      goBack: 'Zurück',
    },
    cartDrawer: {
      title: 'Ihre Auswahl',
      earnedFreeShipping: 'Sie haben sich einen kostenlosen Versand verdient!',
      xAwayFromFreeShipping: 'Sie sind {{ amount }} vom kostenlosen Versand entfernt.',
      freeShippingFrom: 'Kostenloser Versand für Bestellungen ab {{amount}}.',
      cartEmpty: 'Es scheint, als wäre Ihr Warenkorb leer.',
      cartEmptyWhyNotFill: 'Warum nicht auftanken?',
      summarySubtotal: 'Zwischensumme',
      oneItem: 'Artikel',
      numberOfItems: '{{amount}} Artikel',
      free: 'Kostenlos',
      shipping: 'Versand',
      goToCart: 'Warenkorb anzeigen',
      outOfStock: 'Ausverkauft',
    },
    cartGlobal: {
      errorMessageDefault:
        // eslint-disable-next-line max-len
        'Es ist ein Fehler beim Abrufen deines Warenkorbs aufgetreten. Bitte aktualisiere die Seite. Wenn das Problem weiterhin besteht, kontaktiere unseren Kundenservice unter help@edgardcooper.com oder über den Live-Chat.',
    },
  },
  it: {
    error500: {
      title: "C'è stato un errore",
      description: "Qualcosa è andato storto con noi, ci scusiamo per l'inconveniente.",
      button: 'Torna indietro',
    },
    notFound: {
      title: 'Pagina non trovata',
      description: 'Ci dispiace! Non siamo riusciti a trovare la pagina che cercavate.',
      button: 'Torna indietro',
    },
    error: {
      message: 'Ops! Qualcosa è andato storto...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'Questa pagina non è attualmente disponibile per manutenzione. Dovrebbe essere nuovamente disponibile a breve. Ci scusiamo per gli eventuali disagi causati.',
    },
    shipping: {
      'deliveryPromise.tomorrow': 'Ordina prima delle {{cutOffTime}} e ricevi domani!',
      'deliveryPromise.onDay.beforeCutOff': 'Ordina prima delle {{cutOffTime}} e ricevi {{day}}!',
      'deliveryPromise.onDay.afterCutOff': 'Ordina ora e ricevi {{day}}!',
      'day.0': 'domenica',
      'day.1': 'lunedì',
      'day.2': 'martedì',
      'day.3': 'mercoledì',
      'day.4': 'giovedì',
      'day.5': 'venerdì',
      'day.6': 'sabato',
      deliveryInfo: `Facciamo del nostro meglio per consegnare il vostro ordine in tempo.
      Tuttavia, potrebbero verificarsi ritardi occasionali dovuti a circostanze impreviste del servizio di consegna. Apprezziamo la vostra comprensione!`,
    },
    header: {
      dogs: 'Cibo per cani',
      cats: 'Cibo per gatti',
      bundle: 'Pack',
      allProducts: 'Tutti i prodotti',
      dryFood: 'Cibo secco',
      wetFood: 'Cibo umido',
      treats: 'Snack',
      dental: 'Dentale',
      forPuppies: 'Per i cuccioli',
      forKittens: 'Per i gattini',
      festive: 'Natale',

      storeFinder: 'Negozi',
      learn: 'Scopri',
      ourFood: 'Il nostro cibo',
      ourStory: 'La nostra storia',
      ourPlanet: 'Il nostro pianeta',
      account: 'Il mio account',
      login: 'Accedi',

      impactReport: "Report d'impatto 23",

      countryLanguage: 'Selezionare paese e lingua',
      cart: 'Carrello',

      productQuizTitle: 'Trova le ricette giuste per {{ animal }}',
      productQuizTitleAnimalDog: 'tuo cane',
      productQuizTitleAnimalCat: 'tuo gatto',
      productQuizCompletedTitle: 'Ricette consigliate per {{name}}',
      productQuizCta: 'Inizia il quiz',
      productQuizCompletedCta: 'Visualizza le ricette',

      stickerSave5: 'Risparmia 5%',
      stickerNew: 'Nuovo',
    },
    footer: {
      careers: 'Lavora con noi',
      hiring: 'Stiamo assumendo',
      titleSupport: 'Servizio Clienti',
      shippingAndReturns: 'Consegne & restituzioni',
      termsAndConditions: 'Termini e condizioni',
      privacyPolicy: 'Informativa sulla privacy',
      bCorpCertificationNotice: 'Nota sulla Certificazione B Corp',
      faq: 'FAQ',
      contact: 'Contattaci',
      titleShop: 'Negozio',
      dogs: 'Cibo per cani',
      cats: 'Cibo per gatti',

      followInstagram: 'Seguiteci su Instagram',
      followFacebook: 'Seguiteci su Facebook',
      followTiktok: 'Seguiteci su TikTok',
      followLinkedin: 'Seguiteci su sur LinkedIn',
      foundersLetter: 'Lettera dei fondatori',

      titleLearn: 'Scopri',
      ourFood: 'Il nostro cibo',
      ourStory: 'La nostra storia',
      ourPlanet: 'Il nostro pianeta',

      impactReport: "Report d'impatto 23",
      countryLanguage: 'Selezionare paese e lingua',
    },
    localeModal: {
      title: 'Seleziona il tuo paese e la tua lingua',
      country: 'La tuo paese',
      language: 'La tua lingua',
    },
    navigation: {
      title: 'Navigazione',
      productQuizTitle: 'Trova il pasto giusto per il tuo animale domestico',
    },
    authentication: {
      goBack: 'Torna indietro',
    },
    cartDrawer: {
      title: 'La vostra selezione',
      earnedFreeShipping: 'Ti sei guadagnato la spedizione gratuita!',
      xAwayFromFreeShipping: 'Ti mancano {{ amount }} per la spedizione gratuita.',
      freeShippingFrom: 'Spedizione gratuita per ordini superiori a {{amount}}.',
      cartEmpty: 'Sembra che il tuo carrello sia vuoto.',
      cartEmptyWhyNotFill: 'Perché non riempirlo?',
      summarySubtotal: 'Subtotale',
      oneItem: 'articolo',
      numberOfItems: '{{amount}} articoli',
      free: 'Gratuito',
      shipping: 'Spedizione',
      goToCart: 'Al carrello',
      outOfStock: 'Esaurito',
    },
    cartGlobal: {
      errorMessageDefault:
        // eslint-disable-next-line max-len
        'Si è verificato un errore durante il recupero del tuo carrello. Ricarica la pagina. Se il problema persiste, contatta il nostro servizio clienti a help@edgardcooper.com o tramite la chat dal vivo.',
    },
  },
  es: {
    error500: {
      title: 'Hubo un error',
      description: 'Algo salió mal con nosotros, disculpe las molestias.',
      button: 'Volver',
    },
    notFound: {
      title: 'Página no encontrada',
      description: 'No pudimos encontrar la página que estabas buscando, sentimos las molestias.',
      button: 'Volver',
    },
    error: {
      message: '¡Uy! Algo salió mal...',
      code: 'Error code: {{code}}',
    },
    maintenance: {
      pageUnavailable:
        'Esta página no está disponible debido a tareas de mantenimiento. En breve volverá a estar disponible. Disculpa las molestias ocasionadas.',
    },
    shipping: {
      'deliveryPromise.tomorrow': '¡Haz tu pedido antes de las {{cutOffTime}} y lo tendrás mañana!',
      'deliveryPromise.onDay.beforeCutOff':
        '¡Haz tu pedido antes de las {{cutOffTime}} y lo tendrás {{day}}!',
      'deliveryPromise.onDay.afterCutOff': '¡Haz tu pedido ahora y lo recibirás {{day}}!',
      'day.0': 'el domingo',
      'day.1': 'el lunes',
      'day.2': 'el martes',
      'day.3': 'el miércoles',
      'day.4': 'el jueves',
      'day.5': 'el viernes',
      'day.6': 'el sábado',
      deliveryInfo: `Estamos haciendo todo lo posible para entregar tu pedido a tiempo.
      Puede haber ocasiones en las que surjan retrasos debido a circunstancias imprevistas con el servicio de entrega. ¡Agradecemos tu comprensión!`,
    },
    header: {
      dogs: 'Comida para perros',
      cats: 'Comida para gatos',
      bundle: 'Multipacks',
      allProducts: 'Todos los productos',
      dryFood: 'Piensos',
      wetFood: 'Comida húmeda',
      treats: 'Premios',
      dental: 'Dentales',
      forPuppies: 'Para cachorros',
      forKittens: 'Para gatitos',
      festive: 'Navidad',

      storeFinder: 'Tiendas',
      learn: 'Conócenos',
      ourFood: 'Nuestra comida',
      ourStory: 'Nuestra historia',
      ourPlanet: 'Nuestro planeta',
      account: 'Mi cuenta',
      login: 'Iniciar sesión',

      impactReport: 'Informe de impacto 23',

      countryLanguage: 'Seleccione país e idioma',
      cart: 'Carrito',

      productQuizTitle: 'Encuentra las recetas adecuadas para {{ animal }}',
      productQuizTitleAnimalDog: 'tu perro',
      productQuizTitleAnimalCat: 'su gato',
      productQuizCompletedTitle: 'Recetas recomendadas para {{name}}',
      productQuizCta: 'Haz el test',
      productQuizCompletedCta: 'Ver recetas',

      stickerSave5: 'Ahorra un 5%',
      stickerNew: 'Nuevo',
    },
    footer: {
      careers: 'Empleo',
      hiring: 'Estamos contratando',
      titleSupport: 'Atención Al Cliente',
      shippingAndReturns: 'Envío y devoluciones',
      termsAndConditions: 'Términos y condiciones',
      privacyPolicy: 'Política de privacidad',
      bCorpCertificationNotice: 'Aviso de certificación B Corp',
      faq: 'FAQ',
      contact: 'Contacta con nosotros',
      titleShop: 'Tiendas',
      dogs: 'Comida para perros',
      cats: 'Comida para gatos',
      followInstagram: 'Síguenos en Instagram',
      followFacebook: 'Síguenos en Facebook',
      followTiktok: 'Síguenos en TikTok',
      followLinkedin: 'Síguenos en LinkedIn',
      foundersLetter: 'Carta de los fundadores',

      titleLearn: 'Conócenos',
      ourFood: 'Nuestra comida',
      ourStory: 'Nuestra historia',
      ourPlanet: 'Nuestro planeta',
      impactReport: 'Informe de impacto 23',
      countryLanguage: 'Seleccione país e idioma',
    },
    localeModal: {
      title: 'Seleccione su país e idioma',
      country: 'Tu país',
      language: 'Tu lenguaje',
    },
    navigation: {
      title: 'Navegación',
      productQuizTitle: 'Encuentra la comida adecuada para tu mascota',
    },
    authentication: {
      goBack: 'Volver',
    },
    cartDrawer: {
      title: 'Su selección',
      earnedFreeShipping: '¡Te has ganado el envío gratis!',
      xAwayFromFreeShipping: '¡Estás a {{ amount }} del envío gratuito.',
      freeShippingFrom: 'Envío gratuito para pedidos superiores a {{amount}}.',
      cartEmpty: 'Parece que tu carrito está vacío.',
      cartEmptyWhyNotFill: '¿Por qué no llenarlo?',
      summarySubtotal: 'Subtotal',
      oneItem: 'artículo',
      numberOfItems: '{{amount}} artículos',
      free: 'Gratuito',
      shipping: 'Ver la cesta',
      outOfStock: 'Agotado',
    },
    cartGlobal: {
      errorMessageDefault:
        // eslint-disable-next-line max-len
        'Ocurrió un error al recuperar tu carrito. Por favor, actualiza la página. Si el problema persiste, contacta con nuestro servicio de atención al cliente en help@edgardcooper.com o a través del chat en vivo.',
    },
  },
};

export default translations;
