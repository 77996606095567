import React from 'react';

import classNames from 'classnames';

export interface Props {
  /** Label content */
  children?: string;
  /** Visually hide the label */
  hidden?: boolean;
  /** A unique identifier for the label */
  id: string;
  className?: string;
}

const Label: React.FC<Props> = ({
  children,
  hidden,
  id,
  className: additionalClassName,
}: Props) => {
  const className = classNames(
    'flex text-sm text-blue-300 uppercase mb-1',
    hidden && 'visually-hidden',
    additionalClassName,
  );

  return (
    <label className={className} htmlFor={id} id={`label-${id}`}>
      {children}
    </label>
  );
};

export default Label;
