import * as winston from 'winston';
import CloudWatchTransport from 'winston-aws-cloudwatch';

const date = new Date().toISOString().split('T')[0]; // e.g. 2022-02-17
const logGroupName = 'store-www';
const logStreamName = `${logGroupName}_${date}.log`;

const logger = winston.createLogger({
  transports: [
    new CloudWatchTransport({
      logGroupName,
      logStreamName,
      createLogGroup: false,
      createLogStream: true,
      awsConfig: {
        accessKeyId: process.env.NEXT_PUBLIC_AWS_LOGS_ACCESS_KEY_ID,
        secretAccessKey: process.env.NEXT_PUBLIC_AWS_LOGS_SECRET_ACCESS_KEY,
        region: process.env.NEXT_PUBLIC_AWS_LOGS_REGION,
      },
      formatLog(item) {
        return `${item.level}: ${item.message} ${JSON.stringify(item.meta)}`;
      },
    }),
  ],
});

// Ignore errors thrown while logging. In the future Sentry integration could be an option.
logger.on('error', () => {});
// If LOG_LEVEL is not specified, everything will be logged.
logger.level = process.env.LOG_LEVEL || 'silly';

export default logger;
