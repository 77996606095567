import React from 'react';

import SlideOver from 'components/ui/SlideOver';
import { useSlideOver } from 'context/SlideOverProvider';

const SlideOverWrapper: React.FC = () => {
  const { isSlideOverOpen, closeSlideOver, slideOverConfig } = useSlideOver();

  return (
    <SlideOver isOpen={isSlideOverOpen} close={closeSlideOver} config={slideOverConfig}>
      {slideOverConfig.children}
    </SlideOver>
  );
};

export default SlideOverWrapper;
