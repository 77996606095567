type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P>
  ? P
  : never;

/**
 * Utility function to get typescript support when adding provider entries for the Compose component
 */
export const providerEntry = <
  T extends React.JSXElementConstructor<React.PropsWithChildren<unknown>>,
>(
  Component: T,
  props?: Partial<GetComponentProps<T>>,
): [T, Partial<GetComponentProps<T>> | Record<string, string>] => [Component, props || {}];

interface Props {
  providersToCompose: unknown[];
  children: React.ReactNode;
}

export default function Compose({ providersToCompose, children }: Props): JSX.Element {
  return (
    <>
      {providersToCompose.reduceRight(
        (acc, [Provider, props]) => (
          <Provider {...props}>{acc}</Provider>
        ),
        children,
      )}
    </>
  );
}
