import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';
import Link from 'next/link';

interface Props {
  className?: string;
}

const ReferenceNumber: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <Link
    href={{
      hash: 'references',
    }}
    className={classNames(
      'cursor-[help] ml-1 text-xs transform inline-flex font-rooney align-top leading-none top-0 font-bold',
      className,
    )}
  >
    {children}
  </Link>
);

export default ReferenceNumber;
