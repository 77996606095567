import Link from 'next/link';

import Button from '../Button';
import Icon from '../Icon';

export interface CTAProps {
  title: string;
  buttonText: string;
  href: string;
}

const CTA = ({ title, buttonText, href }: CTAProps): JSX.Element => (
  <div className="rounded p-4 md:p-6 bg-cyan-200 font-body">
    <div className="heading-3 text-cyan-400">{title}</div>
    <Link href={href} passHref legacyBehavior>
      <Button legacy color="cyan">
        <span>{buttonText}</span>
        <Icon name="arrow-right" className="w-4 ml-4" />
      </Button>
    </Link>
  </div>
);

export default CTA;
