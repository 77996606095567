import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './tileChoiceList.module.css';

export interface Choice {
  value: string;
  label: string;
  checked?: boolean;
}

export interface Props {
  /** Allow multiple choices to be selected? False by default. */
  allowMultiple?: boolean;
  /** All displayed options */
  choices: Choice[];
  onChange: (selected: string | string[]) => void;
  name: string;
  className?: string;
  tileClassName?: string;
  /** Includes a "none" option, when this option is clicked, all other options will be unchecked. */
  includeNoneOption?: boolean;
  noneOptionLabel?: string;
}

const NONE_VALUE = 'none';

const TileChoiceList = ({
  choices,
  onChange,
  name,
  allowMultiple = false,
  className: additionalClassName,
  tileClassName,
  includeNoneOption = false,
  noneOptionLabel = '',
}: Props): JSX.Element => {
  const selectedValues =
    choices?.filter(({ checked }) => !!checked).map(({ value }) => value) ?? [];
  const [selected, setSelected] = useState(selectedValues);

  if (!allowMultiple && selectedValues.length > 1) {
    throw Error('Unexpected TileChoiceList state. Only one choice may be enabled at once.');
  }

  const isActive = (value: string) =>
    value === NONE_VALUE ? selected.length === 0 : selected.includes(value);

  const handleChange = (_e, { value }: Choice) => {
    if (value === NONE_VALUE) {
      setSelected([]);
      onChange([]);
      return;
    }

    let updatedSelected = [value];
    if (allowMultiple) {
      if (isActive(value)) {
        // Choice was already selected
        updatedSelected = selected.filter((selectedValue) => selectedValue !== value);
      } else {
        // Choice is not yet selected
        updatedSelected = [...selected, value];
      }
    }
    setSelected(updatedSelected);
    onChange(allowMultiple ? updatedSelected : updatedSelected[0]);
  };

  if (!choices?.length) {
    return null;
  }

  const noneChoice = {
    label: noneOptionLabel,
    value: NONE_VALUE,
    checked: !choices.find(({ checked }) => checked),
  };
  const tileListChoices = includeNoneOption ? [noneChoice, ...choices] : choices;
  return (
    <div className={classNames('flex flex-row flex-wrap flex-gap-2', additionalClassName)}>
      {tileListChoices.map((choice) => (
        <div key={choice.value} className={tileClassName}>
          <label htmlFor={choice.value} className="flex">
            <input
              className="hidden"
              type={allowMultiple ? 'checkbox' : 'radio'}
              id={choice.value}
              value={choice.value}
              checked={isActive(choice.value)}
              name={name}
              onChange={(event) => handleChange(event, choice)}
            />
            <span className={styles.tile}>{choice.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default TileChoiceList;
