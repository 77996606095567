import React, { useState } from 'react';

import classNames from 'classnames';

import { Image } from 'api/generated-types';

import styles from './tileChoiceListV2.module.css';
import ECImage from '../ECImage';

export interface Choice {
  value: string;
  label: string;
  description?: string;
  checked?: boolean;
  image?: Image;
}

export interface Props {
  /** Allow multiple choices to be selected? False by default. */
  allowMultiple?: boolean;
  limit?: number;
  /** All displayed options */
  choices: Choice[];
  onChange: (selected: string | string[]) => void;
  name: string;
  label?: string;
  direction?: 'horizontal' | 'vertical';
  mobileDirection?: 'horizontal' | 'vertical';
  textAlignment?: 'centered' | 'left';
  mobileTextAlignment?: 'centered' | 'left';
  className?: string;
  imageSize?: 'xs' | 'sm' | 'md' | 'lg';
  /** Includes a "none" option, when this option is clicked, all other options will be unchecked. */
  includeNoneOption?: boolean;
  noneOptionLabel?: string;
}

const NONE_VALUE = 'none';

const TileChoiceListV2 = ({
  choices,
  onChange,
  name,
  allowMultiple = false,
  limit,
  label,
  direction = 'horizontal',
  mobileDirection = 'vertical',
  mobileTextAlignment = 'centered',
  textAlignment = 'centered',
  imageSize = 'sm',
  className: additionalClassName,
  includeNoneOption = false,
  noneOptionLabel = '',
}: Props): JSX.Element => {
  const selectedValues =
    choices?.filter(({ checked }) => !!checked).map(({ value }) => value) ?? [];
  const [selected, setSelected] = useState(selectedValues);

  if (!allowMultiple && selectedValues.length > 1) {
    throw Error('Unexpected TileChoiceList state. Only one choice may be enabled at once.');
  }

  const isActive = (value: string) =>
    value === NONE_VALUE ? selected.length === 0 : selected.includes(value);

  const handleChange = (_e, { value }: Choice) => {
    if (value === NONE_VALUE) {
      setSelected([]);
      onChange([]);
      return;
    }

    let updatedSelected = [value];
    if (allowMultiple) {
      if (isActive(value)) {
        // Choice was already selected
        updatedSelected = selected.filter((selectedValue) => selectedValue !== value);
      } else if (limit && selected.length >= limit) {
        // Choice is not yet selected, but is checked if it's still in the limit
        updatedSelected = [...selected];
      } else {
        // Choice is not yet selected
        updatedSelected = [...selected, value];
      }
    }
    setSelected(updatedSelected);
    onChange(allowMultiple ? updatedSelected : updatedSelected[0]);
  };

  if (!choices?.length) {
    return null;
  }

  const noneChoice = {
    label: noneOptionLabel,
    value: NONE_VALUE,
    checked: !choices.find(({ checked }) => checked),
  };
  const tileListChoices: Choice[] = includeNoneOption ? [noneChoice, ...choices] : choices;
  return (
    <>
      {label && <div className="font-rooney mb-2 text-purple-500">{label}</div>}
      <div
        className={classNames(
          'flex flex-wrap flex-gap-2',
          mobileDirection === 'horizontal' ? 'maxMd:flex-row' : 'maxMd:flex-col',
          direction === 'horizontal' ? 'md:flex-row' : 'md:flex-col',
          additionalClassName,
        )}
      >
        {tileListChoices.map((choice) => (
          <div key={`${name}-${choice.value}`} className="flex flex-1">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={`${name}-${choice.value}`} className="flex flex-1">
              <input
                className="hidden"
                type={allowMultiple ? 'checkbox' : 'radio'}
                id={`${name}-${choice.value}`}
                value={choice.value}
                checked={isActive(choice.value)}
                name={name}
                onChange={(event) => handleChange(event, choice)}
              />
              <span
                className={classNames(
                  styles.tile,
                  textAlignment === 'left' ? 'md:flex-row' : 'md:flex-col',
                  mobileTextAlignment === 'left' ? 'maxMd:flex-row' : 'maxMd:flex-col',
                  textAlignment === 'centered' ? 'md:text-center' : 'md:text-left',
                  mobileTextAlignment === 'centered' ? 'maxMd:text-center' : 'maxMd:text-left',
                  choice.description ? styles.hasDescription : '',
                )}
              >
                <span
                  className={classNames(
                    textAlignment === 'left' &&
                      (allowMultiple || choice.image) &&
                      'md:flex md:items-center md:pr-4',
                    mobileTextAlignment === 'left' &&
                      (allowMultiple || choice.image) &&
                      'maxMd:flex maxMd:items-center maxMd:pr-4',
                  )}
                >
                  {allowMultiple && <span className={styles.tileCheckMark} />}
                  {choice.image && !allowMultiple && (
                    <span
                      className={classNames(
                        styles.tileImageWrapper,
                        styles[`tileImageWrapper--${imageSize}`],
                        textAlignment === 'centered' && 'md:flex md:justify-center md:mb-4',
                        mobileTextAlignment === 'centered' &&
                          'maxMd:flex maxMd:justify-center maxMd:mb-4',
                      )}
                    >
                      <ECImage
                        className={styles.tileImage}
                        pictureClassName={styles.tileImagePicture}
                        img={choice.image}
                        srcOptions={{
                          w: (() => {
                            switch (imageSize) {
                              case 'xs':
                                return 96;
                              case 'sm':
                                return 128;
                              case 'md':
                                return 192;
                              case 'lg':
                                return 336;
                              default:
                                return 336;
                            }
                          })(),
                        }}
                      />
                    </span>
                  )}
                </span>
                <span className="flex flex-col justify-center">
                  <span className={styles.tileMainLabel}>{choice.label}</span>
                  {choice.description && (
                    <span className={styles.tileDescription}>{choice.description}</span>
                  )}
                </span>
              </span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default TileChoiceListV2;
