import { useEffect, useState, ReactNode } from 'react';

import { useMediaQuery } from 'react-responsive';

import MEDIA_QUERIES from './mediaQueries';

interface Props {
  children: ReactNode;
  md?: boolean;
  minWidth?: keyof typeof MEDIA_QUERIES;
  maxWidth?: keyof typeof MEDIA_QUERIES;
}

const DefaultScreen: React.FC<Props> = ({
  children,
  md,
  minWidth: propMinWidth,
  maxWidth: propMaxWidth,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const isDefaultScreen = useMediaQuery({ minWidth: MEDIA_QUERIES.m });
  const isSmallScreen = useMediaQuery({
    minWidth: MEDIA_QUERIES[0],
    maxWidth: MEDIA_QUERIES.m - 1,
  });

  const screenDimensions = useMediaQuery({
    minWidth: 0,
    ...(propMinWidth && {
      minWidth: MEDIA_QUERIES[propMinWidth],
    }),
    ...(propMaxWidth && {
      maxWidth: MEDIA_QUERIES[propMaxWidth] - 1,
    }),
  });

  let typeScreen = md ? isSmallScreen : isDefaultScreen;

  if (propMinWidth || propMaxWidth) {
    typeScreen = screenDimensions;
  }

  if (mounted) {
    return typeScreen ? <>{children}</> : null;
  }

  return null;
};

export default DefaultScreen;
