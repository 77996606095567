import { Menu, Transition } from '@headlessui/react';

import Icon from '../Icon';

interface DropdownProps {
  buttonTitle: string;
  buttonIcon?: string;
  dropdownItems: DropdownItem[];
}

type DropdownItem = {
  href: string;
  title: string;
};

const Dropdown: React.FC<DropdownProps> = ({ buttonTitle, dropdownItems }) => (
  <Menu>
    <div className="relative">
      <div className="flex py-3 px-4 shadow rounded bg-white ml-2">
        <Icon name="pdf" className="w-4" />
        <Menu.Button className="ml-2">{buttonTitle}</Menu.Button>
      </div>
      <Transition
        enter="transition duration-100 ease-out z-30"
        enterFrom="transform scale-95 opacity-0 z-30"
        enterTo="transform scale-100 opacity-100 z-30"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100 z-30"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute top-full mt-2 min-w-max right-0 bg-white rounded shadow-lg py-2 z-30">
          {dropdownItems?.length > 0 &&
            dropdownItems.map((dropdownItem) => (
              <Menu.Item key={dropdownItem.href}>
                <a
                  className="py-2 px-4 hover:bg-cyan-200 last:bl-rounded-lg transition"
                  href={dropdownItem.href}
                >
                  {dropdownItem.title}
                </a>
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </div>
  </Menu>
);

export default Dropdown;
