import React, { useContext, useState, createContext, useCallback } from 'react';

import { Toast, ToastProps, ToastConfig, ToastWrapper } from 'components/ui';

import { useHeaderActions } from './HeaderActionsProvider';

interface ToastContext {
  addToast: (config: ToastConfig) => void;
  addCartToast: (config: ToastConfig) => void;
}

const Context = createContext<ToastContext>(null);

export const useToast: () => ToastContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useToast must be used within a ToastProvider tag');
  }
  return contextState;
};

/**
 * The id counter doesn't have to be a state.
 * It's just memory we access in order to create a new id and then update our state
 */
let toastIdCounter = 0;

const ToastProvider: React.FC<{ children }> = ({ children }) => {
  const [toastPropsArray, setToastPropsArray] = useState<ToastProps[]>([]);
  const [cartToastPropsArray, setCartToastPropsArray] = useState<ToastProps[]>([]);
  const { triggerAction } = useHeaderActions();

  const addToast = (config: ToastConfig) => {
    toastIdCounter += 1;
    setToastPropsArray((prevProps) => [...prevProps, { id: toastIdCounter, config }]);
  };

  const addCartToast = (config: ToastConfig) => {
    toastIdCounter += 1;
    setCartToastPropsArray((prevProps) => [...prevProps, { id: toastIdCounter, config }]);
    triggerAction('show');
  };

  const removeToast = useCallback((id: number) => {
    setToastPropsArray((prevToasts) => prevToasts.filter((t) => t.id !== id));
  }, []);

  const removeCartToast = useCallback((id: number) => {
    setCartToastPropsArray((prevToasts) => prevToasts.filter((t) => t.id !== id));
  }, []);

  const onRemoveToast = useCallback(
    (id: number) => {
      removeToast(id);
    },
    [removeToast],
  );

  const onRemoveCartToast = useCallback(
    (id: number) => {
      removeCartToast(id);
    },
    [removeCartToast],
  );

  return (
    <Context.Provider
      value={{
        addToast,
        addCartToast,
      }}
    >
      {children}
      <ToastWrapper>
        {toastPropsArray.map((toastProps) => (
          <Toast key={toastProps.id} {...toastProps} onRemove={onRemoveToast} />
        ))}
      </ToastWrapper>
      <ToastWrapper isCartToastWrapper>
        {cartToastPropsArray.map((toastProps) => (
          <Toast key={toastProps.id} {...toastProps} onRemove={onRemoveCartToast} />
        ))}
      </ToastWrapper>
    </Context.Provider>
  );
};

export default ToastProvider;
