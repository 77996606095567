import { FC, Fragment, PropsWithChildren, useEffect } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Router from 'next/router';

import Icon from 'components/ui/Icon';

export interface Props {
  isOpen: boolean;
  close: () => void;
  config: {
    position?: 'left' | 'right' | null;
    size?: 'full' | 'sm' | 'md' | 'full-mobile' | null;
    title?: string;
    children?: React.ReactNode;
  };
}

const SlideOver: FC<PropsWithChildren<Props>> = ({ isOpen, close, config, children }) => {
  const { size, position, title } = config;

  let sizeClass = 'max-w-md';
  switch (size) {
    case 'sm':
      sizeClass = 'max-w-md';
      break;
    case 'md':
      sizeClass = 'max-w-3xl';
      break;
    case 'full':
      sizeClass = 'max-w-full';
      break;
    case 'full-mobile':
      sizeClass = 'max-w-full md:max-w-md';
      break;
    default:
      sizeClass = 'max-w-md';
  }

  useEffect(() => {
    Router.events.on('routeChangeComplete', close);

    return () => {
      Router.events.off('routeChangeComplete', close);
    };
  }, [close]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" open={isOpen} onClose={close}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-blue-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div
            className={classNames(
              'fixed inset-y-0  max-w-full flex pointer-events-none',
              // eslint-disable-next-line no-nested-ternary
              size === 'full-mobile'
                ? 'right-0 pr-0 pl-0'
                : position === 'left'
                ? 'left-0 pr-8'
                : 'right-0 pl-8',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={position === 'left' ? '-translate-x-full' : 'translate-x-full'}
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo={position === 'left' ? '-translate-x-full' : 'translate-x-full'}
            >
              <div className={classNames('relative w-screen max-w-md', sizeClass)}>
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-auto pointer-events-auto">
                  <div
                    className={classNames(
                      'flex items-center justify-start sticky top-0 z-10 bg-white',
                      {
                        'border-b': title,
                      },
                    )}
                  >
                    {title && (
                      <Dialog.Title className="heading-3 mb-0 grow font-rooney ml-4 font-bold text-gray-500 text-lg">
                        {title}
                      </Dialog.Title>
                    )}
                    <button
                      className="flex items-center justify-center w-16 h-16"
                      onClick={close}
                      type="button"
                    >
                      <Icon name="cross-thin" className="w-8 text-gray-500" />
                      <span className="sr-only">Close panel</span>
                    </button>
                  </div>
                  <div className="flex flex-col relative flex-1">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
