import React, { useContext, useState, createContext, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useRouter } from 'next/router';

import { authenticatedBffRequest } from 'api/client/bff-request';
import { QueryKeys, Keys } from 'api/client/QueryKeys';
import { Customer } from 'api/generated-types';
import addLoyaltyToWindowObject from 'services/loyalty-lion/add-loyalty-to-window-object';
import { LoyaltyLion } from 'services/loyalty-lion/types';
import { useSessionContext } from 'services/session';

type WindowWithLoyalty = Window &
  typeof globalThis & {
    loyaltylion: LoyaltyLion;
  };

type LoyaltyLionContext = {
  loyaltyLion: LoyaltyLion;
  isLoading: boolean;
  clearLoyaltyLion: () => void;
  updateLoyaltyLion: () => void;
};

type LoyaltyData = {
  activeUser: Partial<Customer>;
};

const loyaltyLionQuery = gql`
  query loyaltylion($locale: String!) {
    activeUser(locale: $locale) {
      loyaltyAuthDate
      loyaltyAuthToken
    }
  }
`;

const Context = createContext<LoyaltyLionContext>(null);

export const useLoyaltyLion = (): LoyaltyLionContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useLoyaltyLion must be used within a LoyaltyLionProvider tag');
  }
  return contextState;
};

interface LoyaltyLionProviderProps {
  children: React.ReactNode;
}

const LoyaltyLionProvider = ({ children }: LoyaltyLionProviderProps): JSX.Element => {
  const { locale } = useRouter();
  const [loyaltyLion, setLoyaltyLion] = useState<LoyaltyLion>();
  const [isInitializing, setInitializing] = useState(false);
  const { user } = useSessionContext();
  const [needsUpdate, setNeedsUpdate] = useState(true);
  const router = useRouter();

  const { data } = useQuery(
    QueryKeys[Keys.LoyaltyLion](user?.email, locale),
    () =>
      authenticatedBffRequest<LoyaltyData>({
        query: loyaltyLionQuery,
        variables: { locale },
      }),
    { enabled: !!user },
  );

  const clearLoyaltyLion = () => {
    (window as WindowWithLoyalty).loyaltylion = undefined;
    setInitializing(false);
    setLoyaltyLion(undefined);
  };

  useEffect(() => {
    const initializeLoyaltyOnRouteChange = () => {
      setNeedsUpdate(true);
    };

    router.events.on('routeChangeComplete', initializeLoyaltyOnRouteChange);

    return () => {
      router.events.off('routeChangeComplete', initializeLoyaltyOnRouteChange);
    };
  }, [router.events]);

  // Set up a tracker for the #loyaltylion container, so we can reload after a
  // modal closes for instance
  useEffect(() => {
    const observer = new MutationObserver(() => setNeedsUpdate(true));
    document.querySelectorAll('#loyaltylion').forEach((el) => {
      observer.observe(el, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
      });
    });
  }, []);

  useEffect(() => {
    if (user?.email) {
      setNeedsUpdate(true);
    }
  }, [user?.email]);

  /**
   * Create loyalty lion object and call init function
   */
  useEffect(() => {
    if (data?.activeUser?.loyaltyAuthToken && needsUpdate) {
      try {
        setNeedsUpdate(false);
        addLoyaltyToWindowObject();

        const initConfig = {
          token: process.env.NEXT_PUBLIC_LOYALTY_LION_TOKEN,
          customer: {
            id: user?.customShopifyUserId,
            email: user?.email,
          },
          auth: {
            date: data.activeUser.loyaltyAuthDate,
            token: data.activeUser.loyaltyAuthToken,
          },
        };
        (window as WindowWithLoyalty).loyaltylion.init(initConfig);
        setInitializing(true);
        setLoyaltyLion(null);
      } catch (err) {
        setInitializing(false);
      }
    }
  }, [user, data, needsUpdate]);

  /**
   * Get init result from window when it's available
   */
  useEffect(() => {
    let interval;
    if (!loyaltyLion && isInitializing) {
      // We can't provide a fallback to the loyalty init function to know when it's ready
      // So we have to use set an interval to regularly check for us
      interval = setInterval(() => {
        const windowWithLoyalty = window as WindowWithLoyalty;
        if (!loyaltyLion) {
          if (windowWithLoyalty.loyaltylion?.api) {
            setLoyaltyLion(windowWithLoyalty.loyaltylion);
            setInitializing(false);
            clearInterval(interval);
          }
        } else {
          clearInterval(interval);
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [loyaltyLion, isInitializing]);

  return (
    <Context.Provider
      value={{
        loyaltyLion,
        isLoading: !loyaltyLion && !loyaltyLion?.customer,
        clearLoyaltyLion,
        updateLoyaltyLion: () => setNeedsUpdate(true),
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LoyaltyLionProvider;
