import React, { RefObject } from 'react';

import classNames from 'classnames';

import styles from './Button.module.css';
import Icon from '../Icon';
import Spinner from '../loaders/Spinner';

type Color =
  | 'blue'
  | 'cyan'
  | 'cyan-200'
  | 'white'
  | 'white-gray-hover'
  | 'white-purple'
  | 'white-purple-500'
  | 'green'
  | 'pink'
  | 'red'
  | 'yellow'
  | 'gray-500'
  | 'purple'
  | 'green-550'
  | 'purple-800';

type Size = 'medium' | 'large';

export interface Props {
  /** The content to display inside the button */
  children?: React.ReactNode;
  /** The color of the button */
  color?: Color;
  /** The size of the button */
  size?: Size;
  /** Disables the button */
  disabled?: boolean;
  /** Toggles a loading state */
  loading?: boolean;
  /** Forces url to open in a new tab */
  external?: boolean;
  /** Allows the button to submit a form */
  submit?: boolean;
  /** A destination to link to, rendered in the href attribute of a link */
  href?: string;
  /** Additional class names */
  className?: string;
  /** Callback when clicked */
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | React.MouseEventHandler<HTMLButtonElement>;
  /** Callback when button becomes focussed */
  onFocus?();
  /** Callback when focus leaves button */
  onBlur?();
  /** Use legacy button */
  legacy?: boolean;
  /** Show checkmark icon when button is clicked */
  showCheckmark?: boolean;
}

const Button = (
  {
    children,
    color = 'blue',
    size = 'medium',
    disabled,
    loading,
    external,
    submit,
    href,
    onClick,
    onFocus,
    onBlur,
    className: additionalClassName,
    legacy,
    showCheckmark,
  }: Props,
  ref,
) => {
  const isDisabled = disabled || loading;
  const spinnerTheme = color !== 'white' ? 'light' : 'dark';

  const iconMarkup =
    loading || showCheckmark ? (
      <span className="flex items-center justify-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        {loading && <Spinner size="small" theme={spinnerTheme} />}
        {showCheckmark && !loading && <Icon name="checkmark" className="w-5 text-white" />}
      </span>
    ) : null;
  const childMarkup = children ? (
    <>
      {children}
      {iconMarkup}
    </>
  ) : null;

  const className = classNames(
    legacy ? styles['button-legacy'] : styles.button,
    color && styles[`button--${color}`],
    size && styles[`button--${size}`],
    disabled && styles['button--disabled'],
    (loading || showCheckmark) && styles['button--with-center-icon'],
    showCheckmark && !loading && '!bg-green-550',
    additionalClassName,
  );

  let buttonMarkup;

  if (href) {
    buttonMarkup = (
      <a
        href={!disabled && href}
        target={external ? '_blank' : undefined}
        className={className}
        rel="noreferrer"
        onClick={!disabled && (onClick as React.MouseEventHandler<HTMLAnchorElement>)}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref as RefObject<HTMLAnchorElement>}
      >
        {childMarkup}
      </a>
    );
  } else {
    buttonMarkup = (
      <button
        type={submit ? 'submit' : 'button'}
        className={className}
        disabled={isDisabled}
        onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref as RefObject<HTMLButtonElement>}
      >
        {childMarkup}
      </button>
    );
  }

  return buttonMarkup;
};

export default React.forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(Button);
