import { useEffect, useState } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { Auth } from 'aws-amplify';

import getInvoiceIdsForShopifyOrderName from 'services/azure/sap-order-invoices/getInvoiceIdsForShopifyOrderName';
import { useTranslations } from 'services/translations/TranslationsProvider';

import Icon from '../Icon';
import Spinner from '../loaders/Spinner';

interface DropdownProps {
  buttonTitle: string;
  orderName: string;
}

const DropdownInvoices: React.FC<DropdownProps> = ({ buttonTitle, orderName }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [invoiceIds, setInvoiceIds] = useState<string[]>();
  const [accessToken, setAccessToken] = useState<string>();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { t } = useTranslations('order');

  useEffect(() => {
    Auth.currentSession().then((currentSession) =>
      setAccessToken(currentSession.getAccessToken().getJwtToken()),
    );
  }, []);

  const fetchOrderInvoices = (e) => {
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
    if (invoiceIds?.length) return;

    getInvoiceIdsForShopifyOrderName(orderName?.split('#')?.[1]).then((invoices) => {
      setInvoiceIds(invoices);
      setLoading(false);
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) fetchOrderInvoices(e);
  };

  return (
    <Menu>
      <div className="relative w-full lg:w-auto">
        <div
          onClick={fetchOrderInvoices}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          className="flex justify-between items-center py-2 px-6 shadow rounded bg-blue-400 text-white hover:bg-blue-500 transition text-base"
        >
          <span className="-mb-1">{buttonTitle}</span>
          <Icon
            name="chevron-down"
            className={`transition transform w-7 ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`}
          />
        </div>
        <Transition
          show={dropdownOpen}
          enter="transition duration-100 ease-out z-30"
          enterFrom="transform scale-95 opacity-0 z-30"
          enterTo="transform scale-100 opacity-100 z-30"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100 z-30"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-full right-0 bg-white rounded shadow-lg py-2 flex-col items-center z-30 w-full">
            {loading && (
              <div className="flex items-center justify-center h-8">
                <Spinner size="small" />
              </div>
            )}
            {!loading &&
              !!invoiceIds?.length &&
              invoiceIds.map((invoiceId, index) => (
                <Menu.Item
                  as="div"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `${process.env.NEXT_PUBLIC_AZURE_SAP_ORDER_INVOICES_API_ENDPOINT}/GetPdfForInvoiceId?invoiceId=${invoiceId}&accessToken=${accessToken}`,
                      '_blank',
                    );
                    setDropdownOpen(false);
                  }}
                  key={invoiceId}
                >
                  <a
                    className="py-2 px-5 inline-block w-42 hover:bg-cyan-200 text-base text-gray-300 w-full transition"
                    href={`${process.env.NEXT_PUBLIC_AZURE_SAP_ORDER_INVOICES_API_ENDPOINT}/GetPdfForInvoiceId?invoiceId=${invoiceId}&accessToken=${accessToken}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('invoiceItem')} #{index + 1}
                  </a>
                </Menu.Item>
              ))}
            {!loading && !invoiceIds?.length && (
              <p className="text-sm py-2 px-3">{t('noInvoicesForThisOrder')}</p>
            )}
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
};

export default DropdownInvoices;
