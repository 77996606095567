/* eslint-disable @typescript-eslint/naming-convention */

// https://content-security-policy.com/
// https://developer.mozilla.org/en-US/docs/Web/HTTP/CSP
// https://www.intercom.com/help/en/articles/3894-using-intercom-with-content-security-policy

const googleDomains = [
  'google.com',
  'www.google.com',
  'www.google.com.ar',
  'www.google.com.au',
  'www.google.com.bd',
  'www.google.com.bh',
  'www.google.com.bo',
  'www.google.com.br',
  'www.google.com.co',
  'www.google.com.cy',
  'www.google.com.do',
  'www.google.com.eg',
  'www.google.com.ec',
  'www.google.com.gh',
  'www.google.com.gt',
  'www.google.com.hk',
  'www.google.com.kw',
  'www.google.com.kh',
  'www.google.com.lb',
  'www.google.com.mm',
  'www.google.com.mt',
  'www.google.com.mx',
  'www.google.com.ng',
  'www.google.com.ni',
  'www.google.com.pa',
  'www.google.com.pe',
  'www.google.com.pg',
  'www.google.com.ph',
  'www.google.com.pr',
  'www.google.com.py',
  'www.google.com.sa',
  'www.google.com.sg',
  'www.google.com.sv',
  'www.google.com.tr',
  'www.google.com.tw',
  'www.google.com.ua',
  'www.google.com.uy',
  'www.google.com.vn',
  'www.google.com.qa',
  'www.google.co.cr',
  'www.google.co.id',
  'www.google.co.il',
  'www.google.co.in',
  'www.google.co.jp',
  'www.google.co.ke',
  'www.google.co.kr',
  'www.google.co.ma',
  'www.google.co.nz',
  'www.google.co.th',
  'www.google.co.tz',
  'www.google.co.uk',
  'www.google.co.ve',
  'www.google.co.za',
  'www.google.co.zm',
  'www.google.ad',
  'www.google.ae',
  'www.google.at',
  'www.google.az',
  'www.google.be',
  'www.google.bf',
  'www.google.bg',
  'www.google.by',
  'www.google.ca',
  'www.google.cd',
  'www.google.cg',
  'www.google.ch',
  'www.google.ci',
  'www.google.cl',
  'www.google.cm',
  'www.google.cv',
  'www.google.cz',
  'www.google.de',
  'www.google.dk',
  'www.google.dz',
  'www.google.ee',
  'www.google.es',
  'www.google.fi',
  'www.google.fr',
  'www.google.ge',
  'www.google.gg',
  'www.google.gr',
  'www.google.hn',
  'www.google.hr',
  'www.google.hu',
  'www.google.ie',
  'www.google.im',
  'www.google.it',
  'www.google.je',
  'www.google.li',
  'www.google.lk',
  'www.google.lt',
  'www.google.lu',
  'www.google.lv',
  'www.google.mk',
  'www.google.mg',
  'www.google.mn',
  'www.google.mu',
  'www.google.nl',
  'www.google.no',
  'www.google.pl',
  'www.google.pt',
  'www.google.ro',
  'www.google.rs',
  'www.google.ru',
  'www.google.se',
  'www.google.sc',
  'www.google.sk',
  'www.google.si',
  'www.google.sn',
  'www.google.sr',
  'www.google.tg',
  'www.google.tn',
];
/**
 * NEW policy to be monitored
 */

const contentSecurityPolicy_img_src = [
  'images.ctfassets.net',
  // '*.ads.linkedin.com',
  // '*.google-analytics.com',
  'www.googletagmanager.com',
  'adservice.google.com',
  // '*.analytics.google.com',
  '*.doubleclick.net',
  '*.googlesyndication.com',
  ...googleDomains,
  'www.facebook.com',
  'www.gstatic.com',
  'fonts.gstatic.com',
  'maps.gstatic.com',
  'maps.googleapis.com',
  // '*.klaviyo.com',
  'https://js.intercomcdn.com',
  'https://downloads.intercomcdn.com',
  'static.intercomassets.com',
  // '*.intercomusercontent.com',
  // '*.intercom-attachments-1.com',
  // '*.intercom-attachments-2.com',
  // '*.intercom-attachments-3.com',
  // '*.intercom-attachments-4.com',
  // '*.intercom-attachments-5.com',
  // '*.intercom-attachments-6.com',
  // '*.intercom-attachments-7.com',
  // '*.intercom-attachments-8.com',
  // '*.intercom-attachments-9.com',
  // '*.ggpht.com',
  // 'optimize.google.com',
  '*.bing.com',
  // '*.microsoft.com',
  '*.clarity.ms',
  // '*.cloudfront.net',
  // '*.typeform.com',
  'https://vercel.live', // vercel toolbar
  'https://vercel.com', // vercel toolbar
  'imgsct.cookiebot.com',
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
  'https://assets.survicate.com',
  'https://img.survicate.com',
  'https://images.unsplash.com', // survicate
];

export const contentSecurityPolicy_script_src = [
  '*.sentry.io',
  // '*.contentful.com',
  'https://consentcdn.cookiebot.com',
  'https://consent.cookiebot.com',
  'vitals.vercel-insights.com', // Vercel analytics
  // '*.analytics.google.com',
  '*.google-analytics.com',
  // '*.googleanalytics.com',
  'www.googletagmanager.com',
  'https://googleads.g.doubleclick.net',
  // 'tagmanager.google.com',
  'connect.facebook.net',
  'www.facebook.com',
  // '*.myshopify.com',
  'https://cdn.segment.com',
  // '*.segmentapis.com',
  'https://maps.googleapis.com',
  // 'cognito-idp.eu-west-1.amazonaws.com',
  // 'cognito-identity.eu-west-1.amazonaws.com/',
  // 'https://logs.eu-west-1.amazonaws.com/',
  // 'https://store729833ce-729833ce-test.auth.eu-west-1.amazoncognito.com/oauth2/token',
  'widget.trustpilot.com',
  '*.loyaltylion.net',
  // '*.loyaltylion.com',
  '*.klaviyo.com',
  'widget.intercom.io',
  'https://js.intercomcdn.com',
  // '*.intercomcdn.com',
  // '*.intercomcdn.eu',
  // '*.intercomusercontent.com',
  // 'wss://nexus-websocket-a.intercom.io',
  // 'wss://nexus-websocket-b.intercom.io',
  ...(process.env.VERCEL_ENV === 'development'
    ? ['http://localhost:3002', 'http://localhost:3001', 'ws://localhost:3000']
    : []),
  ...(process.env.VERCEL_ENV === 'development' ? ["'unsafe-eval'"] : []),
  // '*.googleapis.com',
  // 'optimize.google.com',
  // 'www.googleoptimize.com',
  // 'www.youtube.com',
  'bat.bing.com',
  // 'wss://*.bing.com',
  // 'www.googleadservices.com',
  '*.vercel.app',
  // 'polyfill.io',
  'https://www.clarity.ms',
  // '*.azurewebsites.net',
  // '*.datadome.co',
  // '*.typeform.com',
  // 'apim-b2c-edgardcooper-prd.azure-api.net',
  // '*.googlesyndication.com',
  'https://analytics.tiktok.com',
  '*.realytics.io',
  '*.realytics.net',
  'https://vercel.live', // vercel toolbar
  'va.vercel-scripts.com',
  '*.dwin1.com', // Awin tracking
  'https://survey.survicate.com',
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
  'https://survey-prd.survicate-cdn.com',
];
const contentSecurityPolicy_connect_src = [
  '*.sentry.io',
  '*.contentful.com',
  'https://consentcdn.cookiebot.com',
  'https://consent.cookiebot.com',
  'vitals.vercel-insights.com', // Vercel analytics
  '*.analytics.google.com',
  'analytics.google.com',
  ...googleDomains,
  '*.google-analytics.com',
  // '*.googleanalytics.com',
  '*.googletagmanager.com',
  '*.g.doubleclick.net',
  // 'tagmanager.google.com',
  // 'connect.facebook.net',
  'https://graph.facebook.com',
  'https://www.facebook.com',
  '*.myshopify.com',
  '*.segment.com',
  '*.segmentapis.com',
  'api.segment.io',
  // 'ipapi.co',
  'https://maps.googleapis.com',
  'cognito-idp.eu-west-1.amazonaws.com',
  'https://cognito-identity.eu-west-1.amazonaws.com',
  'https://logs.eu-west-1.amazonaws.com/',
  // 'https://store729833ce-729833ce-test.auth.eu-west-1.amazoncognito.com/oauth2/token',
  // '*.trustpilot.com',
  '*.loyaltylion.net',
  '*.loyaltylion.com',
  '*.klaviyo.com',
  '*.intercom.io',
  // '*.intercomcdn.com',
  // '*.intercomcdn.eu',
  // '*.intercomusercontent.com',
  'wss://nexus-websocket-a.intercom.io',
  'wss://nexus-websocket-b.intercom.io',
  // ...(process.env.VERCEL_ENV === 'development'
  //   ? ['http://localhost:3002', 'http://localhost:3001', 'ws://localhost:3000']
  //   : []),
  // '*.edgardcooper.com',
  '*.googleapis.com',
  // 'optimize.google.com',
  // 'www.googleoptimize.com',
  // 'www.youtube.com',
  '*.bing.com',
  // 'wss://*.bing.com',
  'www.googleadservices.com',
  'adservice.google.com',
  // '*.vercel.app',
  // 'polyfill.io',
  '*.clarity.ms',
  // '*.azurewebsites.net',
  '*.datadome.co',
  // '*.typeform.com',
  'apim-b2c-edgardcooper-prd.azure-api.net',
  'https://pagead2.googlesyndication.com',
  'analytics.tiktok.com',
  '*.realytics.io',
  '*.realytics.net',
  'https://vercel.live', // vercel toolbar
  'wss://ws-us3.pusher.com', // vercel toolbar
  'https://sockjs-us3.pusher.com',
  'va.vercel-scripts.com',
  '*.dwin1.com', // Awin tracking
  'https://respondent.survicate.com',
  'https://survey.survicate.com',
  'https://survey-prd.survicate-cdn.com',
];

const contentSecurityPolicy_form_action = [
  // 'intercom.help',
  // 'api-iam.intercom.io',
  // 'connect.facebook.net',
  'www.facebook.com',
  // '*.typeform.com',
];

const contentSecurityPolicy_font_src = [
  'fonts.gstatic.com',
  'fonts.intercomcdn.com',
  'cdn.edgardcooper.com',
  // '*.typeform.com',
  'https://vercel.live', // vercel toolbar
  'https://assets.vercel.com', // vercel toolbar
  '*.klaviyo.com',
  '*.typekit.net', // klaviyo
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
];

const contentSecurityPolicy_style_src = [
  'fonts.googleapis.com',
  // 'tagmanager.google.com',
  'www.gstatic.com',
  'sdk.loyaltylion.net',
  'sdk-static.loyaltylion.net',
  // 'optimize.google.com',
  // '*.bing.com',
  '*.typekit.net', // klaviyo
  // '*.typeform.com',
  'https://vercel.live', // vercel toolbar,
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
];

const contentSecurityPolicy_style_src_elem = [
  'fonts.googleapis.com',
  'use.typekit.net',
  'sdk-static.loyaltylion.net',
  '*.typekit.net', // klaviyo
  '*.klaviyo.com',
  'https://vercel.live', // vercel toolbar
  'https://surveys-static.survicate.com',
  'https://surveys-static-prd.survicate-cdn.com',
  'googleads.g.doubleclick.net',
  'widget.trustpilot.com',
  'www.gstatic.com',
  'www.googletagmanager.com',
];

const contentSecurityPolicy_media_src = [
  'js.intercomcdn.com',
  '*.ctfassets.net',
  //  '*.typeform.com'
  '*.gstatic.com',
];

const contentSecurityPolicy_frame_src = [
  'https://consentcdn.cookiebot.com',
  'widget.trustpilot.com',
  // 'optimize.google.com',
  // 'sdx.microsoft.com',
  // 'bid.g.doubleclick.net',
  'www.facebook.com',
  // 'https://anchor.fm/',
  // '*.typeform.com',
  'https://intercom-sheets.com',
  'https://vercel.live', // vercel toolbar
  'https://www.googletagmanager.com',
  'https://td.doubleclick.net', // google tag manager
];

// const contentSecurityPolicy_child_src = [
//   'https://intercom-sheets.com',
//   'https://www.intercom-reporting.com',
//   'https://www.youtube.com',
//   'https://player.vimeo.com',
//   'https://fast.wistia.net',
// ];

// https://csp.withgoogle.com/docs/strict-csp.html
const contentSecurityPolicy = [
  "default-src 'self' www.edgardcooper.com;",
  "base-uri 'self';",
  // unsafe-inline is needed for Segment/Google Tag Manager :'(
  // unsafe-eval is needed for Google tag manager :'(
  `script-src 'self' blob: *.edgardcooper.com 'unsafe-inline' 'unsafe-eval' ${contentSecurityPolicy_script_src.join(
    ' ',
  )};`,
  // unsafe-inline is needed for Segment/Google Tag Manager :'(
  // unsafe-eval is needed for Google tag manager :'(
  `script-src-elem 'self' blob: *.edgardcooper.com 'unsafe-inline' 'unsafe-eval' ${contentSecurityPolicy_script_src.join(
    ' ',
  )};`,
  `connect-src 'self' *.edgardcooper.com ${contentSecurityPolicy_connect_src.join(' ')};`,
  `font-src 'self' data: ${contentSecurityPolicy_font_src.join(' ')};`,
  `form-action 'self' ${contentSecurityPolicy_form_action.join(' ')};`,
  `frame-src 'self' ${contentSecurityPolicy_frame_src.join(' ')};`,
  // `child-src 'self' ${contentSecurityPolicy_child_src.join(' ')};`,
  // 'unsafe-inline' is needed for cookiebot cookiecontrol dialog :'( and (vercel toolbar)
  `style-src 'self' 'unsafe-inline' ${contentSecurityPolicy_style_src.join(' ')};`,
  `style-src-elem 'self' 'unsafe-inline' ${contentSecurityPolicy_style_src_elem.join(' ')};`,
  `img-src 'self' data: blob: *.edgardcooper.com ${contentSecurityPolicy_img_src.join(' ')};`,
  "object-src 'none';",
  `media-src 'self' *.edgardcooper.com ${contentSecurityPolicy_media_src.join(' ')};`,
  // Report endpoint https://docs.sentry.io/security-legal-pii/security/security-policy-reporting/
  // NOTE: does not work with <meta> tag, only with headers version
  // `report-uri https://o507535.ingest.us.sentry.io/api/6110335/security/?sentry_key=ef59b425ac004ac491f593a295649110&sentry_environment=${process.env.NEXT_PUBLIC_SENTRY_ENV};`,
  // `report-to csp-endpoint`,
].join(' ');

export default contentSecurityPolicy;
