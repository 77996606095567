import React, { FocusEventHandler, ForwardedRef } from 'react';

import classNames from 'classnames';

import Labelled from 'components/ui/Labelled';

import styles from './Select.module.css';

interface StrictGroup {
  /** Title for the group */
  title: string;
  /** List of options */
  options: StrictOption[];
}

export interface SelectGroup {
  title: string;
  options: SelectOption[];
}

interface StrictOption {
  /** Machine value of the option; this is the value passed to `onChange` */
  value: string | number;
  /** Human-readable text for the option */
  label: string;
  /** Option will be visible, but not selectable */
  disabled?: boolean;
}

export type SelectOption = string | StrictOption;

export interface Props {
  /** Display an error state */
  error?: string | boolean;
  /** List of options or option groups to choose from */
  options?: (SelectOption | SelectGroup)[];
  /** Label for the select */
  label: string;
  /** Element next to the label */
  labelAside?: React.ReactNode;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Name for form input */
  name?: string;
  /** initial value for the input */
  value?: string;
  /** default value for the input */
  defaultValue?: string | number;
  /** Disable input */
  disabled?: boolean;
  /** ID for form input */
  id: string;
  /** Hint text to display */
  placeholder?: string;
  /** Additional hint text to display */
  helpText?: string;
  /** Additional class names */
  className?: string;
  /** Loading state */
  isLoading?: boolean;
  /** Different color */
  isCat?: boolean;
  /** Callback when selection is changed */
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
  /** Callback when input is focused */
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  /** Callback when focus is removed */
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  ref?: ForwardedRef<HTMLSelectElement>;
}

/**
 * Converts a string into an option object
 */
const normalizeStringOption = (option: string): StrictOption => ({
  label: option,
  value: option,
});

const isString = (option: SelectOption | SelectGroup): option is string =>
  typeof option === 'string';

const isGroup = (option: SelectOption | SelectGroup): option is SelectGroup =>
  typeof option === 'object' && 'options' in option && option.options != null;

/**
 * Converts a string option (and each string option in a Group) into
 * an Option object.
 */
const normalizeOption = (option: SelectOption | SelectGroup): StrictOption | StrictGroup => {
  if (isString(option)) {
    return normalizeStringOption(option);
  }
  if (isGroup(option)) {
    const { title, options } = option;
    return {
      title,
      options: options.map((groupOption) =>
        isString(groupOption) ? normalizeStringOption(groupOption) : groupOption,
      ),
    };
  }

  return option;
};

/**
 * Renders a single option
 */
const renderSingleOption = (option: StrictOption): React.ReactNode => {
  const { value, label, ...rest } = option;
  return (
    <option key={value} value={value} {...rest}>
      {label}
    </option>
  );
};

/**
 * Renders a group or a single option
 */
const renderOption = (optionOrGroup: StrictOption | StrictGroup): React.ReactNode => {
  if (isGroup(optionOrGroup)) {
    const { title, options } = optionOrGroup;
    return (
      <optgroup label={title} key={title}>
        {options.map(renderSingleOption)}
      </optgroup>
    );
  }

  return renderSingleOption(optionOrGroup);
};

/**
 * TODO: use React-Select package instead https://www.npmjs.com/package/react-select
 * https://app.asana.com/0/1200162659197790/1200642586093462/f
 */
const Select = (
  {
    error,
    options = [],
    value,
    defaultValue,
    disabled,
    isLoading,
    label,
    labelAside,
    labelHidden,
    name,
    id,
    placeholder,
    helpText,
    onChange,
    onFocus,
    onBlur,
    isCat,
    className: additionalClassname,
    ...additionalProps
  }: Props,
  ref,
): JSX.Element => {
  let normalizedOptions = options.map(normalizeOption);
  if (placeholder) {
    normalizedOptions = [
      {
        label: !isLoading ? placeholder : '',
        value: '',
        disabled: true,
      },
      ...normalizedOptions,
    ];
  }
  const optionsMarkup = normalizedOptions.map(renderOption);

  const className = classNames(
    styles.select,
    (disabled || isLoading) && styles['select--disabled'],
    error && styles['select--error'],
    isLoading && 'animate-pulse',
    additionalClassname,
  );

  const selectProps = {
    className,
    value,
    defaultValue,
    disabled,
    isloading: isLoading?.toString() ?? 'false',
    name,
    ref,
    id,
    onChange,
    onFocus,
    onBlur,
    ...additionalProps,
  };

  if (value) {
    selectProps.value = value;
  } else {
    selectProps.defaultValue = defaultValue || '';
  }
  return (
    <Labelled
      id={id}
      label={label}
      labelHidden={labelHidden}
      error={error}
      helpText={helpText}
      labelAside={labelAside}
      isCat={isCat}
    >
      <select {...selectProps}>{optionsMarkup}</select>
    </Labelled>
  );
};

export default React.forwardRef<HTMLSelectElement, Props>(Select);
