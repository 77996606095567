import useAddToCart from './useAddToCart';
import useClearCart from './useClearCart';
import useRedirectToCheckout from './useRedirectToCheckout';
import useRemoveFromCart from './useRemoveFromCart';
import useUpdateCartItem from './useUpdateCartItem';
import type { ExtendedShopifyCart } from '../..';
import type useCartStates from '../useCartStates';
import type useCartTracking from '../useCartTracking';
import type useExtendShopifyCart from '../useExtendShopifyCart';

export type CartItemType = 'regular' | 'suggested';

const useCartActions = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
  cartTracking: ReturnType<typeof useCartTracking>,
) => {
  const addToCart = useAddToCart(shopifyCart, cartStates, extendShopifyCart, cartTracking);
  const removeFromCart = useRemoveFromCart(
    shopifyCart,
    cartStates,
    extendShopifyCart,
    cartTracking,
  );
  const updateCartItem = useUpdateCartItem(
    shopifyCart,
    cartStates,
    extendShopifyCart,
    cartTracking,
  );
  const clearCart = useClearCart(shopifyCart, cartStates, extendShopifyCart);
  const redirectToCheckout = useRedirectToCheckout(shopifyCart, cartStates);

  return {
    addToCart,
    removeFromCart,
    updateCartItem,
    clearCart,
    redirectToCheckout,
  };
};

export default useCartActions;
