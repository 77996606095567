import { Product } from 'api/generated-types';
import PetSurveyV2Input from 'components/parts/PetSurveyV2/PetSurveyV2Input';

import { ProductQuizProtocol } from '../protocols';

const mapEventPropertiesToProductQuizProtocol = (
  properties: PetSurveyV2Input,
): ProductQuizProtocol => ({
  quiz_id: properties?.id,
  animal: properties?.animal,
  gender: properties?.gender,
  name: properties?.name,
  breed_type: properties?.breed,
  breed1: properties?.breed1,
  breed2: properties?.breed2,
  breed3: properties?.breed3,
  breed_size: properties?.breedSize,
  age_category: properties?.age,
  is_neutered: properties?.neutered === 'yes',
  is_grain_free: properties?.grainFree === 'yes',
  weight: properties?.weight ? `${properties?.weight}${properties?.weightMetric}` : undefined,
  body_type: properties?.bodyType,
  activity: properties?.activity,
  food_type: properties?.foodType,
  appetite: properties?.appetite,
  health_benefits: properties?.health,
  allergies: properties?.allergies,
  flavours: properties?.flavours,
  current_brand: properties?.brand,
  // Dog only
  treat_type: properties?.treatType,
  // Cat only
  wet_food_texture: properties?.wetFoodTexture,
  // Email experiment
  email: properties?.email,
  email_marketing_consent: properties?.emailMarketingConsent,
});

const mapProductQuizProducts = (
  products: Product[],
): { universal_key: string; health_benefits: string[]; position: number }[] =>
  products.map((product, index) => ({
    universal_key: product.universalKey,
    health_benefits: product.masterData.healthFocusAreas.map((hfa) => hfa.key),
    position: index + 1,
  }));

export { mapEventPropertiesToProductQuizProtocol, mapProductQuizProducts };
