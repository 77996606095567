import { useRef } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

import type { CartItemType } from '.';
import type { ExtendedShopifyCart } from '../..';
import useCartStates from '../useCartStates';
import type useCartTracking from '../useCartTracking';
import type useExtendShopifyCart from '../useExtendShopifyCart';

/**
 * @private Hook that returns a function to update item in cart
 */
const useUpdateCartItem = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
  cartTracking: ReturnType<typeof useCartTracking>,
) => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();
  const updateShopifyCartTimeout = useRef<ReturnType<typeof setTimeout>>();
  const queryClient = useQueryClient();

  const updateCartItem = async (
    universalKey: string,
    quantity: number,
    type: CartItemType,
    options?: {
      onSuccess?: (universalKey) => void;
      onError?: (universalKey) => void;
    },
  ): Promise<void> => {
    /** Get the cart line id corresponding to the universalKey */
    const shopifyCartLine = shopifyCart.lines.edges.find(
      (edge) =>
        edge.node.merchandise.sku === universalKey &&
        (type === 'regular' || (type === 'suggested' && edge.node.ecLineItemType === 'suggested')),
    )?.node;
    const quantityBeforeUpdate = shopifyCartLine?.quantity;

    /**
     * Only call Shopify if at least 300ms has passed since the last update
     */
    clearTimeout(updateShopifyCartTimeout.current);
    updateShopifyCartTimeout.current = setTimeout(async () => {
      // Lock the cart here, else you can't spam the + or - button in QuantityPicker
      cartStates.setIsCartUpdating(true);

      // Update the cart in Shopify
      await storefrontSDK
        .cartLinesUpdate({
          cartId: shopifyCart.id,
          lines: [
            {
              id: shopifyCartLine.id,
              quantity,
              attributes: shopifyCartLine.attributes.map((attr) => ({
                key: attr.key,
                value: attr.value,
              })),
            },
          ],
        })
        .then((res) => {
          if (res.cartLinesUpdate.userErrors.length > 0) {
            throw new Error(
              `Could not update cart line due to userErrors: ${JSON.stringify(
                res.cartLinesUpdate.userErrors,
              )}`,
            );
          }

          // Set query data based on result
          return extendShopifyCart(res.cartLinesUpdate.cart).then((extendedCart) => {
            // If cookie id changed, set client side cookie
            setClientSideCookie(CookieNames.CartId, res.cartLinesUpdate.cart.id);
            return queryClient.setQueryData(
              QueryKeys[Keys.Cart](countryCode, locale),
              () => extendedCart,
            );
          });
        })
        .then((extendedCart) => {
          if (options?.onSuccess) {
            options.onSuccess(universalKey);
          }
          cartTracking.trackCartUpdated(
            shopifyCart.id,
            'update',
            extendedCart.lines.edges.find((edge) => edge.node.merchandise.sku === universalKey)
              .node,
            quantity,
            type,
            quantityBeforeUpdate,
          );
        })
        .catch((err) => {
          Sentry.captureException(err);
          if (options?.onError) {
            options.onError(universalKey);
          }
          // On error, refetch cart so item quantity will return back to server data
          queryClient.refetchQueries(QueryKeys[Keys.Cart](countryCode, locale));
        });

      // Release the lock when done
      cartStates.setIsCartUpdating(false);
    }, 300);
  };

  return updateCartItem;
};

export default useUpdateCartItem;
