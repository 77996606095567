import React from 'react';

import classNames from 'classnames';

import Checkbox from 'components/ui/Checkbox';
import RadioButton from 'components/ui/RadioButton';

interface Choice {
  /** id of the choice */
  id: string;
  /** Value of the choice */
  value: string;
  /** Label for the choice */
  label: string;
  /** Disable choice */
  disabled?: boolean;
  /** Additional text to aide in use */
  helpText?: string;
}

export interface Props {
  /** Allow to select multiple options at once */
  allowMultiple?: boolean;
  /** Collection of choices */
  choices: Choice[];
  /** Disable all choices * */
  disabled?: boolean;
  /** Display an error message */
  error?: string;
  /** Name for form input */
  name?: string;
  /** Collection of selected choices */
  selected: string[];
  /** Label for list of choices */
  label: string;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Callback when the selected choices change */
  onChange?(selected: string[], name: string): void;
}

const choiceIsSelected = ({ value }: Choice, selected: string[]) => selected.includes(value);

const updateSelectedChoices = (
  { value }: Choice,
  checked: boolean,
  selected: string[],
  allowMultiple = false,
) => {
  if (checked) {
    return allowMultiple ? [...selected, value] : [value];
  }
  return selected.filter((selectedChoice) => selectedChoice !== value);
};

const ChoiceList: React.FC<Props> = ({
  allowMultiple,
  choices,
  disabled = false,
  error,
  name,
  selected,
  label,
  labelHidden,
  onChange,
}: Props) => {
  const ControlComponent = allowMultiple ? Checkbox : RadioButton;
  const finalName = allowMultiple ? `${name}[]` : name;
  const titleMarkup = label ? (
    <legend
      className={classNames(
        'text-sm text-blue-300 uppercase mb-1',
        labelHidden && 'visually-hidden',
      )}
    >
      {label}
    </legend>
  ) : null;

  const choicesMarkup = choices?.map((choice: Choice) => {
    const { value, label: choiceLabel, disabled: choiceDisabled, id, helpText } = choice;

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      onChange(
        updateSelectedChoices(choice, event.currentTarget.checked, selected, allowMultiple),
        name,
      );
    };

    const controlMarkup = (
      <>
        <ControlComponent
          id={id}
          name={finalName}
          value={value}
          label={choiceLabel}
          helpText={helpText}
          disabled={choiceDisabled || disabled}
          checked={choiceIsSelected(choice, selected)}
          onChange={handleChange}
        />
      </>
    );

    return <li key={value}>{controlMarkup}</li>;
  });

  const errorMarkup = error && <div className="text-base mt-1 text-red-400">{error}</div>;

  return (
    <fieldset id={finalName}>
      {titleMarkup}
      <ul className="flex-gap-2">{choicesMarkup}</ul>
      {errorMarkup}
    </fieldset>
  );
};

export default ChoiceList;
