import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';

import Icon from '../Icon';

interface AccordionItem {
  title: string;
  description: string | JSX.Element;
}

interface Props {
  items: AccordionItem[];
  color?: 'light' | 'dark';
  className?: string;
}

const Accordion: React.FC<Props> = ({ items, color = 'light', className: additionalClassName }) => (
  <div className={classNames('flex flex-col flex-gap-y-4', additionalClassName)}>
    {items.map((item) => (
      <Disclosure
        as="div"
        className="flex flex-col border rounded bg-white overflow-hidden"
        key={item.title}
      >
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                `flex items-center justify-between text-left p-4 text-xl transition
                focus:outline-none md:p-6 md:text-xl font-medium`,
                color === 'dark'
                  ? 'hover:bg-cyan-400 focus:bg-cyan-400 hover:text-white focus:text-white'
                  : 'hover:bg-cyan-200 focus:bg-cyan-200',
              )}
            >
              {item.title}
              <Icon
                name={open ? 'minus' : 'plus'}
                className="w-6 ml-4 flex-none self-start md:w-8"
              />
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500 p-4 border-t md:p-6">
              {item.description}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ))}
  </div>
);

export default Accordion;
