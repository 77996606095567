import { gql } from 'graphql-request';

import { authenticatedBffRequest } from 'api/client/bff-request';
import { CurrentAuthenticatedUser } from 'services/session/types/CurrentAuthenticatedUser';

const query = gql`
  mutation handleSignInUser($locale: String!, $countryCode: String) {
    handleSignInUserV2(locale: $locale, countryCode: $countryCode) {
      sub
      email
      customShopifyUserId
      emailVerified
      previousEmail
      userLocale
    }
  }
`;

// After being login the follow flow is executed: https://app.diagrams.net/?src=about#Hedgardcooper%2Fdiagrams%2Fmain%2FAuthentication%20flows.drawio%23%7B%22pageId%22%3A%22_ftkWgfcCiLZZXRn0TXn%22%7D
const handleSignInUser = async (
  locale: string,
  countryCode?: string,
): Promise<CurrentAuthenticatedUser> => {
  const authenticatedUser = await authenticatedBffRequest<{
    handleSignInUserV2: CurrentAuthenticatedUser;
  }>({
    query,
    variables: { locale, countryCode },
  });

  return authenticatedUser.handleSignInUserV2;
};

export default handleSignInUser;
