import { createContext, FC, PropsWithChildren, useContext, useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useRouter } from 'next/router';

import { authenticatedBffRequest } from 'api/client/bff-request';
import subscriptionFragment from 'api/client/fragments/subscription';
import { Keys, QueryKeys } from 'api/client/QueryKeys';
import {
  Subscription as SubscriptionType,
  Mandate as MandateType,
  CountryWithProvinces,
} from 'api/generated-types';
import { useSessionContext } from 'services/session';

import { useCountryContext } from './CountryContextProvider';
import { useCart } from './ShopifyCartProvider';

const subscriptionQuery = gql`
  ${subscriptionFragment}
  query getSubscription($locale: String!) {
    subscription(locale: $locale) {
      ...subscriptionFields
    }
  }
`;

const subscriptionMandateQuery = gql`
  query getSubscriptionMandate {
    subscriptionMandate {
      method
      status
      holder
      number
      label
    }
  }
`;

interface SubscriptionContext {
  isLoading: boolean;
  isError: boolean;
  subscription: SubscriptionType;
  subscriptionMandate: MandateType;
  subscriptionShippingCountries: CountryWithProvinces[];
  isActiveSubscription: boolean;
}

const Context = createContext<SubscriptionContext>(null);

export const useSubscription: () => SubscriptionContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useSubscription must be used within a SubscriptionProvider tag');
  }
  return contextState;
};

interface Data {
  subscription: SubscriptionType;
  subscriptionMandate: MandateType;
}

type QueryVars = {
  locale: string;
};

const subscriptionQueryFn = async (variables: QueryVars): Promise<Data> =>
  authenticatedBffRequest({ query: subscriptionQuery, variables });

const subscriptionMandateQueryFn = async (variables: QueryVars): Promise<Data> =>
  authenticatedBffRequest({ query: subscriptionMandateQuery, variables });

const useSubscriptionQuery = () => {
  const { user } = useSessionContext();
  const { locale } = useRouter();
  return useQuery<Data>(
    QueryKeys[Keys.Subscription](locale, user?.email),
    () => (user?.email ? subscriptionQueryFn({ locale }).catch(() => null) : null),
    {
      staleTime: Infinity,
      enabled: !!user,
    },
  );
};

const SubscriptionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { shippingCountries } = useCountryContext();
  const { cartStates } = useCart();

  const {
    data: subscriptionData,
    isInitialLoading: subscriptionIsLoading,
    isError: subscriptionIsError,
  } = useSubscriptionQuery();

  const useSubscriptionMandateQuery = () => {
    const { locale } = useRouter();
    const { user } = useSessionContext();

    return useQuery<Data>(
      QueryKeys[Keys.SubscriptionMandate](locale, user?.email),
      () => (user?.email ? subscriptionMandateQueryFn({ locale }).catch(() => null) : null),
      {
        staleTime: Infinity,
        enabled: !!user && !!subscriptionData?.subscription,
      },
    );
  };

  const {
    data: subscriptionMandateData,
    // isLoading is always true when the query is always disabled
    // isInitialLoading = isLoading && isFetching
    isInitialLoading: subscriptionMandateIsLoading,
    isError: subscriptionMandateIsError,
  } = useSubscriptionMandateQuery();

  const isActiveSubscription = (subscriptionStatus) =>
    ['active', 'paused'].includes(subscriptionStatus);

  useEffect(() => {
    const subscription = { ...subscriptionData?.subscription };
    const lineItemsInfo = subscriptionData?.subscription?.lineItems?.map((item) => ({
      id: item.id,
      price: item.price,
      quantity: item.quantity,
    }));

    // Remove lineItems out of Sentry payload
    delete subscription?.lineItems;

    Sentry.setContext('subscription', {
      subscription: JSON.stringify(subscription, null, 2),
      lineItems: JSON.stringify(lineItemsInfo, null, 2),
    });
  }, [subscriptionData, subscriptionMandateData]);

  return (
    <Context.Provider
      value={{
        isLoading:
          subscriptionIsLoading ||
          subscriptionMandateIsLoading ||
          !shippingCountries ||
          cartStates.isCartLoading,
        isError: subscriptionIsError || subscriptionMandateIsError,
        subscription: subscriptionData?.subscription,
        subscriptionMandate: subscriptionMandateData?.subscriptionMandate,
        subscriptionShippingCountries: shippingCountries,
        isActiveSubscription: isActiveSubscription(subscriptionData?.subscription?.status),
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SubscriptionProvider;
