const baseUrl = (): string => {
  // For custom domains, we need to set the base url - e.g. production and staging
  if (process.env.NEXT_PUBLIC_BASE_URL) {
    return process.env.NEXT_PUBLIC_BASE_URL;
  }
  // For development, the env variable can be null - either way we always use localhost on port 3000
  if (!process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV === 'development') {
    return 'http://localhost:3000';
  }
  // If no custom domain is set and it isn't development. We are in a preview build, the URL is decided at build time.
  return globalThis?.location?.origin || `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
};

export default baseUrl();
