// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^\+?[0-9 ]*$/;

export const VARIANT_ID_REGEX = /^[0-9]{14}$/;

export const DASHES_REGEX = /\s+/g;

export const MIN_PHONE_LENGTH = 10;

export const NO_SPECIAL_CHARS_REGEX = /^[^*|":<>[\]{}`\\()';@&$€!%=+#]+$/;

// TODO: check if this change breaks anything
// This caused hydration errors because it is lowercase but the cookie value is uppercased
export const FALLBACK_COUNTRY_CODE = 'OTHER';

export const FALLBACK_CUTOFFTIME = '16:00';

export const FALLBACK_LOCALE = 'en';

export const BUILD_COUNTRY_CODES = ['BE', 'GB'];

export const SUBSCRIPTION_DISCOUNT_PERCENTAGE = 5;

export const PLASTIC_BAGS_SAVED = 37507604;

export const DONATED_TO_CHARITY = 1096764;

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 256;

export const LBS_TO_KG = 0.45359237;
export const G_PER_KG = 1000;

export const MAX_PET_NAME_LENGTH = 30;
export const MAX_PET_WEIGHT_KGS_OR_LBS = 200;

export const GA4_MEASUREMENT_ID_GLOBAL_PRD = 'G-43TJKEXXZ4';
export const GA4_MEASUREMENT_ID_GLOBAL_TST = 'G-7Q29898NV5';

export enum GlobalSearchParams {
  COUNTRY_CODE = 'cc',
  DISCOUNT_CODE = 'discount',
}

export enum CookieNames {
  // Read more at https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
  PreferredLocale = 'NEXT_LOCALE',
  CheckoutId = 'checkout-id',
  CartPreferences = 'cart',
  CartId = 'cart-id',
  SegmentId = 'ajs_anonymous_id',
  SessionId = 'ec-session-id',
  Discount = 'ec-discount-code',
  IsPreviewSession = 'ec-is-preview-session',
  HideSuggestedProductInCart = 'ec-hide-suggested-product-in-cart',
  CountrySettings = 'ec-country-settings',
  TikTokClickId = 'ec-ttclid',
  PetSurveyDog = 'ec-pet-survey-dog',
  PetSurveyCat = 'ec-pet-survey-cat',
  NeedsToSetCountryCode = 'needsToSetCountryCode',
  CountryCode = 'countryCode',
  GaSessionIdPrd = '_ga_43TJKEXXZ4',
  GaSessionIdDev = '_ga_7Q29898NV5',
  GaClientId = '_ga',
  BotSession = 'ec-is-bot-session',
  CookieConsent = 'CookieConsent',
  Geolocation = 'ec-geolocation',
}

export enum CountrySettingsCookieProperties {
  countryCode = 'countryCode',
  currencyCode = 'currencyCode',
  isSubscriptionEnabled = 'isSubscriptionEnabled',
  deliveryDayBeforeCutOff = 'deliveryDayBeforeCutOff',
  deliveryDayAfterCutOff = 'deliveryDayAfterCutOff',
  cutOffDateTime = 'cutOffDateTime',
}
