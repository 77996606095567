import { ArticleIntroduction } from '../../generated-types';

const getArticleExcerpt = (introduction: ArticleIntroduction): string => {
  if (introduction?.json?.content?.length) {
    const jsonFirstContent = introduction?.json?.content[0];
    if (jsonFirstContent?.content?.length) {
      const content = introduction?.json?.content[0].content[0]?.value;
      const splitWords = content.split(' ');
      if (splitWords.length <= 18) {
        return content;
      }
      return `${splitWords.slice(0, 17).join(' ')}...`;
    }
  }
  return '';
};

export default getArticleExcerpt;
