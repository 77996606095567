import { useEffect, useState } from 'react';

// Returned by hook
export interface UseViewportResult {
  width: number;
  isMobile: boolean;
}

const useViewport = (): UseViewportResult => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    }
    return null;
  }, []);

  useEffect(() => {
    if (width >= 1024) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  // Return the width so we can use it in our components
  return { width, isMobile };
};

export default useViewport;
