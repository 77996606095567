import FeedingCalculatorInput from 'components/parts/FeedingCalculator/FeedingCalculatorInput';

import { FeedingCalculatorProtocol, FeedingCalculatorResultProtocol } from '../protocols';

const mapEventPropertiesToFeedingCalculatorProtocol = (
  properties: FeedingCalculatorInput,
  resultData: FeedingCalculatorResultProtocol,
  isEmptyresult: boolean,
): FeedingCalculatorProtocol => ({
  flow_id: properties?.id,
  animal: properties?.animalType,
  breed_type: properties?.breed,
  breed1: properties?.breed1,
  breed_size: properties?.breedSize,
  age_category: properties?.age,
  is_neutered: properties?.isNeutered,
  expected_mature_weight: properties?.matureWeight
    ? `${properties?.matureWeight}${properties?.weightUnit}`
    : undefined,
  activity_level: properties?.activityIndex,
  weight: properties?.weight ? `${properties?.weight}${properties?.weightUnit}` : undefined,
  body_type: properties?.bodyCondition,
  is_giving_treats: properties?.treatsActive,
  daily_energy_requirement: Math.round(resultData.daily_energy_requirement),
  recommended_dry_food: Math.round(resultData.recommended_dry_food),
  recommended_wet_food: Math.round(resultData.recommended_wet_food),
  wet_food_type:
    properties?.animalType === 'dog'
      ? properties?.packagingCategory
      : properties?.foodPreferenceWet,
  food_type: properties?.foodPreference === 'dry,wet' ? 'mixed' : properties?.foodPreference,
  junior_age_stage: properties?.juniorAgeStage,
  is_empty_result: isEmptyresult,
});

export default mapEventPropertiesToFeedingCalculatorProtocol;
