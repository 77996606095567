import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';

import { NextRouter } from 'next/router';

export const getAsUrlForRouter = (
  router: NextRouter,
  query?: ParsedUrlQuery,
  translatedSlug?: string,
): UrlObject => {
  const newQuery = { ...(query || router.query) };
  delete newQuery.countryCodeWithPrefix;
  delete newQuery.articleSlug;
  delete newQuery.breedSlug;
  // Delete slug because this is a catch all parameter and is already part of the url path
  delete newQuery.slug;
  return { pathname: translatedSlug || router.asPath.split('?')[0], query: newQuery };
};

export const getVariantSlugFromPath = (router: NextRouter): string =>
  router.query?.slug?.length === 2 ? router.query?.slug[1] : null;
