/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MouseEventHandler } from 'react';

import Link from 'next/link';

export interface BlogCategoryLinkProps {
  name: string;
  color: string;
  href: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const BlogCategoryLink = ({
  name,
  color,
  href,
  onClick,
  className,
}: BlogCategoryLinkProps): JSX.Element => (
  <Link
    href={href}
    className={`transition-colors text-blue-400 hover:text-blue-500 border-b-6 ${className}`}
    style={{ borderColor: color }}
    onClick={onClick}
  >
    {name}
  </Link>
);

export default BlogCategoryLink;
