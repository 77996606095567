import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import isProductVariantInStock from 'api/client/queries/isProductVariantInStock';
import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { variantIdToPlainGid } from 'services/shopify/utils';
import { CookieNames } from 'utils/constants';

import type { CartItemType } from '.';
import useUpdateCartItem from './useUpdateCartItem';
import type { ExtendedShopifyCart } from '../..';
import type useCartStates from '../useCartStates';
import type useCartTracking from '../useCartTracking';
import type useExtendShopifyCart from '../useExtendShopifyCart';

/**
 * @private Hook that returns a function to add item to cart
 */
const useAddToCart = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
  cartTracking: ReturnType<typeof useCartTracking>,
) => {
  const { countryCode } = useCountryContext();
  const queryClient = useQueryClient();
  const { locale } = useRouter();
  const updateCartItem = useUpdateCartItem(
    shopifyCart,
    cartStates,
    extendShopifyCart,
    cartTracking,
  );

  const addToCart = async (
    universalKey: string,
    shopifyVariantId: string,
    quantity: number,
    type: CartItemType,
    options?: {
      onSuccess?: (universalKey: string) => void;
      onError?: (universalKey: string) => void;
      onOutOfStock?: (universalKey: string) => void;
    },
  ) => {
    cartStates.setIsCartUpdating(true);

    try {
      /**
       * Check if the product variant is in stock through BFF (actuals)
       */
      const isInStock = await isProductVariantInStock(countryCode, universalKey);
      if (!isInStock) {
        cartStates.setIsCartUpdating(false);
        if (options?.onOutOfStock) options.onOutOfStock(universalKey);
        return;
      }

      /**
       * If we already have an item in the cart with attribute _suggested, update the quantity
       * This approach isn't needed in normal cases, but in our case it is needed because of the _suggested attribute
       * We can't have 2 the same products displayed seperately in our cart (one with suggested attr), so we need to update the quantity
       */
      const existingSuggestedItem = shopifyCart.lines.edges.find(
        (edge) =>
          edge.node.merchandise.sku === universalKey && edge.node.ecLineItemType === 'suggested',
      )?.node;

      if (existingSuggestedItem) {
        await updateCartItem(universalKey, existingSuggestedItem.quantity + quantity, 'suggested', {
          onSuccess: options?.onSuccess,
          onError: options?.onError,
        });
        cartStates.setIsCartUpdating(false);
        return;
      }

      /**
       * Try to add item to Shopify Cart
       */
      await storefrontSDK
        .cartLinesAdd({
          cartId: shopifyCart.id,
          lines: [
            {
              merchandiseId: variantIdToPlainGid(shopifyVariantId),
              quantity,
              ...(type === 'suggested'
                ? {
                    attributes: [{ key: '_suggested', value: 'true' }],
                  }
                : {}),
            },
          ],
        })
        .then((res) => {
          if (res.cartLinesAdd.userErrors.length > 0) {
            throw new Error(
              `Could not add item to cart due to user errors: ${JSON.stringify(
                res.cartLinesAdd.userErrors,
              )}`,
            );
          }
          // Set query data based on result
          return extendShopifyCart(res.cartLinesAdd.cart).then((extendedCart) => {
            // If cookie id changed, set client side cookie
            setClientSideCookie(CookieNames.CartId, res.cartLinesAdd.cart.id);
            queryClient.setQueryData(QueryKeys[Keys.Cart](countryCode, locale), () => extendedCart);
            return extendedCart;
          });
        })
        .then((extendedCart) => {
          if (options?.onSuccess) {
            options.onSuccess(universalKey);
          }
          cartTracking.trackCartUpdated(
            shopifyCart.id,
            'add',
            extendedCart.lines.edges.find((edge) => edge.node.merchandise.sku === universalKey)
              .node,
            quantity,
            type,
          );
        });
    } catch (err) {
      /**
       * Capture exceptions
       */
      Sentry.captureException(err);
      if (options?.onError) {
        options.onError(universalKey);
      }
    }

    cartStates.setIsCartUpdating(false);
  };

  return addToCart;
};

export default useAddToCart;
