import { GraphQLClient } from 'graphql-request';

const getContentfulGraphQLClient = (isPreview: boolean): GraphQLClient =>
  new GraphQLClient(
    `https://graphql.contentful.com/content/v1/spaces/${
      process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID
    }/environments/${process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID}?access_token=${
      isPreview
        ? process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_TOKEN
        : process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_TOKEN
    }`,
  );

export default getContentfulGraphQLClient;
