import React, { ReactElement } from 'react';

import classNames from 'classnames';

export interface Props {
  /**  Content to display inside the choice */
  children?: React.ReactNode;
  /** Whether the associated form control is disabled */
  disabled?: boolean;
  /** Display an error message */
  error?: string | boolean;
  /** Additional text to aide in use */
  helpText?: React.ReactNode;
  /** A unique identifier for the choice */
  id: string;
  /**	Label for the choice */
  label: string | ReactElement;
  /** Visually hide the label */
  labelHidden?: boolean;
}

const Choice: React.FunctionComponent<Props> = ({
  children,
  error,
  helpText,
  id,
  label,
  labelHidden,
  disabled,
}: Props) => {
  const labelMarkup = (
    <label
      className={classNames('flex items-center', disabled ? 'cursor-default' : 'cursor-pointer')}
      htmlFor={id}
    >
      <span className="inline-flex">{children}</span>
      <span
        className={classNames('text-gray-300 text-base pl-2', labelHidden && 'visually-hidden')}
      >
        {label}
      </span>
    </label>
  );

  const errorMarkup = error && typeof error !== 'boolean' && (
    <div className="text-red-400">{error}</div>
  );

  const helpTextMarkup = helpText ? (
    <div className="text-gray-300 font-medium mt-0.5">{helpText}</div>
  ) : null;

  const descriptionMarkup =
    helpTextMarkup || errorMarkup ? (
      <div className="text-base mt-1 pl-7">
        {errorMarkup}
        {helpTextMarkup}
      </div>
    ) : null;

  return descriptionMarkup ? (
    <div>
      {labelMarkup}
      {descriptionMarkup}
    </div>
  ) : (
    labelMarkup
  );
};

export default Choice;
