import { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { gql } from 'graphql-request';
import { useRouter } from 'next/router';
import { v4 as uuidv4 } from 'uuid';

import { bffRequest } from 'api/client/bff-request';
import productVariantFragment from 'api/client/fragments/productVariant';
import type {
  ProductSpotlight,
  ProductSpotlightCartInput,
  ProductVariant,
} from 'api/generated-types';
import { useCountryContext } from 'context/CountryContextProvider';

import usecartPreferences from './useCartPreferences';
import type { ExtendedShopifyCart } from '..';

const productsInSpotlightQuery = gql`
  ${productVariantFragment}

  query productsInSpotlight(
    $locale: String!
    $countryCode: String!
    $cart: ProductSpotlightCartInput!
  ) {
    productsInSpotlight(locale: $locale, countryCode: $countryCode, cart: $cart) {
      freeProduct {
        isValid
        label
        minValue {
          amount
          formattedPrice
        }
        productVariant {
          ...productVariantFields
        }
      }
      suggestedProducts {
        label
        productVariant {
          ...productVariantFields
        }
      }
    }
  }
`;

export interface SuggestedProductData {
  productVariant: ProductVariant;
  label: string;
}

interface ProductSpotlightData {
  productsInSpotlight: ProductSpotlight;
}

interface ProductsInSpotlightVariables {
  locale: string;
  countryCode: string;
  cart: ProductSpotlightCartInput;
}

const getProductsInSpotlight = (
  locale: string,
  countryCode: string,
  cart: ProductSpotlightCartInput,
) =>
  countryCode === 'OTHER'
    ? // When the country code is OTHER, we don't want to call the spotlight service
      // And not show the spotlight
      { productsInSpotlight: { suggestedProducts: [], freeProduct: null } }
    : bffRequest<ProductSpotlightData, ProductsInSpotlightVariables>({
        query: productsInSpotlightQuery,
        variables: { locale, countryCode, cart },
      });

/**
 * @private Hook that returns the suggested product and a boolean indicating if we should wait for the product spotlight service
 */
const useProductsInSpotlight = (
  cartPreferences: ReturnType<typeof usecartPreferences>,
  cart: ExtendedShopifyCart,
) => {
  const spotlightRequestTimeout = useRef<ReturnType<typeof setTimeout>>();
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();

  const [suggestedProduct, setSuggestedProduct] = useState<SuggestedProductData | null>(null);
  const [shouldWaitForProductSpotlight, setShouldWaitForProductSpotlight] = useState(false);
  const fetchToServiceId = useRef<string | null>(null);

  useEffect(() => {
    if (cartPreferences.isLoading) {
      return;
    }

    if (!cart) {
      // @TODO: figure out why cart is not available after isLoading
      return;
    }

    setShouldWaitForProductSpotlight(true);
    // Wait for maximum 1 second for the spotlight service to respond
    setTimeout(() => {
      setShouldWaitForProductSpotlight(false);
    }, 1000);

    fetchToServiceId.current = uuidv4();

    clearTimeout(spotlightRequestTimeout.current);
    spotlightRequestTimeout.current = setTimeout(async () => {
      try {
        /**
         * Get data from spotlight service
         * Wrap function with fetchId so we can validate if it was the last call made afterwards
         */
        const spotlightDataWithFetchId = await (async (fetchId: string) => {
          const spotlightData = await getProductsInSpotlight(locale, countryCode, {
            id: btoa(cart.id),
            isSubscription: cartPreferences.isSubscription,
            items: cart.lines.edges.map((edge) => ({
              universalKey: edge.node.merchandise.sku,
              quantity: edge.node.quantity,
              type: edge.node.ecLineItemType,
            })),
            totalPrice: {
              amount: parseFloat(cart.cost.totalAmount.amount),
              currencyCode: cart.cost.totalAmount.currencyCode,
            },
          });
          return {
            spotlightData,
            fetchId,
          };
        })(fetchToServiceId.current);

        // Only react to last call
        const { spotlightData, fetchId } = spotlightDataWithFetchId;
        if (fetchId === fetchToServiceId.current) {
          setShouldWaitForProductSpotlight(false);
          /**
           * Update the suggested product
           * Clear the suggested product if no configuration was returned from the service
           */
          if (spotlightData.productsInSpotlight.suggestedProducts) {
            setSuggestedProduct({
              productVariant:
                spotlightData.productsInSpotlight.suggestedProducts?.[0]?.productVariant,
              label: spotlightData.productsInSpotlight.suggestedProducts?.[0]?.label,
            });
          } else {
            setSuggestedProduct(null);
          }
        }
      } catch (err) {
        Sentry.captureException(err);
        setSuggestedProduct(null);
        setShouldWaitForProductSpotlight(false);
      }
    }, 300);
  }, [cart, cartPreferences.isLoading, cartPreferences.isSubscription, countryCode, locale]);

  return { suggestedProduct, shouldWaitForProductSpotlight };
};

export default useProductsInSpotlight;
