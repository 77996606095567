import { gql } from 'graphql-request';

import { authenticatedBffRequest } from 'api/client/bff-request';

const query = gql`
  query multipassify($url: String!) {
    multipassify(url: $url)
  }
`;

interface QueryVariables {
  url: string;
}

const getAuthenticatedUrl = async (variables: QueryVariables): Promise<{ multipassify: string }> =>
  authenticatedBffRequest({ query, variables });

export default getAuthenticatedUrl;
