import React, { ChangeEvent, FocusEventHandler, ReactElement } from 'react';

import classNames from 'classnames';

import Choice from 'components/ui/Choice';

import styles from './Checkbox.module.css';

export interface Props {
  /** Radio button is selected */
  checked?: boolean;
  /** Radio button is selected by default */
  defaultChecked?: boolean;
  /** Disable input */
  disabled?: boolean;
  /** Display an error message */
  error?: string | boolean;
  /** Additional text to aide in use */
  helpText?: React.ReactNode;
  /** ID for form input */
  id?: string;
  /** Label for the checkbox */
  label: string | ReactElement;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Name for form input */
  name?: string;
  /** Value for form input */
  value?: string;
  /** default value for the input */
  defaultValue?: string;
  /** Additional class names */
  className?: string;
  /** Callback when value is changed */
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  /** Callback when input is focused */
  onFocus?: FocusEventHandler<HTMLInputElement>;
  /** Callback when focus is removed */
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const Checkbox = (
  {
    checked,
    defaultChecked,
    disabled,
    error,
    helpText,
    id,
    label,
    labelHidden,
    name,
    value,
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    className: additionalClassName,
    ...additionalProps
  }: Props,
  ref,
) => {
  const className = classNames(
    styles.checkbox,
    disabled && styles['checkbox--disabled'],
    additionalClassName,
  );

  return (
    <Choice
      disabled={disabled}
      error={error}
      helpText={helpText}
      label={label}
      labelHidden={labelHidden}
      id={id}
    >
      <input
        className={className}
        defaultChecked={defaultChecked}
        disabled={disabled}
        ref={ref}
        id={id}
        name={name}
        type="checkbox"
        value={value}
        defaultValue={defaultValue}
        checked={checked}
        aria-checked={checked}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...additionalProps}
      />
    </Choice>
  );
};

export default React.forwardRef<HTMLInputElement, Props>(Checkbox);
