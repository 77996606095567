import {
  FC,
  createContext,
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
  ReactNode,
} from 'react';

import i18next, { Resource, TFunction, TFunctionDetailedResult, TOptionsBase } from 'i18next';
import { useRouter } from 'next/router';
import reactStringReplace from 'react-string-replace';

import initI18n from 'services/translations/i18n';
import {
  createUnusedTranslationsWindowObject,
  deleteTranslationFromUnusedTranslationsWindowObject,
} from 'utils/check-unused-translations';

interface Props {
  resources: Resource;
}

const Context = createContext<{
  t: TFunction;
}>(null);

export const useTranslations = (
  namespace?: string,
): {
  t: TFunction;
  tReplaceStrong: (key: string, options?: TOptionsBase & Record<string, string>) => ReactNode;
} => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useTranslations must be used within a TranslationsProvider tag');
  }

  const translateWithNamespace = useCallback<TFunction>(
    (key: string, ...args: unknown[]) => {
      deleteTranslationFromUnusedTranslationsWindowObject(namespace, key);
      const translation =
        key?.includes(':') || !namespace?.length
          ? contextState.t(key, ...(args as [options: TOptionsBase & Record<string, string>]))
          : contextState.t(
              `${namespace}:${key}`,
              ...(args as [options: TOptionsBase & Record<string, string>]),
            );

      return translation as unknown as TFunctionDetailedResult;
    },
    [contextState, namespace],
  );

  const translateReplaceStrongReturnReactNode = useCallback(
    (key: string, options?: TOptionsBase & Record<string, string>) => {
      deleteTranslationFromUnusedTranslationsWindowObject(namespace, key);
      const translation =
        key?.includes(':') || !namespace?.length
          ? contextState.t(key, options)
          : contextState.t(`${namespace}:${key}`, options);

      return reactStringReplace(translation.toString(), /<\*(.*?)\*>/g, (match, i) => (
        <strong key={i}>{match}</strong>
      ));
    },
    [contextState, namespace],
  );
  return {
    ...contextState,
    t: translateWithNamespace,
    tReplaceStrong: translateReplaceStrongReturnReactNode,
  };
};

const CIMODE_KEYS = { Control: false, ',': false };
const useCIMode = () => {
  const [cimodeEnabled, setCimodeEnabled] = useState(false);
  const hotkeysPressed = useRef(CIMODE_KEYS);

  useEffect(() => {
    const setHotkeyPressed = (key: string, value: boolean) => {
      hotkeysPressed.current = {
        ...hotkeysPressed.current,
        [key]: value,
      };

      if (hotkeysPressed.current.Control && hotkeysPressed.current[',']) {
        setCimodeEnabled((prev) => !prev);
        // The user has to press CIMODE keys again to enable/disable CIMODE.
        setHotkeyPressed(',', false);
      }

      return hotkeysPressed.current;
    };

    const onKeyUp = (event: KeyboardEvent) => setHotkeyPressed(event.key, false);
    const onKeyDown = (event: KeyboardEvent) => setHotkeyPressed(event.key, true);

    document.body.addEventListener('keydown', onKeyDown);
    document.body.addEventListener('keyup', onKeyUp);

    return () => {
      document.body.removeEventListener('keyup', onKeyUp);
      document.body.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return {
    enabled: cimodeEnabled,
    setEnabled: setCimodeEnabled,
  };
};

const TranslationsProvider: FC<PropsWithChildren<Props>> = ({ children, resources }) => {
  const initialized = useRef(false);
  let { locale } = useRouter();
  const ciMode = useCIMode();

  /**
   * Enable CIMODE for testing purposes in development environment by pressing: Ctrl + ,
   * See https://github.com/i18next/i18next/issues/947#issuecomment-312713662 for more info.
   */
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' && ciMode.enabled) {
    locale = 'cimode';
  }

  /**
   * Initialize i18n if it has not yet been initialized.
   */
  if (!initialized.current) {
    initI18n(locale);
    createUnusedTranslationsWindowObject(locale);
    initialized.current = true;
  }

  /**
   * Add new languages to i18n through resources loaded for the current page.
   */
  if (resources && locale) {
    const resourceLang = resources[locale] ?? {};
    for (let j = 0; j < Object.entries(resourceLang).length; j += 1) {
      const [namespace, resourceBundle] = Object.entries(resourceLang)[j];
      i18next.addResourceBundle(locale, namespace, resourceBundle);
    }
  }

  /**
   * Keep LocaleContext and i18n language in sync.
   */
  if (i18next.language !== locale) {
    i18next.changeLanguage(locale);
  }

  return <Context.Provider value={{ t: i18next.t.bind(i18next) }}>{children}</Context.Provider>;
};

export default TranslationsProvider;
