import { useRef } from 'react';

import { gql } from 'graphql-request';
import { useRouter } from 'next/router';

import { bffRequest } from 'api/client/bff-request';
import productVariantFragment from 'api/client/fragments/productVariant';
import { ProductVariant } from 'api/generated-types';
import { useCountryContext } from 'context/CountryContextProvider';
import { getClientSideCookie } from 'services/session';
import { CookieNames } from 'utils/constants';

import type { ShopifyCart } from '..';

export type BFFQueryInput = {
  productVariants: ProductVariant[];
};

export type BFFQueryVariables = {
  locale: string;
  countryCode: string;
  universalKeys: string[];
};

export const bffQueryToFetchProductVariants = gql`
  ${productVariantFragment}
  query productVariants($locale: String!, $countryCode: String!, $universalKeys: [String!]!) {
    productVariants(locale: $locale, countryCode: $countryCode, universalKeys: $universalKeys) {
      ...productVariantFields
    }
  }
`;

/**
 * @private Hook that extends the Shopify Cart with our own logic
 *
 * We mainly use the Shopify Cart as our Cart logic.
 * However, for marketing purposes we would like to extend the Shopify Cart with additional data from Contentful.
 * Therefor, we extend the Cart with the productVariants from our BFF.
 *
 * We also introduce a type for the line items, which can be either 'regular' or 'suggested' (for spotlight)
 */
const useExtendShopifyCart = () => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();

  /**
   * A map object from universalKey to productVariant to optimise the fetching of product variants.
   * This will allow the user to do many update actions without us always triggering BFF calls.
   */
  const fetchedProductVariantsMap = useRef<Record<string, ProductVariant>>({});

  const extendShopifyCart = async (shopifyCart: ShopifyCart) => {
    /**
     * Create a list of variants we haven't fetched yet
     */
    const productVariantsToFetch = [];
    shopifyCart.lines.edges.forEach((edge) => {
      const universalKey = edge.node.merchandise.sku;
      if (!fetchedProductVariantsMap.current[universalKey]) {
        productVariantsToFetch.push(universalKey);
      }
    });

    /**
     * Fetch the product variants we haven't fetched yet and place them in the fetchedProductVariantsMap
     */
    if (productVariantsToFetch.length > 0) {
      // Fetch product variants from BFF
      const data = await bffRequest<BFFQueryInput, BFFQueryVariables>({
        query: bffQueryToFetchProductVariants,
        variables: {
          locale,
          countryCode,
          universalKeys: productVariantsToFetch,
        },
        ctx: {
          preview: !!getClientSideCookie(CookieNames.IsPreviewSession),
        },
      });

      // Place the fetched product variants in the fetchedProductVariantsMap
      data.productVariants.forEach((productVariant) => {
        fetchedProductVariantsMap.current[productVariant.universalKey] = productVariant;
        // Clear the cache 5 minutes after adding the product variant
        // This is to avoid issues when a user keeps session alive for a long time
        setTimeout(() => {
          delete fetchedProductVariantsMap.current[productVariant.universalKey];
        }, 5 * 60 * 1000);
      });
    }

    /**
     * Extend the cart
     * */
    const extendedCart = {
      ...shopifyCart,
      lines: {
        ...shopifyCart.lines,
        edges: shopifyCart.lines.edges.map((edge) => {
          const ecProductVariant = fetchedProductVariantsMap.current[edge.node.merchandise.sku];
          if (ecProductVariant) {
            return {
              ...edge,
              node: {
                ...edge.node,
                // Extend node with our own fields on the line item node:
                ecProductVariant,
                ecLineItemType: edge.node.attributes.some(
                  (attr) => attr.key === '_suggested' && attr.value === 'true',
                )
                  ? ('suggested' as const)
                  : ('regular' as const),
              },
            };
          }
          // We require productVariant to be available for our line items
          throw Error('ProductVariant not found for line item');
        }),
      },
    };

    return extendedCart;
  };

  return extendShopifyCart;
};

export default useExtendShopifyCart;
