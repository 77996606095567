import React, { useContext, useState, createContext, ReactElement } from 'react';

import AlertModal, { Props as AlertModalProps } from 'components/ui/notifications/AlertModal';

interface AlertModalContext {
  isAlertModalOpen: boolean;
  alertModalConfig: AlertModalProps['config'];
  openAlertModal: () => void;
  closeAlertModal: () => void;
  configureAlertModal: (configuration: AlertModalProps['config']) => void;
}

const Context = createContext<AlertModalContext>(null);

export const useAlertModal: () => AlertModalContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useAlertModal must be used within a AlertModalProvider tag');
  }
  return contextState;
};

const AlertModalProvider: React.FC<{ children }> = ({ children }) => {
  // state
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertModalConfig, setAlertModalConfig] = useState<AlertModalProps['config']>({});

  // methods
  const openAlertModal = () => setIsAlertModalOpen(true);

  const closeAlertModal = () => setIsAlertModalOpen(false);

  const configureAlertModal = (
    settings: React.SetStateAction<{
      title?: string;
      message?: string | ReactElement;
      variant?: 'error' | 'success';
      btnText?: string;
      onClick?: () => void;
    }>,
  ) => setAlertModalConfig(settings);

  return (
    <Context.Provider
      value={{
        isAlertModalOpen,
        openAlertModal,
        closeAlertModal,
        alertModalConfig,
        configureAlertModal,
      }}
    >
      <AlertModal isOpen={isAlertModalOpen} close={closeAlertModal} config={alertModalConfig} />
      {children}
    </Context.Provider>
  );
};

export default AlertModalProvider;
