import * as Sentry from '@sentry/nextjs';
import { Auth, withSSRContext } from 'aws-amplify';
import fetchRetry from 'fetch-retry';
import { GraphQLClient as Client } from 'graphql-request';
import { Variables } from 'graphql-request/build/esm/types';
import originalFetch from 'isomorphic-fetch';
import { GetServerSidePropsContext, GetStaticPropsContext } from 'next';
import { v4 as uuidv4 } from 'uuid';

import { getClientSideCookie } from 'services/session';
import { CookieNames } from 'utils/constants';
import logger from 'utils/logger';

type RequestParams<V> = {
  query: string;
  variables?: V;
  requestHeaders?: HeadersInit;
  // Optional argument to use for SSR requests.
  ctx?: GetServerSidePropsContext | GetStaticPropsContext;
};

/**
 * Normal request to the BFF
 * = Without an authentication header
 */
const bffRequest = <T, V = Variables | undefined>({
  query,
  variables,
  requestHeaders,
  ctx,
}: RequestParams<V>): Promise<T> => {
  const startTime = Date.now();
  const requestId = uuidv4();
  const sessionId = (ctx as GetServerSidePropsContext)?.req
    ? (ctx as GetServerSidePropsContext).req.cookies[CookieNames.SessionId] // Sends session id from inside getServerSideProps by passing ctx
    : getClientSideCookie(CookieNames.SessionId); // Sends session id from front-end requests,

  return new Client(`${process.env.NEXT_PUBLIC_BFF_BASE_URL}/api/graphql`, {
    headers: { 'Content-Type': 'application/json' },
    credentials: 'same-origin',
    // Retries fetch requests on network related issues.
    fetch: fetchRetry(originalFetch, {
      retries: 3,
      retryDelay: 1000,
      retryOn: (attempt, error, response) => {
        if (attempt > 3) return false;
        // retry on any network error, or 4xx or 5xx status codes
        if (error !== null && response?.status >= 400) {
          // exclude failed to fetch errors
          if (
            !(
              error.toString() === 'Failed to fetch' ||
              error.toString() === 'TypeError: Failed to fetch' ||
              error.toString() === 'TypeError: NetworkError when attempting to fetch resource.' ||
              error.toString() === 'TypeError: cancelled' ||
              error.toString() === 'TypeError: Load failed'
            )
          ) {
            logger.error('GraphQLClient@request - caught error', {
              graphqlQuery: query,
              variables,
              requestId,
              sessionId,
              errorMessage:
                response?.status >= 400
                  ? `Network issue ${response?.status} - ${response?.statusText}`
                  : error.toString(),
              retryAttempt: attempt,
            });
          }
          return true;
        }
        return false;
      },
    }),
  })
    .request<T>(query, variables ?? {}, {
      ...requestHeaders,
      /**
       * Add front-end context headers to the requests
       * This allows us to track the user session requests (front to back) in our logs.
       */
      'ec-store-request-id': requestId, // Unique ID for each request
      'ec-store-session-id': sessionId, // Unique ID representing the current session
      'ec-store-is-preview': (!!ctx?.preview)?.toString(), // Indicate if we want preview data
    })
    .catch((err) => {
      const exception = err.error || err.message || err.originalError || err;
      Sentry.withScope((scope) => {
        scope.setExtra('query', query);
        scope.setExtra('variables', variables);
        scope.setExtra('requestId', requestId);
        scope.setExtra('sessionId', sessionId);
        Sentry.captureException(exception);
      });
      // exclude failed to fetch errors
      if (
        !(
          exception.toString() === 'Failed to fetch' ||
          exception.toString() === 'TypeError: Failed to fetch' ||
          exception.toString() === 'TypeError: NetworkError when attempting to fetch resource.' ||
          exception.toString() === 'TypeError: cancelled' ||
          exception.toString() === 'TypeError: Load failed'
        )
      ) {
        logger.error('GraphQLClient@request - caught error', {
          graphqlQuery: query,
          variables,
          requestId,
          sessionId,
          errorMessage: exception,
        });
        throw err;
      }
      throw err;
    })
    .finally(() => {
      logger.info('GraphQLClient@request - resolved request', {
        graphqlQuery: query,
        variables,
        requestId,
        sessionId,
        latency: Date.now() - startTime,
      });
    });
};

type AuthorizationHeaders = {
  authorization?: string;
};

/**
 * Returns authentication headers for performing GraphQL requests.
 * If the user is not authenticated, returns an empty object.
 *
 * Do not try to cache these headers for future requests,
 * as these methods are also used server side with shared memory
 * Related incident:
 * https://www.notion.so/digitalsquad/2022-03-14-User-details-are-incorrect-random-other-user-details-are-shown-bd8c4f4ec6b74c8e8217d97111b05ef5
 * @param ctx Optional argument to use for SSR requests.
 */
const getAuthenticationHeaders = async (
  ctx?: GetServerSidePropsContext | GetStaticPropsContext | undefined,
): Promise<AuthorizationHeaders> => {
  let currentSession;
  if ((ctx as GetServerSidePropsContext)?.req) {
    const SSR = withSSRContext({ req: (ctx as GetServerSidePropsContext).req });
    currentSession = await SSR.Auth.currentSession().catch(() => null);
  } else if (!ctx) {
    // if not called from static props or server side props, try to fetch session
    currentSession = await Auth.currentSession().catch(() => null);
  }

  if (currentSession) {
    const jwt = currentSession.getAccessToken().getJwtToken();
    return { authorization: `Bearer ${jwt}` };
  }

  return {};
};

const authenticatedBffRequest = async <T = unknown, V = Variables | undefined>({
  ctx,
  query,
  variables,
  requestHeaders,
}: RequestParams<V>): Promise<T> => {
  const authHeaders = await getAuthenticationHeaders(ctx);
  return bffRequest<T, V>({
    query,
    variables,
    requestHeaders: { ...requestHeaders, ...authHeaders },
    ctx,
  });
};

export { bffRequest, authenticatedBffRequest };
