import { useState } from 'react';

/**
 * @private Hook that returns states related to cart
 */
const useCartStates = () => {
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [isCartUpdating, setIsCartUpdating] = useState(false);
  const [isCartError, setIsCartError] = useState(false);
  const [isRedirectingToCheckout, setIsRedirectingToCheckout] = useState(false);

  return {
    isCartLoading,
    setIsCartLoading,
    isCartUpdating,
    setIsCartUpdating,
    isCartError,
    setIsCartError,
    isRedirectingToCheckout,
    setIsRedirectingToCheckout,
  };
};

export default useCartStates;
