/* eslint-disable @typescript-eslint/no-explicit-any */
import { CountryWithProvinces } from 'api/generated-types';

import { FALLBACK_LOCALE } from './constants';
import mapCountryToLanguages from './mapCountryToLanguages';

// Helper functions to get regions and languages in a locale
const formatRegionsInLocale = (locale = FALLBACK_LOCALE): any => {
  // The website might crash for older browsers / When Intl.DisplayNames errors occur, so try...catch...
  try {
    return new (Intl as any).DisplayNames([locale], { type: 'region' });
  } catch {
    return undefined;
  }
};

const formatLanguagesInLocale = (locale = FALLBACK_LOCALE): any => {
  // The website might crash for older browsers / When Intl.DisplayNames errors occur, so try...catch...
  try {
    return new (Intl as any).DisplayNames([locale], {
      type: 'language',
    });
  } catch {
    return undefined;
  }
};

// Helper functions to return a region or language in a locale
const regionInLocale = (region: string, locale?: string): string => {
  const regionsInLocale = formatRegionsInLocale(locale);
  return regionsInLocale
    ? `${regionsInLocale.of(region.toUpperCase())}`
    : // fallback if Intl.Displaynames errors
      region.toUpperCase();
};

const regionInLocaleWithRegionInCountryLocale = (region: string, locale?: string): string => {
  let translatedCountries = mapCountryToLanguages(region.toLowerCase()).map((regionLocale) =>
    formatRegionsInLocale(regionLocale).of(region),
  );

  // Remove duplicates out of array and make it a string
  translatedCountries = [...new Set(translatedCountries)];
  const countryTranslated = translatedCountries.join(', ');

  return formatRegionsInLocale(locale).of(region)
    ? `${formatRegionsInLocale(locale).of(region)} (${countryTranslated})`
    : // fallback if Intl.Displaynames errors
      null;
};

// Transform shipping countries to user selected language
const regionsInLocaleWithRegionInCountryLocale = (
  regions: Array<CountryWithProvinces>,
  locale?: string,
): Array<CountryWithProvinces> => {
  const translatedShippingCountries = regions.map((shippingCountry) => {
    // Make variable so we don't change function parameter
    const country = { ...shippingCountry };
    let translatedCountries = mapCountryToLanguages(country.code.toLowerCase()).map(
      (regionLocale) =>
        formatRegionsInLocale(regionLocale)
          ? formatRegionsInLocale(regionLocale).of(country.code)
          : // fallback if Intl.Displaynames errors
            null,
    );

    // Remove duplicates out of array and make it a string
    translatedCountries = [...new Set(translatedCountries)];
    const countryTranslated = translatedCountries.join(', ');

    country.name = `${
      formatRegionsInLocale(locale)
        ? formatRegionsInLocale(locale).of(shippingCountry.code)
        : // fallback if Intl.Displaynames errors
          shippingCountry.code
    } ${
      countryTranslated
        ? `(${countryTranslated})`
        : // fallback if Intl.Displaynames errors
          ''
    }`;

    return country;
  });

  // Sort the country objects alphabetically in array
  translatedShippingCountries.sort((a, b) => {
    // Cast to uppercase to ignore upper and lowercase
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA < nameB) {
      return 1;
    }

    // Names are equal
    return 0;
  });

  return translatedShippingCountries;
};

const languageInLocale = (language: string, locale?: string): string => {
  const languagesInLocale = formatLanguagesInLocale(locale);
  return languagesInLocale
    ? languagesInLocale.of(language)
    : // fallback if Intl.Displaynames errors
      language.toUpperCase();
};

const localesWithoutDefault = (locales: string[]): string[] =>
  locales.filter((locale) => locale !== 'default');

export {
  regionInLocale,
  regionInLocaleWithRegionInCountryLocale,
  regionsInLocaleWithRegionInCountryLocale,
  languageInLocale,
  localesWithoutDefault,
};
