import { useEffect, useCallback } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { getClientSideCookie } from 'services/session';
import { removeClientSideCookie } from 'services/session/cookies';
import { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

import type useCartStates from './useCartStates';
import useExtendShopifyCart from './useExtendShopifyCart';
import type { ExtendedShopifyCart } from '..';

/**
 * @private Hook that is responsible for updating the discount code of the cart
 * Our middleware sets a discount code cookie when a discount code is present in the URL
 * We read out the cookie here and apply it to the cart
 */
const useUpdateDiscountCodeFromUrl = async (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
  isBuyerIdentityUpdated: boolean,
) => {
  const { countryCode } = useCountryContext();
  const queryClient = useQueryClient();
  const { locale } = useRouter();

  /**
   * Update discount code if present
   * If fails, log & ignore (non-critical)
   */
  const updateDiscountCode = useCallback(async () => {
    if (!isBuyerIdentityUpdated) {
      // Wait for buyer identity to be updated first
      return;
    }

    if (cartStates.isCartLoading || cartStates.isCartUpdating) {
      return;
    }

    const discountCodeCookie = getClientSideCookie(CookieNames.Discount);
    // Stop when cookie not present
    if (!discountCodeCookie) {
      return;
    }

    // If already applied, delete cookie
    if (shopifyCart.discountCodes?.some((dc) => dc.code === discountCodeCookie)) {
      removeClientSideCookie(CookieNames.Discount);
      return;
    }

    cartStates.setIsCartUpdating(true);
    await storefrontSDK
      .cartDiscountCodesUpdate({
        cartId: shopifyCart.id,
        discountCodes: [discountCodeCookie],
      })
      .then((res) => {
        if (res.cartDiscountCodesUpdate?.userErrors.length > 0) {
          const error = new Error(
            `Failed to update discount code in cart: ${JSON.stringify(
              res.cartDiscountCodesUpdate.userErrors,
            )}`,
          );
          Sentry.captureException(error, {
            extra: {
              userErrors: res.cartDiscountCodesUpdate?.userErrors,
            },
          });
        }

        // Set query data based on result
        extendShopifyCart(res.cartDiscountCodesUpdate.cart).then((extendedCart) => {
          queryClient.setQueryData(QueryKeys[Keys.Cart](countryCode, locale), () => extendedCart);
          return extendedCart;
        });
      })
      .catch((e) => {
        // Request error, such as unavailable Shopify API
        // Log to Sentry but do nothing
        Sentry.captureException(e);
      })
      .finally(() => {
        cartStates.setIsCartUpdating(false);
        // remove cookie after updating so logic doesn't repeat itself
        removeClientSideCookie(CookieNames.Discount);
      });
  }, [
    shopifyCart,
    countryCode,
    extendShopifyCart,
    locale,
    queryClient,
    cartStates,
    isBuyerIdentityUpdated,
  ]);

  // Aply discount code from URL
  useEffect(() => {
    updateDiscountCode();
  }, [updateDiscountCode]);
};

export default useUpdateDiscountCodeFromUrl;
