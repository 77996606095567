/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/nextjs';
import fetchRetry from 'fetch-retry';
import { GraphQLClient } from 'graphql-request';

import { getSdk } from './generated-sdk';

export const storefrontSDK = getSdk(
  new GraphQLClient(`https://${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN}/api/2024-07/graphql.json`, {
    headers: {
      'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    },
    fetch: fetchRetry(fetch, {
      retries: 3,
      retryDelay: 1000,
      retryOn: (attempt, error, response) => {
        if (attempt > 3) {
          Sentry.captureException(error);
          return false;
        }
        // retry on any network error, or 4xx or 5xx status codes
        if (error !== null || (response?.status ?? 200) >= 400) {
          return true;
        }
        return false;
      },
    }),
  }),
);
