import Link from 'next/link';

import { Image } from 'api/generated-types';

import ECImage from '../ECImage';
import Icon from '../Icon';

export interface BlogAnimalTypeCardProps {
  title: string;
  cta: string;
  href: string;
  img: Image;
}

const BlogAnimalTypeCard = ({ title, cta, href, img }: BlogAnimalTypeCardProps): JSX.Element => (
  <div
    className="bg-cyan-400 rounded pt-8 px-4 pb-0 md:p-4 md:pb-0 lg:p-8 lg:pb-0
    flex items-end md:items-center justify-between md:flex-col"
  >
    <div className="mb-4 md:text-center md:px-4">
      <div>
        <Link href={href} className="heading-2 text-white">
          {title}
        </Link>
      </div>

      <Link
        href={href}
        className="inline-flex items-center mt-4 transition-colors
      text-blue-400 border-b-2 border-blue-400 hover:text-blue-500 hover:border-blue-500"
      >
        <span>{cta}</span>
        <Icon name="arrow-right" className="w-4 ml-4 inline-block" />
      </Link>
    </div>

    <div className="w-24 md:w-9/12">
      <figure className="relative aspect-w-1 aspect-h-1">
        <ECImage img={img} className="absolute w-full h-full object-contain object-bottom" />
      </figure>
    </div>
  </div>
);

export default BlogAnimalTypeCard;
