import {
  ChangeEvent,
  createElement,
  FocusEventHandler,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import Labelled from 'components/ui/Labelled';

import styles from './TextField.module.css';

type InputType = 'text' | 'email' | 'number' | 'password' | 'tel';

export interface Props {
  /** Disable input */
  disabled?: boolean;
  /** Determine type of input */
  type?: InputType;
  /** Label for the input */
  label: string;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Max number of characters for this textfield */
  maxLength?: number;
  /** Minimum value for this textfield */
  min?: string | number;
  /** Maximum value for this textfield */
  max?: string | number;
  /** Text displayed on the right side of the text field. Cannot be combined with maxLength */
  rightText?: string;
  /** Allow for multiple lines of input */
  multiline?: boolean;
  /** Initial value for the input */
  value?: string;
  /** default value for the input */
  defaultValue?: string;
  /** Amount of rows when using multiline */
  rows?: number;
  /** Hint text to display */
  placeholder?: string;
  /** Name of the input */
  name?: string;
  /** ID for the input */
  id: string;
  /** Additional hint text to display */
  helpText?: string;
  /** Disable editing of the input */
  readOnly?: boolean;
  /** Error to display */
  error?: string | boolean;
  /** Additional class names */
  className?: string;
  /** Turn autocomplete on or off */
  autoComplete?: string;
  /** Loading state */
  isLoading?: boolean;
  /** Callback when value is changed */
  onChange?(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  /** Callback when input is focused */
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /** Callback when focus is removed */
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /** Callback when key is down */
  onKeyDown?(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  /** Ref */
  ref?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
}

const TextField = (
  {
    disabled,
    isLoading,
    type = 'text',
    label,
    labelHidden,
    maxLength,
    rightText,
    multiline,
    value,
    defaultValue,
    placeholder,
    rows,
    name,
    id,
    helpText,
    readOnly,
    error,
    onChange,
    onFocus,
    onBlur,
    className: additionalClassname,
    ...additionalProps
  }: Props,
  ref,
): JSX.Element => {
  const [inputLength, setInputLength] = useState(value?.length || defaultValue?.length || 0);
  const myRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const spanText = rightText ?? (maxLength ? `${inputLength}/${maxLength}` : undefined);
  const className = classNames(
    styles['text-field'],
    (disabled || isLoading) && styles['text-field--disabled'],
    multiline && styles['text-field--multiline'],
    readOnly && styles['text-field--readonly'],
    error && styles['text-field--error'],
    !!spanText && styles['text-field--has-span'],
    isLoading && 'animate-pulse',
    additionalClassname,
  );

  const input = createElement(multiline ? 'textarea' : 'input', {
    className,
    disabled,
    isloading: isLoading?.toString() ?? 'false',
    type,
    value,
    defaultValue,
    rows,
    placeholder: !isLoading ? placeholder : '',
    name,
    id,
    helptext: helpText,
    readOnly,
    onChange,
    onFocus,
    onBlur,
    ref: (node) => {
      myRef.current = node as HTMLInputElement | HTMLTextAreaElement;
      if (typeof ref === 'function') {
        ref(node as HTMLInputElement | HTMLTextAreaElement);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        (ref as MutableRefObject<HTMLInputElement | HTMLTextAreaElement>).current = node as
          | HTMLInputElement
          | HTMLTextAreaElement;
      }
    },
    ...additionalProps,
  });

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (myRef.current.value.length === maxLength) {
        if (
          !(
            e.key === 'Backspace' ||
            e.key === 'Enter' ||
            e.key === 'ArrowLeft' ||
            e.key === 'ArrowRight' ||
            e.key === 'ArrowUp' ||
            e.key === 'ArrowDown'
          )
        ) {
          // backspace/enter/del
          e.preventDefault();
        }
      }
    };
    const onInput = () => {
      if (myRef.current.value.length >= maxLength) {
        myRef.current.value = myRef.current.value.slice(0, maxLength);
      }
      setInputLength(myRef.current.value.length);
    };

    if (maxLength) {
      if (myRef.current) {
        onInput(); // Trigger update for any change to myRef's value.
        myRef.current.addEventListener('keydown', onKeyDown);
        myRef.current.addEventListener('input', onInput);
      }
    }
  }, [maxLength, myRef, myRef?.current?.value]);

  return (
    <Labelled id={id} label={label} labelHidden={labelHidden} error={error} helpText={helpText}>
      <div className={styles['text-field__input-wrap']}>
        {input}
        {!!spanText && <span className={styles['text-field__span']}>{spanText}</span>}
      </div>
    </Labelled>
  );
};

export default forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(TextField);
