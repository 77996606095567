import React from 'react';

import Modal from 'components/ui/Modal';
import { useModal } from 'context/ModalProvider';

const ModalWrapper: React.FC = () => {
  const { isModalOpen, closeModal, modalConfig } = useModal();
  return (
    <Modal isOpen={isModalOpen} close={closeModal} config={modalConfig}>
      {modalConfig.children}
    </Modal>
  );
};
export default ModalWrapper;
