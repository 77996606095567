import { useCallback } from 'react';

import { useTracking } from 'context/TrackingProvider';

import type { ExtendedShopifyCart } from '..';

/**
 * @private Hook that returns a function to track cart updates
 */
const useCartTracking = () => {
  const {
    events: { trackProductAddedRemoved },
  } = useTracking();

  const trackCartUpdated = useCallback(
    (
      cartId: string,
      cartAction: 'add' | 'update' | 'remove',
      currentCartItem: ExtendedShopifyCart['lines']['edges'][number]['node'],
      quantity: number,
      type?: 'regular' | 'suggested',
      previousQuantity?: number, // Needed for tracking when updating cart item
    ) => {
      switch (cartAction) {
        case 'add':
          trackProductAddedRemoved(
            'add',
            'cart',
            cartId,
            currentCartItem.ecProductVariant,
            quantity,
            type,
          );
          break;

        case 'update':
          if (quantity > previousQuantity) {
            trackProductAddedRemoved(
              'add',
              'cart',
              cartId,
              currentCartItem.ecProductVariant,
              quantity - previousQuantity,
              currentCartItem.ecLineItemType,
            );
          } else if (quantity < previousQuantity) {
            trackProductAddedRemoved(
              'remove',
              'cart',
              cartId,
              currentCartItem.ecProductVariant,
              currentCartItem.quantity - quantity,
              type,
            );
          }
          break;

        case 'remove':
          trackProductAddedRemoved(
            'remove',
            'cart',
            cartId,
            currentCartItem.ecProductVariant,
            currentCartItem.quantity,
            type,
          );
          break;

        default:
          break;
      }
    },
    [trackProductAddedRemoved],
  );

  return { trackCartUpdated };
};

export default useCartTracking;
