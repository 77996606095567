import { gql } from 'graphql-request';

import productVariantFragment from 'src/api/client/fragments/productVariant';

const subscriptionFragment = gql`
  ${productVariantFragment}
  fragment subscriptionFields on Subscription {
    id
    currencyCode
    billingAddress {
      address1
      address2
      city
      country {
        name
        code
      }
      compact
      firstName
      lastName
      postalCode
      phone
      province {
        name
        code
      }
      company
    }
    discountTotal {
      amount
      currencyCode
      formattedPrice
    }
    frequency
    preferredDeliveryDay
    lineItems {
      id
      price {
        amount
        currencyCode
      }
      quantity
      productVariant {
        ...productVariantFields
      }
    }
    nextOrder {
      needsPayment
      paymentDate
      paymentStatus
      paymentTotal {
        amount
        currencyCode
      }
      shipmentDate
      deliveryDate
      status
      remainingEditDays
    }
    previousOrder {
      status
      total {
        amount
        currencyCode
      }
      index
      createdAt
      deliveryDate
    }
    shippingAddress {
      address1
      address2
      city
      country {
        name
        code
      }
      compact
      firstName
      lastName
      postalCode
      phone
      province {
        name
        code
      }
      company
    }
    shippingTotal {
      amount
      currencyCode
      formattedPrice
    }
    status
    subTotal {
      amount
      currencyCode
      formattedPrice
    }
    taxTotal {
      amount
      currencyCode
      formattedPrice
    }
    total {
      amount
      currencyCode
      formattedPrice
    }
    shippingTitle
    shippingDefaultRate {
      amount
      currencyCode
      formattedPrice
    }
    shippingFreeFrom {
      amount
      currencyCode
      formattedPrice
    }
    discountPercentage
    discountTitle
  }
`;

export default subscriptionFragment;
