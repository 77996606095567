import React from 'react';

import { useRouter } from 'next/router';
import { DefaultSeo, NextSeo } from 'next-seo';

import { Image } from 'api/generated-types';
import baseUrl from 'utils/baseUrl';

export interface PageSeoProps {
  title?: string;
  description?: string;
  image?: Image;
  canonical?: string;
}

const PageSeo: React.FC<PageSeoProps> = ({ title, description, image, canonical }) => {
  const router = useRouter();

  return (
    <>
      <DefaultSeo
        title="Naturally Tasty Cat & Dog Food"
        titleTemplate="%s | Edgard & Cooper"
        description=""
        openGraph={{
          type: 'website',
          url: `${baseUrl}/${router.locale}${router.asPath}`,
          site_name: 'Edgard & Cooper',
          images: [
            {
              url: `${baseUrl}/images/share-image.png`,
              alt: 'Edgard & Cooper',
              width: 1200,
              height: 630,
            },
          ],
        }}
      />
      <NextSeo
        title={title}
        description={description}
        key="pageseo"
        canonical={
          canonical ??
          `${baseUrl}/${router.locale}${router.pathname}/`
            .replace(/\/home/, '')
            .replace(/\[countryCodeWithPrefix\]\//, '')
            .toLowerCase()
        }
        openGraph={{
          images: image?.src
            ? [
                {
                  url: image?.src,
                  alt: image?.alt,
                },
              ]
            : [],
        }}
      />
    </>
  );
};

export default PageSeo;
