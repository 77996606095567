import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

import multipassify from 'api/client/queries/multipassify';
import { useAwin } from 'context/AwinProvider';
import { useCountryContext } from 'context/CountryContextProvider';
import { getClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CountryCode } from 'services/shopify/storefront/generated-sdk';
import {
  CookieNames,
  GA4_MEASUREMENT_ID_GLOBAL_PRD,
  GA4_MEASUREMENT_ID_GLOBAL_TST,
} from 'utils/constants';

import type { ExtendedShopifyCart } from '../..';
import type useCartStates from '../useCartStates';

/**
 * @private Hook that returns a function to redirect to checkout
 */
const useRedirectToCheckout = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
) => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();
  const { awc, source } = useAwin();

  const redirectToCheckout = async (options?: { onError?: () => void }) => {
    // Create checkout
    cartStates.setIsRedirectingToCheckout(true);

    const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

    /**
     * Configure checkout attributes
     */
    const attributes = [
      { key: 'language', value: locale },
      { key: 'country-of-cart', value: countryCode },
      { key: 'cart_id', value: shopifyCart.id },
    ];

    /** Add awin attributes if available */
    if (awc) {
      attributes.push({ key: '__awc', value: awc });
    }
    if (source) {
      attributes.push({ key: '__awin_channel', value: source });
    }

    // Place tracking attributes retrieval in try catch just to be sure it won't block checkout flow
    try {
      if (global.analytics?.user?.().anonymousId?.()) {
        attributes.push({ key: 'anonymous_id', value: global.analytics?.user().anonymousId() });
      }

      /**
       * Gtag tracking
       * https://developers.google.com/tag-platform/gtagjs/reference#get
       */
      const measurementId = isProduction
        ? GA4_MEASUREMENT_ID_GLOBAL_PRD
        : GA4_MEASUREMENT_ID_GLOBAL_TST;

      const getGtagClientId = new Promise<string>((resolve) => {
        global?.gtag('get', measurementId, 'client_id', (client_id: string) => {
          resolve(client_id);
        });
      });
      const getGtagSessionId = new Promise<string>((resolve) => {
        global?.gtag('get', measurementId, 'session_id', (session_id: string) => {
          resolve(session_id);
        });
      });

      const getIdPromisesAndAddToAttributes = Promise.all([getGtagClientId, getGtagSessionId]).then(
        ([client_id, session_id]) => {
          attributes.push({
            key: 'ga_client_id',
            value: client_id,
          });
          attributes.push({
            key: 'ga_session_id',
            value: session_id,
          });
        },
      );

      // Wait max 1 second for gtag client and session id
      await Promise.race([
        getIdPromisesAndAddToAttributes,
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]);

      // Tiktok tracking
      if (getClientSideCookie(CookieNames.TikTokClickId)) {
        attributes.push({
          key: 'ttclid',
          value: getClientSideCookie(CookieNames.TikTokClickId),
        });
      }
    } catch (err) {
      // An unexpected error occured trying to get tracking data, do nothing besides logging
      Sentry.captureException(err);
    }

    /**
     * Update cart attributes
     * If fails, throw error (important that this call succeeds!)
     */
    await storefrontSDK
      .cartAttributesUpdate({
        attributes,
        cartId: shopifyCart.id,
      })
      .then((res) => {
        if (res.cartAttributesUpdate?.userErrors.length > 0) {
          const error = new Error('Failed to update cart attributes');
          Sentry.captureException(error, {
            extra: {
              userErrors: res.cartAttributesUpdate?.userErrors,
            },
          });
          throw new Error('Failed to update cart attributes');
        }
      });

    /**
     * Set buyer identity in cart
     * If fails, log & ignore (non-critical)
     */
    if (countryCode.toUpperCase() !== 'OTHER') {
      await storefrontSDK
        .cartBuyerIdentityUpdate({
          cartId: shopifyCart.id,
          buyerIdentity: {
            countryCode: countryCode as CountryCode,
          },
        })
        .then((res) => {
          if (res.cartBuyerIdentityUpdate?.userErrors.length > 0) {
            const err = new Error('Failed to update buyer identity in cart');
            Sentry.captureException(err, {
              extra: {
                userErrors: res.cartBuyerIdentityUpdate?.userErrors,
              },
            });
          }
        });
    }

    /**
     * Multipassify (= Shopify term) the url to achieve a logged in state in checkout if user is logged in
     */
    await multipassify({
      url: `${shopifyCart.checkoutUrl}&locale=${locale}`,
    })
      .then((data) => {
        window.location.assign(data.multipassify);
      })
      .catch((err) => {
        Sentry.captureException(err);
        cartStates.setIsRedirectingToCheckout(false);
        if (options.onError) options.onError();
      });
  };

  return redirectToCheckout;
};

export default useRedirectToCheckout;
