import React from 'react';

import classNames from 'classnames';

import Icon from 'components/ui/Icon';

import styles from './Spinner.module.css';

type Theme = 'light' | 'dark' | 'gray-300';
type Size = 'small' | 'large';

export interface SpinnerProps {
  /** Color of spinner */
  theme?: Theme;
  /** Size of spinner */
  size?: Size;
  /** Additional class names */
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({
  theme = 'dark',
  size = 'large',
  className: additionalClassName,
}: SpinnerProps) => {
  const className = classNames(
    styles.spinner,
    theme && styles[`spinner--${theme}`],
    additionalClassName,
  );

  const spinnerSize = size === 'small' ? 'w-5' : 'w-12';

  const spinnerSVGMarkup = <Icon name="loader" className={spinnerSize} />;

  return <span className={className}>{spinnerSVGMarkup}</span>;
};
export default Spinner;
