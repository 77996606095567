import { useEffect, useRef } from 'react';

import LocaleSelector from 'components/parts/LocaleSelector';
import { useModal } from 'context/ModalProvider';
import { getClientSideCookie } from 'services/session';
import { useTranslations } from 'services/translations/TranslationsProvider';

import { CookieNames } from '../../../utils/constants';

const RequiredLocaleSelector = ({ translatedSlugs }): JSX.Element => {
  const { t } = useTranslations();
  const needsToSetCountryRef = useRef<boolean>();

  if (needsToSetCountryRef.current === undefined) {
    needsToSetCountryRef.current =
      getClientSideCookie(CookieNames.NeedsToSetCountryCode) === 'true';
  }

  const { openModal, setModalConfig } = useModal();

  // When the url contains "#shopping", this means it's a Google/Bing Shopping Bot visiting the page.
  // Do not show the popup to select country/language in that event.
  useEffect(() => {
    if (needsToSetCountryRef.current && window.location.hash !== '#shopping') {
      setModalConfig({
        size: 'sm',
        title: t('localeModal:title'),
        children: <LocaleSelector required translatedSlugs={translatedSlugs} />,
        required: true,
      });
      openModal();
    }
  }, [openModal, setModalConfig, t, translatedSlugs]);

  return null;
};

export default RequiredLocaleSelector;
