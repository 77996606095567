import React, { Fragment, useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import Link from 'next/link';

import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';

import styles from './Toast.module.css';

export interface ToastConfig {
  title: string;
  title2?: string;
  description: string;
  variant: 'success' | 'error';
  button?: ButtonConfig;
  toastTrigger?: 'cart' | 'other'; // Where this toast will be triggered. This is used to determine the required animation.
}

export interface ButtonConfig {
  btnLink: string;
  btnText: string;
}
export interface ToastProps {
  id: number;
  onRemove?: (id) => void;
  config: ToastConfig;
}

const Toast = ({
  id,
  onRemove = () => {},
  config: { title, description, variant, button, title2, toastTrigger },
}: ToastProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(null);
  const triggeredInCart = toastTrigger === 'cart';

  // Trigger animation on mount
  useEffect(() => {
    setShow(true);
  }, []);

  // Trigger onRemove after animation
  useEffect(() => {
    let timer;

    if (show === false) {
      // Delay equal to exit animation duration
      timer = setTimeout(() => {
        onRemove(id);
      }, 200);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [show, id, onRemove]);

  // Automatically delete toast after 3 seconds.
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, setShow]);

  const themeConfig = {
    success: {
      iconName: 'checkmark',
      color: 'text-white',
    },
    error: {
      iconName: 'exclamation-circle',
      color: 'text-red-400',
    },
  };
  const commonTransitionProps = {
    show: !!show,
    as: Fragment,
    enter: 'transform ease-out duration-300 transition',
    leave: 'transform transition ease-in duration-200',
    enterTo: 'opacity-100 sm:translate-x-0',
    leaveFrom: 'opacity-100  sm:translate-x-0',
  };

  const animationProps = triggeredInCart
    ? {
        enterFrom: '-translate-y-6 opacity-0 -translate-x-0 opacity-0 sm:-translate-y-12',
        leaveTo: '-translate-y-6 opacity-0 -translate-x-0 sm:-translate-y-6',
      }
    : {
        enterFrom: 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-32',
        leaveTo: 'translate-y-2 sm:translate-y-0 sm:translate-x-4',
      };

  return (
    <Transition {...commonTransitionProps} {...animationProps}>
      <div className={styles['inner-container-toast']}>
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <Icon
                name={themeConfig[variant].iconName}
                className={classNames(
                  'h-6 w-6 bg-green-550 p-1 mr-3 rounded-full ',
                  themeConfig[variant].color,
                )}
              />
            </div>
            <div className="w-0 flex-1">
              <p className="text-lg font-bold text-gray-500">
                {title} {title2 && `(${title2})`}
              </p>
              <p className="mt-1 text-sm text-gray-300 font-bold">{description}</p>
            </div>
            <div className="ml-4 shrink-0 flex">
              <button
                type="button"
                className={styles['btn-toast']}
                onClick={() => {
                  setShow(false);
                }}
              >
                <span className="sr-only">Close</span>

                <Icon name="cross" className="h-6 w-6" />
              </button>
            </div>
          </div>
          {button && (
            <Link href={button.btnLink} passHref legacyBehavior>
              <Button legacy color="green-550" size="medium" className="w-full mt-4">
                <span data-testid="toastBtn">{button.btnText}</span>
              </Button>
            </Link>
          )}
        </div>
      </div>
    </Transition>
  );
};

export default Toast;
