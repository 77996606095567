import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import { getIntercomUserHash } from 'pages/api/intercom/hash';
import { useSessionContext } from 'services/session';

import { useTracking } from './TrackingProvider';

interface IntercomContext {
  isLoaded: boolean;
  showIntercom: () => void;
  hideIntercom: () => void;
  visualShowIntercom: () => void;
  visualHideIntercom: () => void;
  shutdownIntercom: () => void;
}

const Context = createContext<IntercomContext>(null);

export const useIntercom = (): IntercomContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useIntercom must be used within a IntercomProvider tag');
  }
  return contextState;
};

const IntercomProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isIntercomUserValidated, setIsIntercomUserValidated] = useState(false);
  const { user } = useSessionContext();

  const {
    events: { identifyUser },
  } = useTracking();

  useEffect(() => {
    // wait for intercom to be loaded
    const waitForIntercom = () => {
      if (typeof global.Intercom !== 'undefined') {
        setIsLoaded(true);
      } else {
        setTimeout(waitForIntercom, 250);
      }
    };
    waitForIntercom();
  }, []);

  /**
   * Validate the authenticated user with Intercom once the page is loaded
   */
  useEffect(() => {
    if (!isIntercomUserValidated && user?.customShopifyUserId) {
      const fetchIntercomUserHash = async () => {
        const intercomUserHash = await getIntercomUserHash();

        if (!intercomUserHash)
          throw new Error('Could not get intercomUserHash in IntercomProvider');

        identifyUser({
          userId: user.customShopifyUserId,
          cognitoId: user.sub,
          email: user.email,
          emailVerified: user.emailVerified,
          intercomUserHash,
        });
      };
      fetchIntercomUserHash();
      setIsIntercomUserValidated(true);
    }
  }, [isLoaded, user, identifyUser, isIntercomUserValidated]);

  const visualShowIntercom = () => {
    if (isLoaded) {
      document.querySelector('.intercom-lightweight-app')?.classList.remove('hidden');
      document.querySelector('#intercom-container')?.classList.remove('hidden');
    }
  };

  const visualHideIntercom = () => {
    if (isLoaded) {
      document.querySelector('.intercom-lightweight-app')?.classList.add('hidden');
      document.querySelector('#intercom-container')?.classList.add('hidden');
    }
  };

  const showIntercom = () => {
    if (isLoaded) {
      visualShowIntercom();
      global.Intercom('show');
    }
  };

  const hideIntercom = () => {
    if (isLoaded) {
      global.Intercom('hide');
    }
  };

  const shutdownIntercom = () => {
    if (isLoaded) {
      global.Intercom('shutdown');
    }
  };

  return (
    <Context.Provider
      value={{
        isLoaded,
        showIntercom,
        hideIntercom,
        visualShowIntercom,
        visualHideIntercom,
        shutdownIntercom,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default IntercomProvider;
