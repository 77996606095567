import React from 'react';

import classNames from 'classnames';

import styles from './Section.module.css';

export interface Props {
  size?: 'small' | 'medium';
  color?:
    | 'cyan'
    | 'blue'
    | 'white'
    | 'pink'
    | 'green'
    | 'green-350'
    | 'yellow'
    | 'brown'
    | 'brown-50'
    | 'brown-100'
    | 'brown-200'
    | 'purple'
    | 'purple-200'
    | 'purple-550'
    | 'purple-600'
    | 'purple-700'
    | 'cyan-200'
    | 'beige';
  noTopSpacing?: boolean;
  noBottomSpacing?: boolean;
  children?: React.ReactNode;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
}

export interface ItemProps {
  children?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
const Section = React.forwardRef(
  (
    {
      size = 'medium',
      color,
      noTopSpacing,
      noBottomSpacing,
      children,
      id,
      className: additionalClassName,
      style,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const className = classNames(
      styles.section,
      styles[`section--${size}`],
      styles[`section--${color}`],
      noTopSpacing && styles['section--no-top-spacing'],
      noBottomSpacing && styles['section--no-bottom-spacing'],
      additionalClassName,
    );
    return (
      <div className={className} id={id} ref={ref} style={style}>
        {children}
      </div>
    );
  },
);

/* Used when multiple subsections exists inside a sections (same colour) */
const SectionItem = ({ children }: ItemProps) => (
  <div className={styles.section__item}>{children}</div>
);

export default Section;
export { SectionItem };
