const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).trim();
};

const removeCatOrDogFromStartOfString = (string: string): string => {
  if (string.startsWith('cat') || string.startsWith('dog')) {
    return string.slice(3);
  }
  return string;
};

export { capitalize, removeCatOrDogFromStartOfString };
