import { useEffect, createRef } from 'react';

import Icon from 'components/ui/Icon';
import scrollIntoViewIfNeeded from 'utils/scrollIntoViewIfNeeded';

import styles from './Alert.module.css';

// Possible variant extensions:
// 'primary',
// 'secondary',
// 'warning',
// 'info',
// 'light',
// 'dark',
// 'success'

export type AlertProps = {
  message: string;
  variant: 'error' | 'success' | 'info';
  className?: string;
  btnText?: string;
  scrollIntoView?: boolean;
  onClick?();
};

const Alert = ({
  message,
  variant,
  className,
  btnText,
  onClick,
  scrollIntoView = true,
}: AlertProps): JSX.Element => {
  const alertRef = createRef<HTMLDivElement>();

  let iconName = '';

  switch (variant) {
    case 'error':
      iconName = 'exclamation';
      break;
    case 'success':
      iconName = 'check-circle';
      break;
    case 'info':
      iconName = 'info';
      break;
    default:
      iconName = null;
  }

  useEffect(() => {
    if (alertRef.current && scrollIntoView) {
      scrollIntoViewIfNeeded(alertRef.current);
    }
  }, [scrollIntoView, alertRef]);

  return (
    <div
      ref={alertRef}
      className={`${styles[`bg-${variant}`]} rounded border-l-4 ${
        styles[`border-${variant}`]
      } p-4 ${className}`}
    >
      <div className="flex items-center">
        <div className="shrink-0 flex">
          <Icon
            name={iconName}
            className={`h-5 w-5 ${styles[`icon-${variant}`]}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className={`text-sm ${styles[`message-${variant}`]}`}>
            {message}{' '}
            {btnText && (
              <button
                type="button"
                className={`underline mt-2 text-left ${styles[`hover-${variant}`]}`}
                onClick={onClick}
              >
                {btnText}
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
