import * as crypto from 'crypto';

import { Auth, withSSRContext } from 'aws-amplify';
import { NextApiHandler } from 'next';

import configureAmplify from 'services/amplify/configure';

configureAmplify();

export type GetIntercomUserHashResponse = { intercomUserHash: string } | { message: string };

const handler: NextApiHandler<GetIntercomUserHashResponse> = async (req, res) => {
  if (req.method !== 'GET')
    res.status(405).json({
      message: `Method "${req.method}" not supported.`,
    });

  const auth = withSSRContext({ req }).Auth as typeof Auth;
  const shopifyUserId = await auth
    .userAttributes(await auth.currentAuthenticatedUser())
    .then((attributes) => attributes.find(({ Name }) => Name === 'custom:shopifyUserId').Value)
    .catch<null>(() => null);

  if (!shopifyUserId)
    return res.status(500).json({
      message: `Can not return intercom user hash because of missing shopifyUserId attribute`,
    });

  // create a sha-256 hasher
  const sha256Hasher = crypto.createHmac('sha256', process.env.INTERCOM_SECRET);

  // hash the string and set the output format
  const intercomUserHash = sha256Hasher.update(shopifyUserId).digest('hex');

  return res.json({
    intercomUserHash,
  });
};

export default handler;

// Request to the internal API of the store
// Used to identify the user in Intercom chat widget
export const getIntercomUserHash = async () =>
  fetch(`/api/intercom/hash`, {
    method: 'GET',
  })
    .then((res) => res.json() as Promise<GetIntercomUserHashResponse>)
    .catch<GetIntercomUserHashResponse>((err) => {
      // eslint-disable-next-line no-console
      console.error();
      return {
        message: err.message ?? err.toString?.() ?? JSON.stringify(err),
      };
    })
    .then((res) =>
      'intercomUserHash' in res
        ? res.intercomUserHash
        : // eslint-disable-next-line no-console
          (console.error(`Failed to get intercomUserHash:`, res.message) as undefined) ?? null,
    );
