import React from 'react';

import classNames from 'classnames';

import styles from './CardSection.module.css';

interface Props {
  /** The content */
  children?: React.ReactNode;
  /** Size */
  size?: 'x-small' | 'small' | 'large';
  className?: string;
}

const CardSection: React.FC<Props> = ({
  children,
  size = 'large',
  className: additionalClassName,
}) => (
  <div
    className={classNames(
      styles['card-section'],
      styles[`card-section--${size}`],
      additionalClassName,
    )}
  >
    {children}
  </div>
);

export default CardSection;
