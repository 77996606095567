import { NextRouter } from 'next/router';

import { setClientSideCookie } from 'services/session/cookies';
import { CookieNames } from 'utils/constants';

import { getAsUrlForRouter } from './routerUtils';

export interface LocaleContext {
  router: NextRouter;
  newLocale: string;
  newCountryCode?: string;
  forceRefresh?: boolean;
  translatedSlugs?: { en: string; nl: string; fr: string; de: string; it: string; es: string };
}

const changeLocale = async ({
  router,
  newLocale,
  newCountryCode = '',
  forceRefresh = false,
  translatedSlugs,
}: LocaleContext): Promise<void> => {
  const { query } = router;

  setClientSideCookie(CookieNames.PreferredLocale, newLocale);
  /**
   * Set countryCode cookie so the
   * middleware function can execute its logic based on it
   * */
  setClientSideCookie(CookieNames.CountryCode, newCountryCode);

  /**
   * We want `getServerside to rerun after every page load.
   * With the usual method of rerouting through `router.push`, this doesn't always happen,
   * even after setting shallow routing to false. This is a work around to ensure `getServerside reruns.
   */
  if (router.locale !== newLocale || forceRefresh) {
    const params = query;
    delete params.cc;

    await router
      .push(
        { pathname: translatedSlugs ? translatedSlugs[newLocale] : router.pathname, query: params },
        getAsUrlForRouter(router, undefined, translatedSlugs && translatedSlugs[newLocale]),
        {
          locale: newLocale,
        },
      )
      .then(() => {
        /**
         * getServersideProps doesnt't return when the url stays the same (same locale)
         * so we forced this by reloading
         */
        if (forceRefresh) {
          router.reload();
        }
      });
    return Promise.resolve();
  }
  return Promise.reject();
};

export default changeLocale;
