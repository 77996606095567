import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { CookieNames } from 'utils/constants';

import { useCountryContext } from './CountryContextProvider';
import { useTracking } from './TrackingProvider';

/**
 * Returned by the useExperiment hook
 */
export interface UseExperimentReturn {
  ExperimentVariant: React.FC<ExperimentVariantProps>;
  activeVariant: '0' | '1';
}

/**
 * Props for ExperimentVariant helper component
 */
interface ExperimentVariantProps {
  variant: '0' | '1';
  children: React.ReactNode;
}

/**
 * Configuration for experiments
 */
export interface ExperimentProps {
  id: string;
  name: string;
  config?: {
    locales?: string[];
    countries?: string[];
    paths?: string[];
  };
}

export const EXPERIMENTS: { [key: string]: ExperimentProps } = {
  // dogsButton: {
  //   id: 'dogs_button',
  //   name: 'Dogs Button',
  //   config: {
  //     locales: ['fr'],
  //     countries: ['FR'],
  //     paths: ['/dogs'],
  //   },
  // },
  // quickBuyModal: {
  //   id: 'quick_buy_modal',
  //   name: 'Quick Buy Modal',
  //   config: {
  //     countries: ['BE', 'NL'],
  //     paths: ['/collections/dogs', '/collections/cats'],
  //   },
  // },
  // productQuizEmail: {
  //   id: 'product_quiz_email',
  //   name: 'Procuct Quiz Email',
  //   config: {},
  // },
  // homePageConversion: {
  //   id: 'home_page_conversion',
  //   name: 'Home Page Conversion',
  //   config: {
  //     countries: ['FR', 'BE', 'NL'],
  //   },
  // },
};

/**
 * Context
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExperimentContext {}
const Context = createContext<ExperimentContext>(null);

/**
 * UseExperiment hook
 */
export const useExperiment = (experiment: ExperimentProps): UseExperimentReturn => {
  const contextState = useContext(Context);

  if (contextState === null) {
    throw new Error('useExperiment must be used within a ExperimentProvider tag');
  }

  const {
    events: { trackExperimentViewed },
  } = useTracking();

  const [activeVariant, setActiveVariant] = useState(null);
  const router = useRouter();
  const { isAnalyticsLoaded } = useTracking();
  const { countryCode } = useCountryContext();

  const validateExperimentConfig = useCallback(
    (exp: ExperimentProps) => {
      if (
        (!exp?.config?.countries || exp?.config?.countries.includes(countryCode)) &&
        (!exp?.config?.locales || exp?.config?.countries.includes(router.locale)) &&
        (!exp?.config?.paths || exp?.config.paths.includes(router.asPath))
      ) {
        return true;
      }

      return false;
    },
    [router.locale, router.asPath, countryCode],
  );

  useEffect(() => {
    if (validateExperimentConfig(experiment)) {
      /**
       * If the user is a bot, we don't want to set a variant
       */
      if (Cookies.get(CookieNames.BotSession) === '1') {
        return;
      }
      const experimentCookie = Cookies.get(`exp.${experiment.id}`);
      if (experimentCookie) {
        // Variant from Cookie
        setActiveVariant(experimentCookie);
      } else {
        // Set variant
        const randomVariant = Math.floor(Math.random() * 2);
        Cookies.set(`exp.${experiment.id}`, randomVariant, { expires: 365 });
        setActiveVariant(randomVariant.toString());
      }
    }
  }, [experiment, countryCode, validateExperimentConfig]);

  useEffect(() => {
    if (activeVariant && isAnalyticsLoaded) {
      trackExperimentViewed({
        experimentId: experiment.id,
        experimentName: experiment.name,
        variantId: activeVariant,
      });
    }
  }, [activeVariant, trackExperimentViewed, experiment, isAnalyticsLoaded]);

  /**
   * Helper component to render the variants
   */
  const ExperimentVariant = useCallback(
    ({ children, variant }: ExperimentVariantProps) => {
      if (variant === activeVariant || (variant === '0' && !activeVariant)) {
        return <>{children}</>;
      }
      return <></>;
    },
    [activeVariant],
  );

  return { ExperimentVariant, activeVariant };
};

const ExperimentProvider: FC<PropsWithChildren> = ({ children }) => (
  <Context.Provider value={{}}>{children}</Context.Provider>
);

export default ExperimentProvider;
