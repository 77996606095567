import React, { useContext, useState, createContext, useCallback } from 'react';

import { Props as SlideOverProps } from 'components/ui/SlideOver';

interface SlideOverContext {
  isSlideOverOpen: boolean;
  slideOverConfig: SlideOverProps['config'];
  openSlideOver: () => void;
  closeSlideOver: () => void;
  setSlideOverConfig: (configuration: SlideOverProps['config']) => void;
}

const Context = createContext<SlideOverContext>(null);

export const useSlideOver: () => SlideOverContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useSlideOver must be used within a SlideOverProvider tag');
  }
  return contextState;
};

const SlideOverProvider: React.FC<{ children }> = ({ children }) => {
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [slideOverConfig, setSlideOverConfig] = useState<SlideOverProps['config']>({
    children: null,
  });

  const openSlideOver = () => setIsSlideOverOpen(true);
  const closeSlideOver = useCallback(() => setIsSlideOverOpen(false), []);

  return (
    <Context.Provider
      value={{
        isSlideOverOpen,
        openSlideOver,
        closeSlideOver,
        slideOverConfig,
        setSlideOverConfig,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SlideOverProvider;
