import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Select from 'react-select';

import colors from 'colors.js';
import CountryFlag from 'components/ui/CountryFlag';
import Labelled from 'components/ui/Labelled';

interface Option {
  /** Machine value of the option */
  value: string | number;
  /** Human-readable text for the option */
  label: string;
  /** Option will be visible, but not selectable */
  disabled?: boolean;
}

interface OptionsGroup {
  label: string;
  options: Array<Option>;
}

export interface Props {
  selectLabel: string;
  options: Array<OptionsGroup> | Array<Option>;
  labelHidden?: boolean;
  error?: string | boolean;
  id: string;
  name?: string;
  helpText?: string;
  onChange?: Dispatch<SetStateAction<string>>;
  isSearchable?: boolean;
  defaultValue?: string;
  isCountrySelect?: boolean;
  maxHeight?: number;
}

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: '1px solid rgb(231, 238, 239)',
    display: 'flex',
    borderRadius: '.25rem',
    fontSize: '1rem',
  }),
  option: (styles) => ({
    ...styles,
    padding: '.25rem .75rem',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '.25rem',
  }),
  groupHeading: (styles, state) => ({
    ...styles,
    // If label is undefined, display nothing
    ...(state.data.label === undefined
      ? {
          display: 'none',
        }
      : {}),
    // If label is empty string, show divider line
    ...(state.data.label === ''
      ? {
          borderBottom: '1px solid rgb(231, 238, 239)',
          marginBottom: '15px',
        }
      : {}),
  }),
};

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colors.blue[400],
    primary25: colors.cyan[200],
    primary50: colors.cyan[300],
    neutral80: colors.blue[400],
  },
});

const ReactSelect: React.FC<Props> = ({
  selectLabel,
  options,
  labelHidden,
  error,
  id,
  name,
  helpText,
  onChange,
  defaultValue,
  isCountrySelect,
  maxHeight = 160,
  isSearchable = false,
}: Props) => {
  const getOptionForDefaultValueOrFirstOption = (): Option => {
    if (options.length && (options[0] as OptionsGroup).options) {
      // grouped options react select
      let option;
      options.forEach((optionsGroup) => {
        const index = optionsGroup.options.findIndex((o) => o.value === defaultValue);
        if (index >= 0) {
          option = optionsGroup.options[index];
        }
      });

      return option;
    }
    if (options.length) {
      // Not grouped options
      const index = options.findIndex((o) => o.value === defaultValue);
      if (index >= 0) {
        return options[index] as Option;
      }
    }
    return (options[0] as OptionsGroup).options
      ? ((options[0] as OptionsGroup).options[0] as Option)
      : (options[0] as Option);
  };

  const [selectedOption, setSelectedOption] = useState<Option>(
    // eslint-disable-next-line no-nested-ternary
    getOptionForDefaultValueOrFirstOption(),
  );

  const formatOptionLabel = ({ label, value }) => (
    <div className="flex items-center">
      {isCountrySelect ? <CountryFlag className="mr-3 shrink-0" countryCode={value} /> : null}
      <div className="text-base">{label}</div>
    </div>
  );

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  useEffect(() => {
    handleChange(getOptionForDefaultValueOrFirstOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Labelled
      id={id}
      label={selectLabel}
      labelHidden={labelHidden}
      error={error}
      helpText={helpText}
    >
      <div data-testid={isCountrySelect && 'countrySelect'}>
        <Select
          maxMenuHeight={maxHeight}
          formatOptionLabel={formatOptionLabel}
          value={selectedOption}
          styles={customStyles}
          isSearchable={isSearchable}
          onChange={handleChange}
          options={options}
          name={name}
          theme={customTheme}
        />
      </div>
    </Labelled>
  );
};

export default ReactSelect;
