import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

import type { CartItemType } from '.';
import type { ExtendedShopifyCart } from '../..';
import useCartStates from '../useCartStates';
import type useCartTracking from '../useCartTracking';
import type useExtendShopifyCart from '../useExtendShopifyCart';

/**
 * @private Hook that returns a function to remove item from cart
 */
const useRemoveFromCart = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
  cartTracking: ReturnType<typeof useCartTracking>,
) => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();
  const queryClient = useQueryClient();

  const removeFromCart = async (
    universalKey: string,
    type: CartItemType,
    options?: {
      onSuccess?: (universalKey) => void;
      onError?: (universalKey) => void;
    },
  ): Promise<void> => {
    /** Get the cart line id corresponding to the universalKey */
    const shopifyCartLineNode = shopifyCart.lines.edges.find(
      (edge) =>
        edge.node.merchandise.sku === universalKey &&
        (type === 'regular' || (type === 'suggested' && edge.node.ecLineItemType === 'suggested')),
    )?.node;

    // Lock the cart when actually updating
    cartStates.setIsCartUpdating(true);

    await storefrontSDK
      .cartLinesRemove({
        cartId: shopifyCart.id,
        lineIds: shopifyCartLineNode.id,
      })
      .then((res) => {
        if (res.cartLinesRemove.userErrors.length > 0) {
          throw new Error(
            `Could not remove cart line due to userErrors: ${JSON.stringify(
              res.cartLinesRemove.userErrors,
            )}`,
          );
        }
        // Set query data based on result
        return extendShopifyCart(res.cartLinesRemove.cart).then((extendedCart) => {
          // If cookie id changed, set client side cookie
          setClientSideCookie(CookieNames.CartId, res.cartLinesRemove.cart.id);
          return queryClient.setQueryData(
            QueryKeys[Keys.Cart](countryCode, locale),
            () => extendedCart,
          );
        });
      })
      .then((_extendedCart) => {
        if (options?.onSuccess) {
          options.onSuccess(universalKey);
        }
        cartTracking.trackCartUpdated(
          shopifyCart.id,
          'remove',
          shopifyCartLineNode,
          shopifyCartLineNode.quantity,
          type,
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (options?.onError) {
          options.onError(universalKey);
        }
        // On error, refetch cart so item will jump back
        queryClient.refetchQueries(QueryKeys[Keys.Cart](countryCode, locale));
      });

    cartStates.setIsCartUpdating(false);
  };

  return removeFromCart;
};

export default useRemoveFromCart;
