import { FC, Fragment, PropsWithChildren, useEffect, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Router } from 'next/router';

import Icon from 'components/ui/Icon';

export interface Props {
  isOpen: boolean;
  close: () => void;
  config: {
    internalTitle?: string; // used for modal workaround when we had updating modal issues
    children?: React.ReactNode;
    title?: string;
    size?: 'sm' | 'md' | 'lg' | 'full' | null;
    padding?: 'none' | null;
    required?: boolean;
    allowOverflow?: boolean;
    keepOpenWhileRouteChange?: boolean;
  };
}

const Modal: FC<PropsWithChildren<Props>> = ({ isOpen, close, config, children }) => {
  const cancelButtonRef = useRef(null);
  const { size, title, padding, allowOverflow, keepOpenWhileRouteChange } = config;

  let sizeClass = 'sm:max-w-md';
  switch (size) {
    case 'sm':
      sizeClass = 'sm:max-w-md';
      break;
    case 'md':
      sizeClass = 'sm:max-w-3xl';
      break;
    case 'lg':
      sizeClass = 'sm:max-w-5xl';
      break;
    case 'full':
      sizeClass = 'sm:max-w-full h-full';
      break;
    default:
      sizeClass = 'sm:max-w-md';
  }

  useEffect(() => {
    if (!keepOpenWhileRouteChange) {
      Router.events.on('routeChangeComplete', close);
    }

    return () => {
      Router.events.off('routeChangeComplete', close);
    };
  }, [close, keepOpenWhileRouteChange]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed flex items-center z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={config.required ? () => {} : close}
      >
        <div
          className={classNames(
            `my-auto mx-auto w-full`,
            sizeClass,
            size !== 'full' ? 'overflow-y-auto p-4' : '',
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-400 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-0 scale-95"
            enterTo="opacity-100 translate-y-0 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 scale-100"
            leaveTo="opacity-0 translate-y-0 scale-95"
          >
            <div
              className={classNames(
                'flex flex-col bg-white rounded text-left shadow-xl transform transition-all',
                size === 'full' && 'h-full overflow-y-auto',
                size !== 'full' && 'my-24',
                padding !== 'none' && 'pt-8',
                !allowOverflow && 'overflow-hidden',
              )}
            >
              {!config.required && (
                <button
                  className={classNames(
                    'absolute right-0 top-0 z-50 flex items-center justify-center ml-auto w-12 h-12 transition hover:bg-cyan-200 rounded',
                    padding === 'none' && 'bg-cyan-200 bg-opacity-50',
                  )}
                  onClick={close}
                  ref={cancelButtonRef}
                  type="button"
                >
                  <Icon name="cross" />
                  <span className="sr-only">Close panel</span>
                </button>
              )}
              <div
                className={classNames(
                  'grow flex-col',
                  padding !== 'none' && 'p-4 pt-0 sm:p-8 sm:pt-0',
                )}
              >
                {title && <Dialog.Title className="heading-3 mb-4 sm:mb-8">{title}</Dialog.Title>}
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
