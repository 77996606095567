import { CookieNames, CountrySettingsCookieProperties } from 'utils/constants';

import { getClientSideCookie } from '../../services/session/cookies';

type Request = {
  cookies: unknown;
};

/** @returns The client side cookie `property` value.
 * On the server side it returns `null`, unless it is given a valid `req` object.
 *
 * If the cookie can not be parsed, it will return null. */
const getPropertyFromCountrySettingsCookie = <T = unknown>(
  property: CountrySettingsCookieProperties,
  req?: Request,
): T => {
  let value: T;
  try {
    value = JSON.parse(
      req
        ? req.cookies[CookieNames.CountrySettings]
        : getClientSideCookie(CookieNames.CountrySettings),
    )[property];
  } catch {
    value = null;
  }

  return value;
};

export default getPropertyFromCountrySettingsCookie;
