import Link from 'next/link';

import { Article } from 'services/contentful/generated-types';
import { useTranslations } from 'services/translations/TranslationsProvider';

import BlogCategoryLink from '../BlogCategoryLink';
import Button from '../Button';
import ECImage from '../ECImage';
import Icon from '../Icon';

export interface BlogCategory {
  name: string;
  color: string;
  href: string;
}

export interface ArticleSpotlightProps {
  article: Article;
  locale: string;
}

const ArticleSpotlight = ({
  article: { mainCategory, date, title, image, slug },
  locale,
}: ArticleSpotlightProps): JSX.Element => {
  const { t } = useTranslations('page.blog.common');
  const href = `/blog/${slug}/`;

  return (
    <article className="flex flex-col lg:flex-row rounded overflow-hidden">
      <div className="flex flex-col justify-center items-start bg-white p-4 xs:p-8 md:p-16 lg:max-w-lg">
        <div className="w-full xs:flex items-center justify-between mb-10">
          {mainCategory && (
            <BlogCategoryLink
              name={mainCategory.name}
              color={mainCategory.color}
              href={`/blog/category/${mainCategory.slug}/`}
              className="mr-4 mb-2 xs:mb-0"
            />
          )}
          {date && (
            <time dateTime={date} className="text-gray-100 text-base">
              {new Date(date).toLocaleDateString(locale, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </time>
          )}
        </div>
        <Link href={href} className="heading-2">
          {title}
        </Link>
        <Link href={href} passHref legacyBehavior>
          <Button legacy color="cyan" className="mt-10">
            {t('page.blog.common:continueReading')}
            <Icon name="arrow-right" className="w-4 ml-4" />
          </Button>
        </Link>
      </div>
      <div className="order-first lg:order-last">
        <ECImage
          img={{
            src: image?.url || '',
            alt: image?.description,
          }}
          className="w-full h-full object-cover"
          srcOptions={{ w: 1328, q: 90 }}
          loading="eager"
        />
      </div>
    </article>
  );
};

export default ArticleSpotlight;
