/* eslint-disable jsx-a11y/no-redundant-roles */
import { useRouter } from 'next/router';

const ECLink = ({
  href,
  as = undefined,
  className = '',
  children,
  onClickEvent = undefined,
  ...props
}): JSX.Element => {
  const router = useRouter();

  const hrefWithLocale = href.startsWith('http')
    ? href
    : `/${router.locale}${href.startsWith('/') ? href : `/${href}`}`;

  const goToLink = (e) => {
    onClickEvent?.();
    router.push(hrefWithLocale, as, {
      shallow: false,
    });
    e.preventDefault(e);
    return false;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) goToLink(e);
  };

  return (
    <a
      href={hrefWithLocale}
      onClick={goToLink}
      target={href.startsWith('http') ? '_blank' : undefined}
      onKeyDown={handleKeyDown}
      {...props}
      className={`${className} cursor-pointer`}
      role="link"
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default ECLink;
