import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import type { ExtendedShopifyCart } from 'context/ShopifyCartProvider';
import { setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

import useCartStates from '../useCartStates';
import useExtendShopifyCart from '../useExtendShopifyCart';

/**
 * @private Hook that returns a function to clear the cart
 */
const useClearCart = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
) => {
  const { countryCode } = useCountryContext();
  const { locale } = useRouter();
  const queryClient = useQueryClient();

  const clearCart = async (options?: { onSuccess?: () => void; onError?: () => void }) => {
    if (!shopifyCart) return;

    // Lock the cart when actually updating
    cartStates.setIsCartUpdating(true);

    await storefrontSDK
      .cartLinesRemove({
        cartId: shopifyCart.id,
        lineIds: shopifyCart.lines.edges.map(({ node }) => node.id),
      })
      .then((res) => {
        if (res.cartLinesRemove.userErrors.length > 0) {
          throw new Error(
            `Could not remove cart line due to userErrors: ${JSON.stringify(
              res.cartLinesRemove.userErrors,
            )}`,
          );
        }
        // Set query data based on result
        return extendShopifyCart(res.cartLinesRemove.cart).then((extendedCart) => {
          // If cookie id changed, set client side cookie
          setClientSideCookie(CookieNames.CartId, res.cartLinesRemove.cart.id);
          return queryClient.setQueryData(
            QueryKeys[Keys.Cart](countryCode, locale),
            () => extendedCart,
          );
        });
      })
      .then((_extendedCart) => {
        if (options?.onSuccess) {
          options.onSuccess();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (options?.onError) {
          options.onError();
        }
        // On error, refetch cart so item will jump back
        queryClient.refetchQueries(QueryKeys[Keys.Cart](countryCode, locale));
      });

    cartStates.setIsCartUpdating(false);
  };

  return clearCart;
};

export default useClearCart;
