import logger from 'utils/logger';

import getContentfulGraphQLClient from './graphql-client';

/**
 *
 * @param query query to send
 * @param variables Variables without preview
 * @returns Promise that resolves the data
 */
const contentfulGraphQLRequest = async <Data>(
  query: string,
  variables: Record<string, unknown>,
): Promise<Data> => {
  const startTime = Date.now();
  return getContentfulGraphQLClient(!!variables.preview)
    .request<Data>(query, variables)
    .catch((err) => {
      logger.error('contentfulGraphQLRequest@request - caught error', {
        graphqlQuery: query,
        variables,
        errorMessage: err,
        errorStack: err?.stack,
      });
      throw err;
    })
    .finally(() => {
      logger.info('contentfulGraphQLRequest@request - resolved request', {
        graphqlQuery: query,
        variables,
        latency: Date.now() - startTime,
      });
    });
};

export default contentfulGraphQLRequest;
