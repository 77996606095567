import classNames from 'classnames';

import Spinner from '../Spinner';

interface Props {
  size?: 'small' | 'large';
  /** Additional class names */
  className?: string;
}

const Loader: React.FC<Props> = ({ size = 'small', className = '' }) => (
  <div
    className={classNames(
      size === 'small' && 'h-10',
      size === 'large' && 'h-20',
      'flex items-center justify-center',
      className,
    )}
  >
    <Spinner size={size} />
  </div>
);

export default Loader;
