/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import slugify from 'slugify';

export const capitalizeFirstLetter = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);
export const lowercaseFirstLetter = (str: string): string =>
  str.charAt(0).toLowerCase() + str.slice(1);
export const slugifyString = (string: string): string => slugify(string, { lower: true });

export const hashStringToNumber = (str) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0; // Convert to an unsigned 32-bit integer
};
