import React, { Children, isValidElement, ReactElement, ReactNode } from 'react';

import classNames from 'classnames';

import CardSection from './CardSection';

export interface Props {
  /** The content of the card */
  children?: React.ReactNode;
  /** Size */
  size?: 'small' | 'large';
  className?: string;
  isLoading?: boolean;
}

/* Helper function to get children */
const elementChildren = <T extends React.ReactElement<React.ReactNode>>(
  children: React.ReactNode,
): T[] => Children.toArray(children).filter((child) => isValidElement(child)) as T[];

const Card: React.FC<Props> = ({
  children,
  size = 'large',
  className: additionalClassName,
  isLoading,
}) => {
  let hasSection = false;

  elementChildren(children).forEach((child: ReactElement<ReactNode>) => {
    if (child.type === CardSection) {
      hasSection = true;
    }
  });

  /* Set content markup, wrap in a section if needed */
  let contentMarkup = null;
  if (children) {
    if (hasSection) {
      contentMarkup = children;
    } else {
      contentMarkup = <CardSection size={size}>{children}</CardSection>;
    }
  }

  return (
    <div
      className={classNames(
        `${
          isLoading ? 'bg-blue-200 animate-pulse' : 'bg-white'
        } border rounded flex flex-col w-full`,
        additionalClassName,
      )}
    >
      {contentMarkup}
    </div>
  );
};

export default Card;

export { CardSection };
