import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTracking } from './TrackingProvider';

/**
 * Returned by the useOptimizeExperiment hook
 */
export interface UseOptimizeExperimentReturn {
  activeVariant: string;
  isLoaded: boolean;
  ExperimentVariant: React.FC<OptimizeExperimentVariantProps>;
  activate: () => void;
}

/**
 * Props for OptimizeExperimentVariant helper component
 */
interface OptimizeExperimentVariantProps {
  variant: '0' | '1';
  children: React.ReactNode;
}

/**
 * Configuration for OptimzeExperiments
 */
export interface OptimizeExperimentProps {
  id: string;
  name: string;
}
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
export const OPTIMIZE_EXPERIMENTS: { [key: string]: OptimizeExperimentProps } = {
  FreeShippingCalculator: {
    id: isProduction ? 'U68ns1B0TdeLjTXebGf7Fg' : 'NaztwDauQq6E8TN9C02tmA',
    name: 'Free Shipping Calculator',
  },
};

/**
 * Context
 */
interface OptimizeExperimentContext {
  isLoaded: boolean;
}
const Context = createContext<OptimizeExperimentContext>(null);

/**
 * UseOptimizeExperiment hook
 */
export const useOptimizeExperiment = (
  exp: OptimizeExperimentProps,
): UseOptimizeExperimentReturn => {
  const contextState = useContext(Context);

  if (contextState === null) {
    throw new Error('useOptimizeExperiment must be used within a OptimizeExperimentProvider tag');
  }

  const { isLoaded } = contextState;

  const [expActivated, setExpActivated] = useState(null);
  const [activeVariant, setActiveVariant] = useState(null);

  const {
    events: { trackExperimentViewed },
  } = useTracking();

  /**
   * Check if the experiment is activated and retrieve the variant.
   */
  useEffect(() => {
    if (isLoaded && exp) {
      global.dataLayer?.push({
        event: 'optimize.callback',
        eventModel: {
          name: exp.id,
          callback: (value: string) => {
            if (typeof value === 'undefined') {
              // undefined if the experiment no longer matches the specifications for the experiment
              setActiveVariant(null);
              setExpActivated(false);
            } else {
              // set variant
              // '0 is the original variant
              setActiveVariant(value);
              setExpActivated(true);
            }
          },
        },
      });
    }
  }, [isLoaded, exp]);

  /**
   * Sent trigger (event to Analytics) to activate Optimize experiment.
   * Keep in mind the experiment will only start if all conditions (page and audience targeting) are valid.
   * The activation event needs to be configured in Optimize.
   */
  const activate = useCallback(() => {
    if (isLoaded && exp) {
      global.dataLayer.push({ event: `optimize.activate.${exp.id}` });
    }
  }, [isLoaded, exp]);

  /**
   * Send tracking event when experiment is viewed
   */
  useEffect(() => {
    if (expActivated === true) {
      trackExperimentViewed({
        experimentId: exp.id,
        experimentName: exp.name,
        variantId: activeVariant,
      });
    }
  }, [expActivated, activeVariant, exp, trackExperimentViewed]);

  /**
   * Helper component to render the variants
   */
  const ExperimentVariant = useCallback(
    ({ children, variant }: OptimizeExperimentVariantProps) => {
      if (variant === activeVariant || (variant === '0' && !activeVariant)) {
        return <>{children}</>;
      }
      return <></>;
    },
    [activeVariant],
  );

  return { activeVariant, isLoaded, activate, ExperimentVariant };
};

const OptimizeExperimentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Wait for google optimize to be loaded via Segment
   * with a max delay of 5000ms
   */
  useEffect(() => {
    let maxDelay = 5000;
    const retryInterval = 50;
    const waitForGoogleOptimize = () => {
      const intervalId = setInterval(() => {
        if (typeof global.google_optimize !== 'undefined') {
          setIsLoaded(true);
          clearInterval(intervalId);
        }
        if (maxDelay <= 0) {
          clearInterval(intervalId);
        } else {
          maxDelay -= retryInterval;
        }
      }, retryInterval);
    };
    waitForGoogleOptimize();
  }, []);

  return (
    <Context.Provider
      value={{
        isLoaded,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default OptimizeExperimentProvider;
