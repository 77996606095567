import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useRouter } from 'next/router';

import { bffRequest } from 'api/client/bff-request';
import { CountryWithProvinces } from 'api/generated-types';
import { CountrySettingsCookieProperties } from 'utils/constants';
import getPropertyFromCountrySettingsCookie from 'utils/country-settings/getPropertyFromCountrySettingsookie';

import { FALLBACK_COUNTRY_CODE } from '../utils/constants';

const getAllShippingCountriesQuery = gql`
  query getAllShippingCountriesQuery($locale: String!) {
    shippingCountries(locale: $locale) {
      code
      name
      freeShippingFrom
      leadTimeInDays
      provinces {
        code
        name
      }
      currencyCode
    }
  }
`;

interface ShippingCountriesData {
  shippingCountries: CountryWithProvinces[];
}

type Props = PropsWithChildren & {
  countryCode?: string;
};

export const fetchShippingCountries = async (locale: string): Promise<CountryWithProvinces[]> => {
  const shippingCountriesData = await bffRequest<ShippingCountriesData>({
    query: getAllShippingCountriesQuery,
    variables: {
      locale,
    },
  });

  return shippingCountriesData.shippingCountries;
};

// Context ----------
export type CountryContext = ReturnType<typeof useCountryContextValue>;
const Context = createContext<CountryContext>(null);
export const useCountryContext = () => useContext(Context);

/** This is the hook used by the CountryContext.Provider component to create the countryContext value. */
const useCountryContextValue = (countryCode: string) => {
  const { locale } = useRouter();

  /** @see `CountryContext.cookieCountryCode` or the return value of this function for more information. */
  const cookieCountryCode = getPropertyFromCountrySettingsCookie<string | null>(
    CountrySettingsCookieProperties.countryCode,
  );

  const { data: shippingCountries, isLoading } = useQuery({
    queryKey: ['shippingCountries', locale] as const,
    queryFn: ({ queryKey }) => fetchShippingCountries(queryKey[1]),
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  const currentShippingCountry = shippingCountries?.find(
    (shippingCountry) => shippingCountry.code === cookieCountryCode,
  );

  const {
    leadTimeInDays: shippingCountryLeadTimeInDays,
    freeShippingFrom: shippingCountryFreeShippingFrom,
  } = currentShippingCountry ?? {};

  const [countryContext, setCountryContext] = useState({
    /** The actual state of the countryCode.
     * On the server and initial render it is equal to the `FALLBACK_COUNTRY_CODE` constant.
     * On the second render it will get the value from the user cookie */
    countryCode: countryCode ?? FALLBACK_COUNTRY_CODE,
    currencyCode: 'EUR',
    subscriptionEnabled: true,
    // Default to today 18:00
    cutOffDateTime: new Date(new Date().setHours(18, 0, 0, 0)),
    leadTimeInDays: shippingCountryLeadTimeInDays ?? 2,
    deliveryDayBeforeCutOff: undefined as string | undefined,
    deliveryDayAfterCutOff: undefined as string | undefined,
    freeShippingFrom: shippingCountryFreeShippingFrom,
  });

  useEffect(() => {
    const subscriptionEnabled = getPropertyFromCountrySettingsCookie<boolean>(
      CountrySettingsCookieProperties.isSubscriptionEnabled,
    );

    // Set the client side cookie values
    setCountryContext((prev) => ({
      ...prev,
      countryCode: cookieCountryCode ?? prev.countryCode,
      currencyCode:
        getPropertyFromCountrySettingsCookie<string>(
          CountrySettingsCookieProperties.currencyCode,
        ) || prev.currencyCode,
      subscriptionEnabled:
        typeof subscriptionEnabled === 'boolean' ? subscriptionEnabled : prev.subscriptionEnabled,
      cutOffDateTime: new Date(
        getPropertyFromCountrySettingsCookie<string>(
          CountrySettingsCookieProperties.cutOffDateTime,
        )?.match(
          // Need to check if the value is in the form of: '2024-08-05T16:00:00+00:00'
          /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/,
        )
          ? getPropertyFromCountrySettingsCookie<string>(
              CountrySettingsCookieProperties.cutOffDateTime,
            )
          : prev.cutOffDateTime, // Default when cookie doesn't match the regex
      ),
      leadTimeInDays: shippingCountryLeadTimeInDays,
      freeShippingFrom: shippingCountryFreeShippingFrom,
      deliveryDayBeforeCutOff:
        // Need to check if the value is in the form of: "2024-01-01"
        getPropertyFromCountrySettingsCookie<string>(
          CountrySettingsCookieProperties.deliveryDayBeforeCutOff,
        )?.match(/^\d{4}-\d{2}-\d{2}$/)
          ? getPropertyFromCountrySettingsCookie<string>(
              CountrySettingsCookieProperties.deliveryDayBeforeCutOff,
            )
          : undefined,
      deliveryDayAfterCutOff: getPropertyFromCountrySettingsCookie<string>(
        CountrySettingsCookieProperties.deliveryDayAfterCutOff,
      )?.match(/^\d{4}-\d{2}-\d{2}$/)
        ? getPropertyFromCountrySettingsCookie<string>(
            CountrySettingsCookieProperties.deliveryDayAfterCutOff,
          )
        : undefined,
    }));

    // Set correct country context after first render
    Sentry.setTag('countryCode', cookieCountryCode);
  }, [cookieCountryCode, shippingCountryLeadTimeInDays, shippingCountryFreeShippingFrom]);

  return {
    ...countryContext,
    shippingCountries,
    isLoading,
    /** The client side country code cookie value.
     * This is used for tracking purposes because `CountryContext.countryCode` === `FALLBACK_COUNTRY_CODE` on the server and first render.
     * If they were to differ, it would cause hydration errors
     *
     * If the countryCode could not be found in the cookie, it is set to `FALLBACK_COUNTRY_CODE` */
    cookieCountryCode: cookieCountryCode ?? FALLBACK_COUNTRY_CODE,
  };
};

const CountryContextProvider: FC<Props> = ({ children, countryCode }) => (
  <Context.Provider value={useCountryContextValue(countryCode)}>{children}</Context.Provider>
);
export default CountryContextProvider;
