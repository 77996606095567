const GID_PREFIX = 'gid://shopify/ProductVariant/';

const isGid = (id: string): boolean => Buffer.from(id, 'base64').includes(GID_PREFIX);

export const variantIdToGid = (variantId: string | number): string => {
  const id = typeof variantId === 'number' ? variantId.toString() : variantId;
  return isGid(id) ? id : Buffer.from(`${GID_PREFIX}${id}`).toString('base64');
};

export const variantIdToPlainGid = (variantId: string | number): string => {
  const id = typeof variantId === 'number' ? variantId.toString() : variantId;
  return `${GID_PREFIX}${id}`;
};

export const gidToVariantId = (gid: string): number => {
  if (parseFloat(gid)) {
    return parseFloat(gid);
  }
  const idParts = Buffer.from(gid, 'base64').toString().split('/');
  return Number(idParts[idParts.length - 1]);
};
