import { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { setClientSideCookie } from 'services/session';
import { storefrontSDK } from 'services/shopify/storefront';
import { CountryCode } from 'services/shopify/storefront/generated-sdk';
import { CookieNames } from 'utils/constants';

import type useCartStates from './useCartStates';
import useExtendShopifyCart from './useExtendShopifyCart';
import type { ExtendedShopifyCart } from '..';

/**
 * Private hook to manage the buyer identity.
 */
const useBuyerIdentity = (
  shopifyCart: ExtendedShopifyCart,
  cartStates: ReturnType<typeof useCartStates>,
  extendShopifyCart: ReturnType<typeof useExtendShopifyCart>,
) => {
  const { countryCode } = useCountryContext();
  const queryClient = useQueryClient();
  const { locale } = useRouter();
  const [isBuyerIdentityUpdated, setIsBuyerIdentityUpdated] = useState(false);

  // Always update buyer identity at beginning of session because Shopify does not recognize the buyer identity when logged in
  const isBuyerIdentityUpdatedAtBeginningOfSession = useRef(false);

  useEffect(() => {
    if (cartStates.isCartLoading || cartStates.isCartUpdating) {
      return;
    }
    if (
      shopifyCart &&
      (shopifyCart?.buyerIdentity?.countryCode !== countryCode ||
        !isBuyerIdentityUpdatedAtBeginningOfSession.current) &&
      countryCode !== 'OTHER'
    ) {
      cartStates.setIsCartUpdating(true);
      storefrontSDK
        .cartBuyerIdentityUpdate({
          cartId: shopifyCart.id,
          buyerIdentity: {
            countryCode: countryCode as CountryCode,
          },
        })
        .then((res) => {
          if (res.cartBuyerIdentityUpdate?.userErrors.length > 0) {
            const err = new Error('Failed to update buyer identity in cart');
            Sentry.captureException(err, {
              extra: {
                userErrors: res.cartBuyerIdentityUpdate?.userErrors,
              },
            });
          }

          isBuyerIdentityUpdatedAtBeginningOfSession.current = true;

          // Set query data based on result
          return extendShopifyCart(res.cartBuyerIdentityUpdate.cart).then((extendedCart) => {
            // If cookie id changed, set client side cookie
            setClientSideCookie(CookieNames.CartId, res.cartBuyerIdentityUpdate.cart.id);
            return queryClient.setQueryData(
              QueryKeys[Keys.Cart](countryCode, locale),
              () => extendedCart,
            );
          });
        })
        .finally(() => {
          cartStates.setIsCartUpdating(false);
          setIsBuyerIdentityUpdated(true);
        });
    }
  }, [countryCode, shopifyCart, extendShopifyCart, queryClient, locale, cartStates]);

  return {
    isBuyerIdentityUpdated,
  };
};

export default useBuyerIdentity;
