/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

const identify = (email: string): void => {
  if (process.env.NEXT_PUBLIC_LOYALTYLION_STORE_NAME === 'Edgard & Cooper') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { klaviyo } = window as any;
    if (klaviyo) {
      klaviyo.push([
        'identify',
        {
          $email: email,
        },
      ]);
      return;
    }

    setTimeout(() => {
      identify(email);
    }, 100);
  }
};

export default identify;
