import React from 'react';

import classNames from 'classnames';

interface Props {
  number: string | number;
  prefix?: string;
  color?: 'cyan' | 'white';
  className?: string;
  footnoteChar?: string;
}

interface ItemProps {
  item: string;
}

const NumberCounter: React.FC<Props> = ({
  number,
  prefix,
  className: additionalClassName,
  color = 'white',
  footnoteChar,
}) => {
  const numberWithSeperator = Number(number).toLocaleString('en').toString();

  const isCommaSeperator = (value: string) => value === ',';

  const bgColor = color === 'white' ? 'bg-white' : 'bg-cyan-400';
  const textColor = color === 'white' ? '' : 'text-white';
  const commaColor = color === 'white' ? 'text-white' : 'text-cyan-400';

  const TickerItem: React.FC<ItemProps> = ({ item }) => {
    if (isCommaSeperator(item)) {
      return <div className={`${commaColor} self-end`}>{item}</div>;
    }
    return <div className={`${bgColor} ${textColor} rounded-sm px-2 py-3`}>{item}</div>;
  };

  return (
    <div
      className={classNames(
        'relative inline-flex flex-row flex-gap-x-2 text-2xl maxXs:text-base',
        additionalClassName,
      )}
    >
      {prefix && <div className="bg-white rounded-sm px-2 py-3">{prefix}</div>}
      {numberWithSeperator.split('').map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TickerItem item={item} key={index} />
      ))}
      {footnoteChar && (
        <div className="absolute -right-3 -top-4 flex font-bold text-sm rounded-full leading-none text-white">
          <span>{footnoteChar}</span>
        </div>
      )}
    </div>
  );
};

export default NumberCounter;
