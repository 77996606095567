import React from 'react';

import classNames from 'classnames';

import { Image } from 'api/generated-types';
import Card, { CardSection } from 'components/ui/Card';
import CountryFlag from 'components/ui/CountryFlag';
import Icon from 'components/ui/Icon';

import ECImage from '../ECImage';

interface Props {
  title?: string;
  description?: string;
  author?: string;
  image?: Image;
  countryCode?: string;
  className?: string;
}

const Testimonial: React.FC<Props> = ({
  title,
  description,
  author,
  image,
  countryCode,
  className: additionalClassName,
}) => (
  <>
    {image && (
      <div className="max-w-xxxs md:max-w-xxs mx-auto border-8 border-cyan-400 rounded transform -rotate-2 -mb-4">
        <ECImage
          img={image}
          className="object-contain"
          pictureClassName="block aspect-w-16 aspect-h-16"
          width={392}
          height={392}
        />
      </div>
    )}
    <Card className={classNames('px-2 items-center text-center', additionalClassName)}>
      <CardSection className="justify-center">
        <div className="flex flex-col sm:flex-row sm:flex-gap-8">
          <span
            className="text-9xl leading-none w-10 h-10 text-gray-100"
            style={{ fontSize: '120px' }}
          >
            “
          </span>
          <div className="flex flex-col items-center justify-center">
            <div className="flex text-cyan-400 mb-4">
              <Icon name="star" className="w-8" />
              <Icon name="star" className="w-8" />
              <Icon name="star" className="w-8" />
              <Icon name="star" className="w-8" />
              <Icon name="star" className="w-8" />
            </div>
            {title && <div className="heading-3">{title}</div>}
            {description && (
              <div className="text-gray-500 text-lg">
                <p>{description}</p>
              </div>
            )}
            {author && (
              <div className="text-cyan-400 text-lg mt-4">
                {countryCode && <CountryFlag countryCode={countryCode} className="mr-2" />}
                <span>{author}</span>
              </div>
            )}
          </div>
          <span
            className="text-9xl leading-none w-10 h-10 text-gray-100 self-end"
            style={{ fontSize: '120px' }}
          >
            ”
          </span>
        </div>
      </CardSection>
    </Card>
  </>
);

export default Testimonial;
