import { createContext, useCallback, useContext, useState } from 'react';

type HeaderAction = 'show';

type HeaderActionsContext = {
  triggerAction: (action: HeaderAction) => void;
  addActionListener: (listener: HeaderActionListener) => void;
};

type HeaderActionListener = {
  actions: HeaderAction[];
  callback: (headerAction: HeaderAction) => void;
};
const Context = createContext<HeaderActionsContext>(null);

const useHeaderActions = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useHeaderActions must be used within a HeaderActionsProvider tag');
  }
  return contextState;
};

const HeaderActionsProvider: React.FC<{ children }> = ({ children }) => {
  const [actionListeners, setActionListeners] = useState<HeaderActionListener[]>([]);

  const addActionListener = useCallback((listener: HeaderActionListener) => {
    setActionListeners((prevListeners) => [...prevListeners, listener]);
  }, []);

  const triggerAction = useCallback(
    (action: HeaderAction) => {
      actionListeners.forEach((listener) => {
        if (listener.actions.includes(action)) {
          listener.callback(action);
        }
      });
    },
    [actionListeners],
  );

  return (
    <Context.Provider value={{ triggerAction, addActionListener }}>{children}</Context.Provider>
  );
};

export { HeaderActionsProvider, useHeaderActions };
