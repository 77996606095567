// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE),

  ignoreErrors: [
    // this error is caused mostly by the user navigating away from the site when a fetch call is in progress, and thus it’s being terminated.
    /^Failed to fetch$/,
    // https://stackoverflow.com/a/60860369
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: cancelled',
    // Filter out this error: https://sentry.io/organizations/edgard-cooper/issues/2966176997/?project=6110335&statsPeriod=24h
    // As it is an issue with the pre-fetch on the collection page, our quota on Sentry fills up fast with this error
    /Variable "\$handle" of required type "String!" was not provided/i,
  ],
  beforeSend: (event) => {
    if (process.env.NEXT_PUBLIC_SENTRY_SEND_EVENTS === 'false') {
      return null;
    }

    if (event?.message?.match(/"path":["productByHandle"]/)) {
      return null;
    }

    // Filter out when message contains the string "NotAuthorizedException", "UserNotFoundException" or "UserNotConfirmedException"
    // These login errors are not relevant to us
    if (
      event?.exception?.values?.[0]?.type?.match(/NotAuthorizedException/i) ||
      event?.exception?.values?.[0]?.type?.match(/UserNotFoundException/i) ||
      event?.exception?.values?.[0]?.type?.match(/UserNotConfirmedException/i)
    ) {
      return null;
    }

    // Filter out when message contains the string "Failed to create credentials for provider"
    // https://edgard-cooper.sentry.io/issues/4352445264/?project=6110335
    if (
      event?.message?.match(/Failed to create credentials for provider.*already exists/i) ||
      event?.message?.match(
        /Failed to create credentials for provider.*has no provider users linked to it/i,
      )
    ) {
      return null;
    }

    // Filter out irrelevant google & tiktok ad errors,
    // because Wouter VM has confirmed he hasn’t received any errors from the Google and TitTok ads that could be related,
    // so these can be ignored
    // https://www.notion.so/edgardcooper/Tiktok-ads-errors-8e520319b34c45dcb8842cc077563bb2?pvs=4
    // https://www.notion.so/edgardcooper/Google-ads-errors-4bc46181e69f4416992512d2520b5c21?pvs=4
    if (
      event?.breadcrumbs?.find(
        (breadcrumb) =>
          (breadcrumb?.data?.url ===
            'https://mon-va.byteoversea.com/monitor_browser/collect/batch/' ||
            breadcrumb?.data?.url ===
              'https://pagead2.googlesyndication.com/pagead/buyside_topics/set/') &&
          breadcrumb?.level === 'error',
      )
    ) {
      return null;
    }

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
