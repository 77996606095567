import React, { useContext, useState, createContext, useCallback } from 'react';

import { Props as ModalProps } from 'components/ui/Modal';

interface ModalContext {
  isModalOpen: boolean;
  modalConfig: ModalProps['config'];
  openModal: () => void;
  closeModal: () => void;
  setModalConfig: (configuration: ModalProps['config']) => void;
}

const Context = createContext<ModalContext>(null);

export const useModal: () => ModalContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useModal must be used within a ModalProvider tag');
  }
  return contextState;
};

const ModalProvider: React.FC<{ children }> = ({ children }) => {
  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalProps['config']>({
    children: null,
  });

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Context.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        modalConfig,
        setModalConfig,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ModalProvider;
