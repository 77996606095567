import { Fragment, useRef, ReactElement } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import Icon from 'components/ui/Icon';

import styles from './AlertModal.module.css';

export interface Props {
  isOpen: boolean;
  close: () => void;
  config: {
    title?: string;
    message?: string | ReactElement;
    variant?: 'error' | 'success';
    btnText?: string;
    cancelText?: string | 'Cancel';
    onClick?: () => void;
  };
}

const AlertModal = ({ isOpen, close, config }: Props): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const { title, message, variant, btnText, cancelText, onClick } = config;

  let iconName = '';
  switch (variant) {
    case 'error':
      iconName = 'exclamation';
      break;
    case 'success':
      iconName = 'check-circle';
      break;
    default:
      iconName = null;
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-40 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen py-4 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={styles['alert-modal-container']}>
              <div className="sm:flex sm:items-start">
                <div className={`${styles['alert-modal-icon-holder']} ${styles[`bg-${variant}`]}`}>
                  <Icon
                    name={iconName}
                    className={`h-6 w-6 ${styles[`icon-${variant}`]}`}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  {title && (
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-500">
                      {title}
                    </Dialog.Title>
                  )}

                  {message && (
                    <div className="mt-2">
                      <p className="text-sm">{message}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {btnText && (
                  <button
                    type="button"
                    className={`${styles['alert-modal-action-btn']} ${styles[`btn-${variant}`]}`}
                    onClick={onClick}
                  >
                    {btnText}
                  </button>
                )}
                <button
                  type="button"
                  className={styles['alert-modal-cancel-btn']}
                  onClick={close}
                  ref={cancelButtonRef}
                >
                  {cancelText || 'Cancel'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AlertModal;
