import { gql } from 'graphql-request';

const productVariantFragment = gql`
  fragment innerProductFields on Product {
    universalKey
    content {
      analyticalConstituents
      checkpoints
      composition
      featuredImage {
        src
        alt
      }
      feedingGuidelinesImage {
        alt
        src
      }
      handle
      id
      images {
        src
        alt
      }
      occasionImage {
        alt
        src
      }
      ingredients {
        description
        image {
          alt
          src
        }
        key
        name
      }
      claims {
        key
        name
        description
      }
      noLongerAvailable
      nutritionalAdditives
      recipeAltImage {
        alt
        src
      }
      recipeDescription
      seo {
        title
        description
        image {
          src
          alt
        }
      }
      sticker {
        name
        backgroundHexColor
        displayText
        link
        openLinkInNewTab
        bannerActiveForCountries
        fontHexColor
      }
      technologicalAdditives
      title
      tracking
      universalKey
      energy
    }
  }

  fragment productVariantFields on ProductVariant {
    universalKey
    # ProductVariant actuals
    actuals {
      price {
        amount
        currencyCode
        formattedPrice
        locale
        compareAt
      }
      isInStock
    }

    # ProductVariant content
    content {
      isBundle
      featuredImage {
        alt
        src
      }
      id
      slug
      title
      tracking
      universalKey
      infoStickers {
        displayText
        backgroundHexColor
        fontHexColor
      }
      productBundle {
        count
        productVariant {
          universalKey
          content {
            universalKey
            title
            tracking
            featuredImage {
              src
              alt
            }
          }
          actuals {
            price {
              amount
              currencyCode
              formattedPrice
              locale
              compareAt
            }
          }
          product {
            universalKey
            masterData {
              ranges {
                key
              }
              dietaryPreferences {
                key
                translations {
                  languageCode
                  translation
                }
              }
              allergies {
                key
                translations {
                  languageCode
                  translation
                }
              }
            }

            content {
              handle
              universalKey
              title
              composition
              nutritionalAdditives
              energy
              analyticalConstituents
              technologicalAdditives
              images {
                src
                alt
              }
            }
          }
        }
      }
    }

    identifiers {
      eanCode
      shopifyProductId
      shopifyProductVariantId
    }
    product {
      ...innerProductFields
    }
  }
`;

export default productVariantFragment;
