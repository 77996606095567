import i18next from 'i18next';

/**
 * Helper utils to manually check for unused translations
 * This creates an object on window called unusedTranslations.
 * When a translation gets used, it will delete itself from the unusedTranslations object.
 */
const createUnusedTranslationsWindowObject = (localeLanguage: string): void => {
  try {
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' &&
      i18next.options.resources[localeLanguage]
    ) {
      globalThis.unusedTranslations = JSON.parse(
        JSON.stringify(i18next.options.resources[localeLanguage]),
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const deleteTranslationFromUnusedTranslationsWindowObject = (
  namespace: string,
  key: string,
): void => {
  try {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
      const ns = namespace || key.split(':')[0];
      const k = namespace ? key : key.split(':')[1];
      if (globalThis.unusedTranslations && globalThis.unusedTranslations[ns]) {
        delete globalThis.unusedTranslations[ns][k];
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export {
  createUnusedTranslationsWindowObject,
  deleteTranslationFromUnusedTranslationsWindowObject,
};
