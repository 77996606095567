const snowImages = [];
if (typeof window === 'object') {
  const snowflake = document?.createElement('img');
  snowflake.src = '/images/festive/snowflake.png';
  const snowflake2 = document?.createElement('img');
  snowflake2.src = '/images/festive/snow-2.png';
  snowImages.push(snowflake);
  snowImages.push(snowflake);
  snowImages.push(snowflake2);
}

// eslint-disable-next-line import/prefer-default-export
export { snowImages };
