import { useEffect, useState } from 'react';

import { z } from 'zod';

import { useCountryContext } from 'context/CountryContextProvider';
import { getClientSideCookie, setClientSideCookie } from 'services/session';
import { CookieNames } from 'utils/constants';

/* -------------------------------------------------------------------------- */
/*                                    Types                                   */
/* -------------------------------------------------------------------------- */

const cartPreferencesCookieSchema = z.object({
  isSubscription: z.boolean(),
  subscriptionFrequency: z.union([
    z.literal(28),
    z.literal(35),
    z.literal(42),
    z.literal(49),
    z.literal(56),
    z.literal(63),
    z.literal(70),
    z.literal(77),
    z.literal(84),
  ]),
  subscriptionPreferredDeliveryDay: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
    z.literal(6),
  ]),
});

type CartPreferences = z.infer<typeof cartPreferencesCookieSchema>;

/* -------------------------------------------------------------------------- */
/*                               Helper methods                               */
/* -------------------------------------------------------------------------- */

const getCartPreferencesFromCookie = (): CartPreferences | null => {
  let cartPreferences: z.infer<typeof cartPreferencesCookieSchema>;
  try {
    cartPreferences = cartPreferencesCookieSchema.parse(
      JSON.parse(getClientSideCookie(CookieNames.CartPreferences)),
    );
  } catch (e) {
    return null;
  }
  return cartPreferences;
};

const setCartPreferencesCookie = (data: CartPreferences) => {
  setClientSideCookie(
    CookieNames.CartPreferences,
    JSON.stringify({
      ...data,
    }),
  );
};

/* -------------------------------------------------------------------------- */
/*                                  Main hook                                 */
/* -------------------------------------------------------------------------- */

/**
 * @private Hook that returns the cart preferences and a function to update them
 */
const useCartPreferences = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cartPreferences, setCartPreferences] = useState<CartPreferences | null>(null);
  const { countryCode, isLoading: isLoadingCountryContext } = useCountryContext();

  /**
   * On initial load: get cart preferences from cookie
   */
  useEffect(() => {
    if (!isLoadingCountryContext) {
      setCartPreferences(
        getCartPreferencesFromCookie() ?? {
          isSubscription: false,
          subscriptionFrequency: 28,
          subscriptionPreferredDeliveryDay: ((c) => {
            switch (c) {
              case 'BE':
              case 'NL':
              case 'LU':
                return 4;
              case 'FR':
              case 'DE':
              case 'AT':
              case 'CH':
                return 5;
              default:
                return 1;
            }
          })(countryCode),
        },
      );
      setIsLoading(false);
    }
  }, [countryCode, isLoadingCountryContext]);

  /**
   * If CountryCode "OTHER" && cartPreferences.isSubscription
   * => set cartPreferences.isSubscription to false
   */
  useEffect(() => {
    if (cartPreferences?.isSubscription && countryCode.toUpperCase() === 'OTHER') {
      setCartPreferencesCookie({ ...cartPreferences, isSubscription: false });
      setCartPreferences({ ...cartPreferences, isSubscription: false });
    }
  }, [countryCode, cartPreferences]);

  return {
    isLoading,
    ...cartPreferences,
    setCartPreferences: (data: Partial<CartPreferences>) => {
      setCartPreferencesCookie({ ...cartPreferences, ...data });
      setCartPreferences({ ...cartPreferences, ...data });
    },
  };
};

export default useCartPreferences;
