interface ToastWrapperProps {
  children: React.ReactNode;
  isCartToastWrapper?: boolean;
}

/** 1. Wrapper accepts an isCartToastWrapper. Based on this prop we decide where to render the toasts.
 *  2. Existing toasts should keep their old rendering positions, cart specific toasts render in the page container.
 */
const ToastWrapper = ({ children, isCartToastWrapper }: ToastWrapperProps): JSX.Element => (
  <div
    aria-live="assertive"
    className={`${
      isCartToastWrapper
        ? 'fixed inset-0 flex items-start px-4 py-14 pointer-events-none sm:px-6 lg:pt-16 z-10 container'
        : 'fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-10'
    }`}
  >
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">{children}</div>
  </div>
);

export default ToastWrapper;
