import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

import Script from 'next/script';

import { getClientSideCookie } from 'services/session';
import { getCookieConsentCategoriesFromCookie } from 'services/session/cookies';
import { CookieNames } from 'utils/constants';

type AwinContext = {
  awc: string;
  source: string;
};

const Context = createContext<AwinContext>(null);

export const useAwin = (): AwinContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useAwin must be used within a AwinProvider tag');
  }
  return contextState;
};

/**
 * Provider that adds Awin tracking to the app
 * Awin is an affiliate marketing network
 * E&C contacts for more information: Laureen Mezger, Wouter Vanmaercke
 */
export const AwinProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isCookieConsentGiven, setIsCookieConsentGiven] = useState(false);
  const [awcValue, setAwcValue] = useState<string>(null);
  const [sourceValue, setSourceValue] = useState<string>(null);

  useEffect(() => {
    const recurrentlyCheckCookieConsent = () => {
      setTimeout(() => {
        if (getClientSideCookie(CookieNames.CookieConsent) != null) {
          if (getCookieConsentCategoriesFromCookie().marketing) {
            setIsCookieConsentGiven(true);
          }
        } else {
          recurrentlyCheckCookieConsent();
        }
      }, 2000);
    };

    recurrentlyCheckCookieConsent();
  }, []);

  useEffect(() => {
    /**
     * Helper functions written by Awin
     */

    const advertiserId = 100729;
    const cookieDomain = '.edgardcooper.com';

    function readCookiesAsString(awRegEx) {
      const aCookies = document.cookie.split(';');
      const cookies = [];
      for (let i = 0; i < aCookies.length; i += 1) {
        const aParts = aCookies[i].split('=');
        if (awRegEx.test(aParts[0])) {
          cookies.push(aParts[1]);
        }
      }
      return cookies.join(',');
    }

    function getAwc() {
      const rx = new RegExp('[?&]awc=([^&]+).*$');
      const returnVal = document.location.href.match(rx);

      if (returnVal !== null) {
        if (returnVal[1] && returnVal[1].match(/[0-9]+_[0-9]+_[a-zA-Z0-9]+/)) {
          return returnVal[1];
        }
      }
      // cookies
      const awcFromCookie = readCookiesAsString(/_aw_m_\d+/);
      const awcFromSnCookie = readCookiesAsString(/_aw_sn_\d+/);

      if (awcFromCookie.length > 0 && awcFromSnCookie.length > 0) {
        return `${awcFromCookie},${awcFromSnCookie}`;
      }
      if (awcFromCookie.length > 0) {
        return awcFromCookie;
      }
      if (awcFromSnCookie.length > 0) {
        return awcFromSnCookie;
      }

      return '';
    }

    function getSource() {
      const rx = new RegExp('[?&]source=([^&]+).*$');
      const returnVal = document.location.href.match(rx);

      if (returnVal !== null) {
        const validatedValue = returnVal[1].replace(/[^a-zA-Z0-9-_]/g, '');
        return validatedValue;
      }

      const sourceFromCookie = readCookiesAsString(/_aw_channel$/);

      if (sourceFromCookie.length > 0) {
        return sourceFromCookie;
      }

      return '';
    }

    // Set cookie for web pixel
    function setPxCookie(awc) {
      const cookieName = `_awc_${advertiserId}`;
      const expires = new Date();
      expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);

      document.cookie = `${cookieName}=${awc};expires=${expires.toUTCString()};path=/;domain=${cookieDomain}`;
    }

    function setSourceCookie(source, expiresOverrideDate?) {
      const cookieName = '_aw_channel';
      const expires = new Date();
      if (expiresOverrideDate) {
        expires.setTime(expiresOverrideDate.getTime() + 365 * 24 * 60 * 60 * 1000);
      } else {
        expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
      }

      const cookieValue =
        source.indexOf('|') !== -1 ? source : `${source}|${Math.round(expires.getTime() / 1000)}`;

      document.cookie = `${cookieName}=${cookieValue};expires=${expires.toUTCString()};path=/;domain=${cookieDomain}`;
    }

    const awc = getAwc();
    const fullSourceValue = getSource();

    if (awc !== '') {
      setAwcValue(awc);
      setPxCookie(awc);
    }

    if (fullSourceValue !== '') {
      setSourceValue(fullSourceValue.split('|')[0]);
      if (fullSourceValue.indexOf('|') !== -1) {
        // refresh from cookie
        const sourceParts = fullSourceValue.split('|');
        const sourceDate = new Date(parseInt(sourceParts[1], 10) * 1000);
        setSourceCookie(fullSourceValue, sourceDate);
      } else {
        // set newly from qs
        setSourceCookie(fullSourceValue);
      }
    }
  }, []);

  return (
    <>
      {isCookieConsentGiven && (
        <Script id="init-awin" async defer src="https://www.dwin1.com/100729.js" />
      )}
      <Context.Provider
        value={{
          awc: awcValue,
          source: sourceValue,
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
};
