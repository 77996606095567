import { createContext, useContext, useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { Keys, QueryKeys } from 'api/client/QueryKeys';
import { useCountryContext } from 'context/CountryContextProvider';
import { removeClientSideCookie } from 'services/session/cookies';
import type { storefrontSDK } from 'services/shopify/storefront';
import { CookieNames } from 'utils/constants';

import useBuyerIdentity from './_hooks/useBuyerIdentity';
import useCartActions, { CartItemType } from './_hooks/useCartActions';
import useCartPreferences from './_hooks/useCartPreferences';
import useCartStates from './_hooks/useCartStates';
import useCartTracking from './_hooks/useCartTracking';
import useExtendShopifyCart from './_hooks/useExtendShopifyCart';
import useFetchCartFromShopify from './_hooks/useFetchCartFromShopify';
import useProductsInSpotlight from './_hooks/useProductsInSpotlight';
import useUpdateDiscountCodeFromUrl from './_hooks/useUpdateDiscountCodeFromUrl';

export type ShopifyCart = Awaited<ReturnType<typeof storefrontSDK.getCart>>['cart'];

export type ExtendedShopifyCart = Awaited<
  ReturnType<Awaited<ReturnType<typeof useExtendShopifyCart>>>
>;

export type { CartItemType };

/**
 * Context & Hook
 */
interface ShopifyCartContext {
  cart: ExtendedShopifyCart;
  cartActions: ReturnType<typeof useCartActions>;
  cartPreferences: ReturnType<typeof useCartPreferences>;
  cartStates: ReturnType<typeof useCartStates>;
  productsInSpotlight: ReturnType<typeof useProductsInSpotlight>;
}

const Context = createContext<ShopifyCartContext>(null);

export const useCart = (): ShopifyCartContext => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error('useCart must be used within a CartProvider tag');
  }
  return contextState;
};

const ShopifyCartProvider = ({ children }): JSX.Element => {
  const { locale } = useRouter();
  const { cookieCountryCode: countryCode, isLoading: isLoadingCountryContext } =
    useCountryContext();

  const cartPreferences = useCartPreferences();
  const fetchCartFromShopify = useFetchCartFromShopify();
  const extendShopifyCart = useExtendShopifyCart();
  const cartStates = useCartStates();
  const cartTracking = useCartTracking();

  // Main method to be used with react-query
  const fetchCart = async () => {
    const extendedCart = await fetchCartFromShopify().then((shopifyCart) =>
      extendShopifyCart(shopifyCart),
    );

    Sentry.setContext('cart', {
      cart: JSON.stringify(extendedCart),
    });

    return extendedCart;
  };
  /**
   * Use react-query to fetch / manage cart data
   */
  const { data: cart, isLoading } = useQuery(
    QueryKeys[Keys.Cart](countryCode, locale),
    () => fetchCart(),
    {
      staleTime: Infinity,
      retry: 3,
      retryDelay: 1000,
      onError: (err) => {
        /**
         * This is a very rare case as fetching the cart gets retried 3 times
         * + the storefront graphql calls themselves are also retried 3 times
         *
         * So in theory, this should almost never occur, unless something is completely broken.
         * If it does occur, we need to handle the exported isError property better and show messages to the user.
         * I wrote some test code and right now if there's an error it will show an empty cart sidebar or crash the cart page.
         * */
        const error = new Error(`Error fetching cart ${err}`, { cause: err });
        Sentry.captureException(error);
        cartStates.setIsCartError(true);
        cartStates.setIsCartLoading(false);
      },
      enabled: !isLoadingCountryContext,
      refetchOnWindowFocus: 'always',
    },
  );

  const cartActions = useCartActions(cart, cartStates, extendShopifyCart, cartTracking);

  const productsInSpotlight = useProductsInSpotlight(cartPreferences, cart);

  const { isBuyerIdentityUpdated } = useBuyerIdentity(cart, cartStates, extendShopifyCart);

  useUpdateDiscountCodeFromUrl(cart, cartStates, extendShopifyCart, isBuyerIdentityUpdated);

  /**
   * In rare cases: we have a preview product in the cart and/or the product is deleted from the store, or just not available then we clear the old invalid cookie and the cart.
   * Reproducible by 1. add a preview item to the cart 2. exit preview 3. the cart is now undefined and the cart is not cleared of that preview product -> cartGlobalError occurs
   */
  useEffect(() => {
    if (!cartStates.isCartLoading && !isLoading && cart === undefined) {
      // If after loading the cart is still undefined -> clear cart and cookies
      removeClientSideCookie(CookieNames.CartId);
      cartActions.clearCart();
    }
  }, [cart, cartActions, cartStates, isLoading]);
  /**
   * Set loading state to false when cart is available
   * Normally we would look at the react-query isLoading property,
   * But somehow it isn't working as expected.
   */
  useEffect(() => {
    if (cartStates.isCartLoading && cart) {
      cartStates.setIsCartLoading(false);
    }
  }, [cartStates, cart]);

  return (
    <Context.Provider
      value={{
        cart,
        cartStates,
        cartActions,
        cartPreferences,
        productsInSpotlight,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ShopifyCartProvider;
