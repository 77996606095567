import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import Image from 'next/image';
import { Snowfall } from 'react-snowfall';
import SwiperType from 'swiper';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StickerBanner as StickerBannerType } from 'api/generated-types';
import Sticker from 'components/parts/Sticker';
import ECImage, { SrcOptions } from 'components/ui/ECImage';
import { snowImages } from 'utils/festiveUtils';
import useViewport from 'utils/hooks/useViewport';

import styles from './GalleryLightBox.module.css';
import Icon from '../Icon';
import ThumbnailBar from '../ThumbnailBar/ThumnailBar';

import 'swiper/css/bundle';

export interface GalleryEntry {
  src: string;
  alt: string;
  thumbSrc: string;
  srcOptions?: SrcOptions;
  thumbSrcOptions?: SrcOptions;
}

export interface Props {
  entries: GalleryEntry[];
  defaultEntry?: GalleryEntry;
  ageLabel?: string;
  sticker?: StickerBannerType;
  festive?: boolean;
}

const Gallery: React.FC<Props> = ({ entries, defaultEntry, ageLabel, sticker, festive }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [activeEntryIndex, setActiveEntryIndex] = useState<number>(
    defaultEntry || entries.length ? 0 : null,
  );

  const { isMobile } = useViewport();
  const singleEntry = entries.length === 1;

  const lightGalleryImgRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    if (defaultEntry && entries?.length > 0) {
      setActiveEntryIndex(entries.indexOf(defaultEntry) >= 0 ? entries.indexOf(defaultEntry) : 0);
    }
  }, [defaultEntry, entries]);

  const onSlideChanged = useCallback((index: number) => {
    setActiveEntryIndex(index);
  }, []);

  useEffect(() => {
    if (activeEntryIndex) {
      if (onSlideChanged) {
        onSlideChanged(activeEntryIndex);
      }
    }
  }, [activeEntryIndex, onSlideChanged, entries]);

  useEffect(() => {
    if (swiper && activeEntryIndex !== null) {
      swiper?.slideToLoop?.(activeEntryIndex);
    }
  }, [swiper, activeEntryIndex]);

  if (!entries.length) {
    return <></>;
  }

  return (
    <div className="sticky md:top-24 lg:top-8 xl:top-20">
      {/* turquoise square frame for gallery */}
      {festive && (
        <>
          <Image
            src="/images/festive/snowcorner.svg"
            alt="snow"
            className="absolute w-[94px] hidden lg:flex left-0 top-0 z-50 -translate-x-[11%] -translate-y-[17%] pointer-events-none"
            width={94}
            height={84}
          />
        </>
      )}
      <div className="bg-cyan-400 flex items-center justify-center lg:justify-end lg:rounded-2xl aspect-1 relative overflow-hidden select-none group">
        <div
          className={classNames(
            'absolute right-4 top-4 flex flex-row-reverse flex-gap-x-2 flex-gap-y-1 items-center z-[99] transition pointer-events-none',
            activeEntryIndex === 0 ? 'opacity-100' : 'opacity-0',
          )}
        >
          {ageLabel && (
            <Sticker
              sticker={{
                displayText: ageLabel,
                backgroundHexColor: '#ecf8f8',
                fontHexColor: '#007377',
                name: 'ageLabel',
                // Not needed, but is required for type
                bannerActiveForCountries: [],
              }}
            />
          )}
          {sticker && <Sticker sticker={sticker} />}
        </div>

        {
          // hide nav arrows on mobile
          !isMobile && !singleEntry && (
            <>
              {/* right arrow */}
              <button
                // eslint-disable-next-line max-len
                className="flex w-8 h-8 items-center justify-center rounded-full absolute top-1/2 transform -translate-y-1/2 z-20 cursor-pointer transition bg-white text-blue-400 opacity-20 group-hover:opacity-80 hover:!opacity-100 mr-8 right-0"
                type="button"
                onClick={() => {
                  setActiveEntryIndex((prevIndex) =>
                    prevIndex + 1 <= entries.length - 1 ? prevIndex + 1 : 0,
                  );
                }}
              >
                <Icon name="chevron-right" />
              </button>
              {/* left arrow */}
              <button
                // eslint-disable-next-line max-len
                className="flex w-8 h-8 items-center justify-center rounded-full absolute top-1/2 transform -translate-y-1/2 z-20 cursor-pointer transition bg-white text-blue-400 opacity-20 group-hover:opacity-80 hover:!opacity-100 ml-8 left-0"
                type="button"
                onClick={() => {
                  setActiveEntryIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : entries.length - 1,
                  );
                }}
              >
                <Icon name="chevron-left" />
              </button>
            </>
          )
        }
        <div className="mx-auto w-full aspect-1">
          <div className="relative z-10 flex items-center h-full">
            <div className="flex flex-col h-full w-full items-center justify-center">
              <div className="mx-auto w-full">
                <Swiper
                  modules={[Pagination]}
                  onSwiper={(newSwiper) => {
                    setSwiper(newSwiper);
                  }}
                  rewind
                  slidesPerView={1}
                  spaceBetween={32}
                  onRealIndexChange={(s) => {
                    setActiveEntryIndex(s.realIndex);
                  }}
                  pagination
                  onClick={() => {
                    lightGalleryImgRefs.current[activeEntryIndex]?.click();
                  }}
                  className={classNames('cursor-zoom-in z-50', styles.swiper)}
                >
                  {festive && (
                    <Snowfall
                      color="#fff"
                      snowflakeCount={20}
                      radius={[8, 16.0]}
                      speed={[0.5, 1]}
                      wind={[-0.5, 0.5]}
                      style={{ zIndex: 1 }}
                      images={snowImages}
                    />
                  )}
                  {entries.map((entry, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <SwiperSlide key={`${entry.src}${index}`} className="p-4">
                      <>
                        <ECImage
                          key={entry?.src}
                          img={entry}
                          srcOptions={{ w: 1096, q: 85 }}
                          className="object-contain w-full rounded md:rounded-md"
                          pictureClassName="block aspect-w-1 aspect-h-1 w-full"
                        />
                        {festive && (
                          <div className="absolute inset-0 bg-gradient-radial from-[#C4ECEC] to-cyan-400 overflow-hidden rounded-2xl -z-30" />
                        )}
                      </>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <LightGallery
                  plugins={[lgZoom]}
                  mobileSettings={{ controls: false, showCloseIcon: true, download: false }}
                  addClass="ec-lightbox"
                  zoom
                  licenseKey={process.env.NEXT_PUBLIC_LIGHTGALLERY_LICENSE_TOKEN}
                >
                  {entries.map((entry, index) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${entry.src}${index}`}
                      data-src={entry?.src}
                      className={classNames(
                        'pointer-events-none',
                        (index !== activeEntryIndex ||
                          // for testing
                          entry !== entries[activeEntryIndex]) &&
                          // ---
                          'sr-only',
                      )}
                      ref={(element) => {
                        lightGalleryImgRefs.current[index] = element;
                      }}
                    />
                  ))}
                </LightGallery>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* thumbnails */}
      <div className="mt-4 maxLg:container overflow-hidden">
        <ThumbnailBar
          images={entries}
          activeImageIndex={activeEntryIndex}
          setActiveImageIndex={setActiveEntryIndex}
          onClick={(index) => {
            setActiveEntryIndex(index);
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(Gallery);
