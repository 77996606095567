import React, { forwardRef } from 'react';

import classNames from 'classnames';

import styles from './RadioButton.module.css';
import Choice from '../Choice/index';

export interface Props {
  /** Radio button is selected */
  checked?: boolean;
  /** Radio button is selected by default */
  defaultChecked?: boolean;
  /** Disable input */
  disabled?: boolean;
  /** Readonly input */
  readOnly?: boolean;
  /** Display an error message */
  error?: string | boolean;
  /** Additional text to aid in use */
  helpText?: React.ReactNode;
  /** ID for form input */
  id?: string;
  /** Label for the radio button */
  label?: string | React.ReactElement;
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Name for form input */
  name?: string;
  /** Value for form input */
  value?: string;
  /** Additional class names */
  className?: string;
  /** Callback when checkbox is toggled */
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  /** Callback when checkbox is focussed */
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  /** Callback when focus is removed */
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const RadioButton = (
  {
    checked,
    defaultChecked,
    disabled,
    readOnly,
    helpText,
    id,
    label,
    labelHidden,
    name,
    value,
    error,
    onChange,
    onFocus,
    onBlur,
    className: additionalClassName,
    ...additionalProps
  }: Props,
  ref,
) => {
  const className = classNames(
    styles['radio-button'],
    disabled && styles['radio-button--disabled'],
    additionalClassName,
  );

  return (
    <Choice
      disabled={disabled}
      helpText={helpText}
      id={id}
      label={label}
      labelHidden={labelHidden}
      error={error}
    >
      <input
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        readOnly={readOnly}
        id={id}
        name={name}
        type="radio"
        value={value}
        className={className}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        {...additionalProps}
      />
    </Choice>
  );
};

export default forwardRef<HTMLInputElement, Props>(RadioButton);
