import Link from 'next/link';

import { BlogCategory } from 'services/contentful/generated-types';

import styles from './blogCategoryCard.module.css';
import ECImage from '../ECImage';

export interface BlogCategoryCardProps {
  blogCategory: BlogCategory;
}

const BlogCategoryCard = ({
  blogCategory: { name, color, slug, image },
}: BlogCategoryCardProps): JSX.Element => {
  const href = `/blog/category/${slug}/`;

  return (
    <div
      className={`rounded p-2 md:p-6 flex flex-col items-center
    transition transform hover:-translate-y-1
    ${styles.blogCategoryCard}`}
      style={{ background: color }}
    >
      <div className="w-24 md:w-full mb-8 mt-8">
        <figure className="relative aspect-w-1 aspect-h-1">
          <ECImage
            img={{
              src: image?.url || '',
              alt: image?.description,
            }}
            className="absolute w-full h-full object-contain"
            srcOptions={{ w: 348, q: 90 }}
          />
        </figure>
      </div>
      <div className="mb-4 md:text-center">
        <div>
          <Link href={href} className="text-white md:text-2xl">
            {name}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogCategoryCard;
