/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import { Image } from 'api/generated-types';

import ECImage from '../ECImage';

type Props = {
  images: Image[];
  activeImageIndex: number;
  setActiveImageIndex: (index: number) => void;
  onClick: (index: number) => void;
  className?: string;
};
const ThumbnailBar: React.FC<Props> = ({
  images,
  activeImageIndex,
  setActiveImageIndex,
  onClick,
  className: addedClassName = '',
}) => {
  /** Determines is user swiped or clicked, which mode to follow */
  const [swipeOrClick, setSwipeOrClick] = useState<'swipe' | 'click'>('click');
  const [alreadySwipedX, setAlreadySwipedX] = useState(0);
  const [currentSwipeX, setCurrentSwipeX] = useState(0);
  /** Get thumb size */
  const selectedThumbRef = useRef<HTMLDivElement>();
  const imageWidth = selectedThumbRef?.current?.offsetWidth;

  /** Get margin-right size */
  const gapSizeInPixels = selectedThumbRef?.current?.style?.marginRight
    ? parseInt(selectedThumbRef?.current?.style?.marginRight, 10)
    : 12;

  /** Get thumbBar outerDiv width  */
  const outerDivRef = useRef<HTMLDivElement>();
  const thumbBarOuterDivWidth = outerDivRef.current?.offsetWidth ?? 0;

  /** -------------- Calculations for determining translateX value based on selected image -------------- */

  const imagesLength = images.length;

  const imageWidthWithGapSize = imageWidth + gapSizeInPixels;

  const thumbBarInnerDivWidth = imageWidthWithGapSize * imagesLength - gapSizeInPixels;

  const minTranslationX = -(thumbBarInnerDivWidth - thumbBarOuterDivWidth);
  const maxTranslationX = 0;

  const fromStartOfThumbsOuterDivToCenterOfSelectedThumb = thumbBarOuterDivWidth / 2;

  const fromStartOfThumbsInnerDivToCenterOfSelectedThumb =
    activeImageIndex * imageWidthWithGapSize + imageWidth / 2;

  /** Calculates how many pixels the thumbs should translate to the left to get into the center of the outer div
   * based on the index given and taking into account min and max */
  const translationXWithoutBoundaries =
    -(
      fromStartOfThumbsInnerDivToCenterOfSelectedThumb -
      fromStartOfThumbsOuterDivToCenterOfSelectedThumb
    ) +
    alreadySwipedX +
    currentSwipeX;

  const translationX = Math.max(
    minTranslationX,
    Math.min(translationXWithoutBoundaries, maxTranslationX),
  );

  const swipeHandlers = useSwipeable({
    onSwiping: (e) => {
      // When swiping, we set the mode to swipe and set the current swipe value
      // This value will be used to calculate the new translationX value (along with alreadySwipedX)
      setSwipeOrClick('swipe');
      setCurrentSwipeX(e.deltaX);
    },
    onSwiped: (e) => {
      // timeout necessary to be able to cancel the onClick when swiped (see below)
      // This basically means the statements will be triggered on the next render cycle, in stead of immediately
      setTimeout(() => {
        setAlreadySwipedX((prevAlreadySwipedX) => {
          // basic calculation
          let newValue = prevAlreadySwipedX + e.deltaX;
          // necessary for boundary checks below
          const whatWillBeNewTranslationX =
            -(
              fromStartOfThumbsInnerDivToCenterOfSelectedThumb -
              fromStartOfThumbsOuterDivToCenterOfSelectedThumb
            ) + newValue;

          // This code is necessary to prevent the user from swiping too far to the left or right
          // Without these if checks, the alreadySwipedX value could be too high/low and the user would not be able to swipe back
          if (whatWillBeNewTranslationX > maxTranslationX) {
            newValue =
              maxTranslationX +
              (fromStartOfThumbsInnerDivToCenterOfSelectedThumb -
                fromStartOfThumbsOuterDivToCenterOfSelectedThumb);
          }
          if (whatWillBeNewTranslationX < minTranslationX) {
            newValue =
              minTranslationX +
              (fromStartOfThumbsInnerDivToCenterOfSelectedThumb -
                fromStartOfThumbsOuterDivToCenterOfSelectedThumb);
          }
          return newValue;
        });
        setCurrentSwipeX(0);
      }, 0);
    },
    trackMouse: true,
    preventScrollOnSwipe: false,
  });

  const hasOverflowingThumbs = thumbBarInnerDivWidth - gapSizeInPixels > thumbBarOuterDivWidth;

  useEffect(() => {
    // Necessary when changed outside of this component
    // Basically: move mode to click and go to correct activeImageIndex => reset to new position
    setSwipeOrClick('click');
    setAlreadySwipedX(0);
    setCurrentSwipeX(0);
  }, [activeImageIndex]);

  return (
    <div className="relative select-none">
      {/* shadow divs */}
      {/* // only shadow left if selected thumb aligned right */}
      <div
        className={`absolute top-0 left-0 w-20 h-full z-[99999] bg-gradient-to-l from-transparent to-brown-100 pointer-events-none ${
          translationX < maxTranslationX ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-500`}
      />
      {/* // only shadow right if selected thumb aligned left */}
      <div
        className={`absolute top-0 right-0 w-20 h-full z-[99999] bg-gradient-to-r from-transparent to-brown-100 pointer-events-none ${
          translationX > minTranslationX && hasOverflowingThumbs ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-500`}
      />
      <div
        className={classNames('relative z-0 overflow-hidden', addedClassName)}
        {...swipeHandlers}
        ref={(el) => {
          // call useSwipeable ref prop with el
          swipeHandlers.ref(el);

          // set myRef el so you can access it yourself
          outerDivRef.current = el;
        }}
      >
        <div
          className={`flex transition-transform ${
            swipeOrClick === 'swipe' ? 'duration-0' : 'duration-300'
          } ease-in-out`}
          style={{
            transform: `translateX(${hasOverflowingThumbs ? translationX : 0}px)`,
          }}
        >
          {images.map((image, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${image.src}-${index}-thumb`}
              onClick={() => {
                if (currentSwipeX === 0) {
                  // only trigger click if not currently swiping
                  onClick(index);
                  setSwipeOrClick('click');
                  setActiveImageIndex(index);
                  setAlreadySwipedX(0);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onClick(index);
                  setSwipeOrClick('click');
                  setActiveImageIndex(index);
                  setAlreadySwipedX(0);
                }
              }}
              role="button"
              tabIndex={index}
              className="flex mr-3 justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0 rounded-lg bg-cyan-400 select-none
              focus:outline-2 focus:-outline-offset-2 focus:outline-purple-700"
              ref={index === activeImageIndex ? selectedThumbRef : null}
            >
              <ECImage
                img={image}
                pictureClassName={`flex w-16 h-16 md:w-20 md:h-20 flex-col justify-center items-center pointer-events-none border-3 rounded md:rounded-md p-0 overflow-hidden ${
                  index === activeImageIndex ? `border-white` : 'border-cyan-400'
                }`}
                className="max-h-full md:rounded"
                srcOptions={{ w: 160 }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThumbnailBar;
