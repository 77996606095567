import classNames from 'classnames';

import styles from './CountryFlag.module.css';

interface Props {
  countryCode: string;
  className?: string;
}

const CountryFlag: React.FC<Props> = ({ countryCode = 'BE', className: additionalClassName }) => (
  <div
    className={classNames(
      styles.flag,
      styles[`flag-${countryCode?.toUpperCase()}`],
      additionalClassName,
    )}
  />
);

export default CountryFlag;
