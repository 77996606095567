module.exports = {
  white: '#ffffff',
  black: '#000000',
  gray: {
    50: '#F5F5F5',
    75: '#D9D9D9',
    100: '#d4d4d3',
    125: '#cac9c8',
    200: '#aaa8a7',
    300: '#7f7d7b',
    400: '#4e4b48',
    450: '#4E6A74',
    500: '#384d5d',
  },
  cyan: {
    200: '#ecf8f8',
    400: '#64CCC9',
  },
  blue: {
    50: '#eff6ff',
    200: '#e7eeef',
    300: '#82abac',
    400: '#007377',
    500: '#074b4e',
  },
  purple: {
    200: '#e9e1eb',
    300: '#dcc1e5',
    350: '#BFA1FF',
    400: '#b884cb',
    // Cat
    500: '#b399f7',
    550: '#9C7BF4',
    600: '#642667',
    // Cat
    700: '#4F006D',
    // Digital sprint (750 and 800)
    750: '#3A2182',
    800: '#271267',
  },
  pink: {
    200: '#fce8f4',
    400: '#df1995',
    500: '#b40e76',
  },
  green: {
    50: '#ecfdf5',
    200: '#e7f3c9',
    300: '#d5eaa0',
    // Digital sprint (350)
    350: '#9EE18C',
    400: '#a4d233',
    500: '#80b00a',
    550: '#56c776',
    575: '#42b362',
    600: '#36573b',
    trustpilot: '#00B67A',
  },
  yellow: {
    400: '#d9e249',
    500: '#ced645',
    // Cat
    600: '#fedd00',
  },
  orange: {
    100: '#F4ECE9',
    200: '#FFE2A9',
    400: '#f4973c',
  },
  brown: {
    // Digital sprint (50)
    50: '#FCF7F5',
    // Cat
    100: '#f5efef',
    // Digital sprint (200)
    200: '#F9F0EB',
    400: '#e8e1d7',
  },
  red: {
    50: '#fef2f2',
    100: '#fee2e2',
    300: '#ff9e9e',
    400: '#f86767',
    500: '#b84646',
  },
  steelBlue: {
    100: '#F6F8F8',
    200: '#E0E9EB',
  },
  grassGreen: '#65C24C',
  goldenYellow: '#E1E000',
};
