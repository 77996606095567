import Link from 'next/link';

import { Article } from 'services/contentful/generated-types';
import getArticleExcerpt from 'services/contentful/utils/blog/getArticleExcerpt';
import { useTranslations } from 'services/translations/TranslationsProvider';

import BlogCategoryLink from '../BlogCategoryLink';
import Button from '../Button';
import ECImage from '../ECImage';
import Icon from '../Icon';
import Screen from '../Screen';

export interface BlogCategory {
  name: string;
  color: string;
  href: string;
}

export interface ArticleCardProps {
  article: Article;
  locale: string;
  hideImage?: boolean;
}

const ArticleCard = ({
  article: { mainCategory, date, title, introduction, image, slug },
  locale,
  hideImage,
}: ArticleCardProps): JSX.Element => {
  const { t } = useTranslations('page.blog.common');
  const href = `/blog/${slug}/`;

  return (
    <article>
      <div
        className="bg-white flex flex-col xs:flex-row lg:flex-col h-full xs:items-center lg:items-start
      rounded overflow-hidden p-4 lg:p-0"
      >
        <div className="flex flex-col lg:h-full items-start lg:p-8">
          <div className="w-full lg:flex items-center justify-between mb-4 lg:mb-10">
            {mainCategory && (
              <BlogCategoryLink
                name={mainCategory.name}
                color={mainCategory.color}
                href={`/blog/category/${mainCategory.slug}/`}
                className="mr-4 mb-2 xs:mb-0"
              />
            )}
            {date && (
              <time dateTime={date} className="text-gray-100 text-base block mt-6 lg:mt-0">
                {new Date(date).toLocaleDateString(locale, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </time>
            )}
          </div>
          <Link href={href} className="heading-3 maxMd:text-lg">
            {title}
          </Link>
          <div className="block lg:grow">
            <Screen>
              <p className="text-base text-gray-300 font-barlow mt-2">
                {getArticleExcerpt(introduction)}
              </p>
            </Screen>
          </div>
          <Link href={href} passHref legacyBehavior>
            <Button legacy color="cyan" className="mt-6">
              {t('page.blog.common:continueReading')}
              <Icon name="arrow-right" className="w-4 ml-4" />
            </Button>
          </Link>
        </div>
        {!hideImage && (
          <div className="order-first xs:order-last lg:order-first shrink-0 ml-auto mb-4 xs:mb-0">
            <ECImage
              img={{
                src: image?.url || '',
                alt: image?.description,
              }}
              className="w-24 h-24 md:w-32 md:h-32 lg:w-full lg:h-full object-cover"
              srcOptions={{ w: 742, q: 80 }}
            />
          </div>
        )}
      </div>
    </article>
  );
};

export default ArticleCard;
