interface DividerProps {
  className?: string;
  showText?: boolean;
  text?: string;
}

const Divider = ({ className, showText, text }: DividerProps) => (
  <div className={`relative mb-8 ${className ?? ''}`}>
    <hr />
    {showText && (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-2 px-3 flex
    items-center justify-center bg-white text-gray-200"
      >
        {text}
      </div>
    )}
  </div>
);

export default Divider;
