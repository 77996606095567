/*
 * Upstream source: https://wiki.openstreetmap.org/wiki/Nominatim/Country_Codes
 */
const countryToLanguageMapping = {
  ad: 'ca',
  ae: 'ar',
  af: 'fa,ps',
  ag: 'en',
  ai: 'en',
  al: 'sq',
  am: 'hy',
  an: 'nl,en',
  ao: 'pt',
  ar: 'es',
  as: 'en,sm',
  at: 'de',
  au: 'en',
  aw: 'nl,pap',
  ax: 'sv',
  ba: 'bs,hr,sr',
  bb: 'en',
  bd: 'bn',
  be: 'nl,fr,de',
  bf: 'fr',
  bh: 'ar',
  bi: 'fr',
  bj: 'fr',
  bl: 'fr',
  bm: 'en',
  bn: 'ms',
  bo: 'es,qu,ay',
  br: 'pt',
  bs: 'en',
  bt: 'dz',
  bv: 'no',
  bw: 'en,tn',
  by: 'be,ru',
  bz: 'en',
  ca: 'en,fr',
  cc: 'en',
  cd: 'fr',
  cf: 'fr',
  cg: 'fr',
  ch: 'de,fr,it,rm',
  ci: 'fr',
  ck: 'en,rar',
  cl: 'es',
  cm: 'fr,en',
  cn: 'zh',
  co: 'es',
  cr: 'es',
  cu: 'es',
  cv: 'pt',
  cx: 'en',
  cy: 'el,tr',
  cz: 'cs',
  dj: 'fr,ar,so',
  dk: 'da',
  dm: 'en',
  do: 'es',
  dz: 'ar',
  ec: 'es',
  ee: 'et',
  eg: 'ar',
  eh: 'ar,es,fr',
  er: 'ti,ar,en',
  es: 'es,ca,eu',
  et: 'am,om',
  fi: 'fi,sv,se',
  fj: 'en',
  fk: 'en',
  fm: 'en',
  ga: 'fr',
  gb: 'en',
  gd: 'en',
  ge: 'ka',
  gf: 'fr',
  gg: 'en',
  gh: 'en',
  gi: 'en',
  gl: 'kl,da',
  gm: 'en',
  gn: 'fr',
  gp: 'fr',
  gq: 'es,fr,pt',
  gr: 'el',
  gs: 'en',
  gt: 'es',
  gu: 'en,ch',
  gw: 'pt',
  gy: 'en',
  hk: 'zh,en',
  hm: 'en',
  hn: 'es',
  ht: 'fr,ht',
  ie: 'en,ga',
  il: 'he',
  im: 'en',
  in: 'hi,en',
  io: 'en',
  iq: 'ar,ku',
  ir: 'fa',
  it: 'it',
  je: 'en',
  jm: 'en',
  jo: 'ar',
  jp: 'ja',
  ke: 'sw,en',
  kg: 'ky,ru',
  kh: 'km',
  ki: 'en',
  km: 'ar,fr',
  kn: 'en',
  kp: 'ko',
  kr: 'ko,en',
  kw: 'ar',
  ky: 'en',
  kz: 'kk,ru',
  la: 'lo',
  lb: 'ar,fr',
  lc: 'en',
  li: 'de',
  lk: 'si,ta',
  lr: 'en',
  ls: 'en,st',
  lu: 'lb,fr,de',
  ly: 'ar',
  ma: 'ar',
  mc: 'fr',
  md: 'ru,uk,ro',
  me: 'srp,sq,bs,hr,sr',
  mf: 'fr',
  mg: 'mg,fr',
  mh: 'en,mh',
  ml: 'fr',
  mm: 'my',
  mo: 'zh,pt',
  mp: 'ch',
  mq: 'fr',
  mr: 'ar,fr',
  ms: 'en',
  mt: 'mt,en',
  mu: 'mfe,fr,en',
  mv: 'dv',
  mw: 'en,ny',
  mx: 'es',
  my: 'ms',
  mz: 'pt',
  na: 'en,sf,de',
  nc: 'fr',
  ne: 'fr',
  nf: 'en,pih',
  ng: 'en',
  ni: 'es',
  no: 'nb,nn,no,se',
  np: 'ne',
  nr: 'na,en',
  nu: 'niu,en',
  nz: 'mi,en',
  om: 'ar',
  pa: 'es',
  pe: 'es',
  pf: 'fr',
  pg: 'en,tpi,ho',
  ph: 'en,tl',
  pk: 'en,ur',
  pm: 'fr',
  pn: 'en,pih',
  pr: 'es,en',
  ps: 'ar,he',
  pw: 'en,pau,ja,sov,tox',
  py: 'es,gn',
  qa: 'ar',
  re: 'fr',
  rs: 'sr',
  rw: 'rw,fr,en',
  sa: 'ar',
  sb: 'en',
  sc: 'fr,en,crs',
  sd: 'ar,en',
  se: 'sv',
  sg: 'en,ms,zh,ta',
  sh: 'en',
  si: 'sl',
  sj: 'no',
  sl: 'en',
  sm: 'it',
  sn: 'fr',
  so: 'so,ar',
  sr: 'nl',
  st: 'pt',
  ss: 'en',
  sv: 'es',
  sy: 'ar',
  sz: 'en,ss',
  tc: 'en',
  td: 'fr,ar',
  tf: 'fr',
  tg: 'fr',
  tj: 'tg,ru',
  tk: 'tkl,en,sm',
  tl: 'pt,tet',
  tm: 'tk',
  tn: 'ar',
  to: 'en',
  tt: 'en',
  tv: 'en',
  tw: 'zh',
  tz: 'sw,en',
  ua: 'uk',
  ug: 'en,sw',
  um: 'en',
  us: 'en',
  uy: 'es',
  uz: 'uz,kaa',
  va: 'it',
  vc: 'en',
  ve: 'es',
  vg: 'en',
  vi: 'en',
  vn: 'vi',
  vu: 'bi,en,fr',
  wf: 'fr',
  ws: 'sm,en',
  ye: 'ar',
  yt: 'fr',
  za: 'zu,xh,af,st,tn,en',
  zm: 'en',
  zw: 'en,sn,nd',
};

const mapCountryToLanguages = (countryCode: string): [string] => {
  if (typeof countryToLanguageMapping[countryCode] !== 'undefined') {
    return countryToLanguageMapping[countryCode].split(',');
  }

  return [countryCode];
};

export default mapCountryToLanguages;
