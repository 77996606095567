// nProgress.ts

import { Router } from 'next/router';
import nProgress from 'nprogress';

/** @description YouTube-style loading bar to indicate page is loading
 * @link https://ricostacruz.com/nprogress/ | https://www.npmjs.com/package/nprogress
 */
// eslint-disable-next-line import/prefer-default-export
export const mountNProgress = () => {
  nProgress.configure({
    showSpinner: false,
    trickleSpeed: 80,
  });

  Router.events.on('routeChangeStart', () => nProgress.start());
  Router.events.on('routeChangeComplete', () => nProgress.done());
  Router.events.on('routeChangeError', () => nProgress.done());
};
