import { useState } from 'react';

import { useRouter } from 'next/router';

import { CountryWithProvinces } from 'api/generated-types';
import { CustomSelect, Button, Card, Spinner } from 'components/ui';
import { useCountryContext } from 'context/CountryContextProvider';
import { removeClientSideCookie } from 'services/session/cookies';
import { useTranslations } from 'services/translations/TranslationsProvider';
import localeSettings from 'src/utils/locales';
import changeLocale, { LocaleContext } from 'utils/changeLocale';
import { CookieNames } from 'utils/constants';
import { regionsInLocaleWithRegionInCountryLocale, languageInLocale } from 'utils/localeHelpers';

interface Props {
  onSuccess?: () => void;
  closeModal?: () => void;
  required?: boolean;
  translatedSlugs?: {
    en: string;
    nl: string;
    fr: string;
    de: string;
    it: string;
    es: string;
  };
}

const LocaleSelector: React.FC<Props> = ({
  onSuccess,
  closeModal,
  required = false,
  translatedSlugs,
}) => {
  const router = useRouter();
  const { locale } = router;
  const { countryCode, shippingCountries } = useCountryContext();
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslations('localeModal');
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(countryCode);
  const [selectedLocale, setSelectedLocale] = useState<string>(locale);

  const onChangeCountry = (option) => {
    if (option) {
      setSelectedCountryCode(option.value);
    }
  };

  const onChangeLocale = (option) => {
    if (option) {
      setSelectedLocale(option.value);
    }
  };

  if (!shippingCountries || !countryCode) {
    return (
      <Card>
        <div className="mx-auto">
          <Spinner />
        </div>
      </Card>
    );
  }

  // get shipping countries in alphabetical order (based on name and in the selected language)
  const sortedShippingCountries = regionsInLocaleWithRegionInCountryLocale(
    shippingCountries,
    locale,
  );

  const mainShippingCountries = sortedShippingCountries.filter((country) =>
    ['BE', 'DE', 'NL', 'FR', 'IT', 'ES', 'GB'].includes(country.code),
  );

  // Filter out shipping countries that can only be selected in actual addresses (hidden in localeSelector)
  const filteredAndSortedShippingCountries = sortedShippingCountries.filter(
    (country) =>
      !localeSettings.hiddenLocales.includes(country.code.toLowerCase()) &&
      !mainShippingCountries.includes(country),
  );

  const mapCountriesToSelectOptions = (countries: CountryWithProvinces[]) =>
    countries.map((shippingCountry) => ({
      label: shippingCountry.name,
      value: shippingCountry.code,
    }));

  return (
    <div className="flex flex-col flex-gap-4">
      <div>
        <CustomSelect
          selectLabel={t('country')}
          id="country-selector"
          options={[
            { label: undefined, options: mapCountriesToSelectOptions(mainShippingCountries) },
            {
              label: '',
              options: [
                ...mapCountriesToSelectOptions(filteredAndSortedShippingCountries),
                {
                  label: 'Other countries',
                  value: 'OTHER',
                },
              ],
            },
          ]}
          defaultValue={
            shippingCountries.find((c) => c.code.toUpperCase() === countryCode)
              ? countryCode
              : 'OTHER'
          }
          onChange={onChangeCountry}
          maxHeight={269}
          isCountrySelect
        />
      </div>
      <div className="mb-5">
        <CustomSelect
          selectLabel={t('language')}
          id="language-selector"
          options={localeSettings.locales.map((l) => ({
            label: languageInLocale(l, l),
            value: l,
          }))}
          defaultValue={selectedLocale}
          onChange={onChangeLocale}
          maxHeight={200}
        />
      </div>
      <Button
        legacy
        loading={isLoading}
        onClick={() => {
          if (selectedLocale !== locale || selectedCountryCode !== countryCode || required) {
            // When we change country, we need to clear the checkoutId cookie
            // so the checkout is recreated with the new country (mainly done to set the buyerIdentity for now)
            if (selectedCountryCode !== countryCode) removeClientSideCookie(CookieNames.CheckoutId);

            setLoading(true);

            changeLocale({
              router,
              newLocale: selectedLocale,
              newCountryCode: selectedCountryCode,
              forceRefresh: true,
              translatedSlugs,
            } as LocaleContext).then(onSuccess);
          } else if (closeModal) {
            closeModal();
          }
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default LocaleSelector;
