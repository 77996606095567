import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Select from 'react-select';

import colors from 'colors.js';
import Labelled from 'components/ui/Labelled';
import { useTranslations } from 'services/translations/TranslationsProvider';

import styles from '../Select/Select.module.css';

interface Option {
  /** Machine value of the option */
  value: string | number;
  /** Human-readable text for the option */
  label: string;
  /** Option will be visible, but not selectable */
  disabled?: boolean;
}

interface OptionsGroup {
  label: string;
  options: Array<Option>;
}

export interface Props {
  selectLabel: string;
  options: Array<OptionsGroup> | Array<Option>;
  labelHidden?: boolean;
  error?: string | boolean;
  id: string;
  name?: string;
  helpText?: string;
  onChange?: Dispatch<SetStateAction<string>>;
  isSearchable?: boolean;
  defaultValue?: string;
  maxHeight?: number;
  placeholder: string;
  /** Callback when input is focused */
}

const customStyles = {
  ...styles,
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: '1px solid rgb(333,333,333)',
    display: 'flex',
    borderRadius: '.25rem',
    fontSize: '1rem',
    '&:hover': {
      outlineColor: 'transparent',
    },
    outline: state.isFocused ? '2px solid rgb(100,204,201) !important' : 'none',

    // remove blue outline while typing
    '*': {
      boxShadow: 'none !important',
    },
    padding: '.2rem .3rem',
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    padding: '.25rem .75rem',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: '.25rem',
  }),
};

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: colors.blue[400],
    primary25: colors.cyan[200],
    primary50: colors.cyan[300],
    neutral80: colors.blue[400],
  },
});

const SearchableSelect: React.FC<Props> = ({
  selectLabel,
  options,
  labelHidden,
  error,
  id,
  name,
  helpText,
  onChange,
  defaultValue,
  maxHeight = 160,
  isSearchable = false,
  placeholder = '',
}: Props) => {
  const getOptionForDefaultValueOrFirstOption = (): Option => {
    if (options.length && (options[0] as OptionsGroup).options) {
      // grouped options react select
      let option;
      options.forEach((optionsGroup) => {
        const index = optionsGroup.options.findIndex((Option) => Option.value === defaultValue);
        if (index >= 0) {
          option = optionsGroup.options[index];
        }
      });

      return option;
    }
    if (options.length) {
      // Not grouped options
      const index = options.findIndex((Option) => Option.value === defaultValue);
      if (index >= 0) {
        return options[index] as Option;
      }
    }

    if (defaultValue === null || defaultValue === undefined) {
      return { value: defaultValue, label: placeholder };
    }

    return { value: defaultValue, label: defaultValue };
  };

  const [selectedOption, setSelectedOption] = useState<Option>(
    getOptionForDefaultValueOrFirstOption(),
  );

  const formatOptionLabel = ({ label }) => {
    if (label === placeholder) {
      return (
        <div className="flex items-center">
          <div className="text-gray-300">{label}</div>
        </div>
      );
    }
    return (
      <div className="flex items-center">
        <div className="text-base">{label}</div>
      </div>
    );
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  };

  useEffect(() => {
    handleChange(getOptionForDefaultValueOrFirstOption());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    setSelectedOption(selectedOption);
  }, [selectedOption]);

  const { t } = useTranslations('form');

  return (
    <Labelled
      id={id}
      label={selectLabel}
      labelHidden={labelHidden}
      error={error}
      helpText={helpText}
    >
      <Select
        maxMenuHeight={maxHeight}
        formatOptionLabel={formatOptionLabel}
        value={selectedOption}
        styles={(styles.select, customStyles)}
        isSearchable={isSearchable}
        onChange={handleChange}
        options={options}
        name={name}
        theme={customTheme}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
        components={{ IndicatorSeparator: () => null }}
      />
    </Labelled>
  );
};

export default SearchableSelect;
