import { useEffect, useState } from 'react';

import ECImage, { SrcOptions } from 'components/ui/ECImage';

export interface GalleryEntry {
  src: string;
  alt: string;
  thumbSrc: string;
  srcOptions?: SrcOptions;
  thumbSrcOptions?: SrcOptions;
}

export interface Props {
  entries: GalleryEntry[];
  defaultEntry?: GalleryEntry;
  onSlideChanged?: (index: number) => void;
}

const Gallery: React.FC<Props> = ({ entries, defaultEntry, onSlideChanged }) => {
  const [activeEntry, setActiveEntry] = useState(
    defaultEntry || entries.length ? entries[0] : null,
  );

  useEffect(() => {
    setActiveEntry(defaultEntry || entries.length ? entries[0] : null);
  }, [defaultEntry, entries]);

  useEffect(() => {
    const activeEntryIndex = entries.findIndex((entry) => entry === activeEntry);
    if (activeEntryIndex > -1) {
      onSlideChanged(activeEntryIndex);
    }
  }, [activeEntry, onSlideChanged, entries]);

  if (!entries.length) {
    return <></>;
  }

  return (
    <>
      <div className="max-w-xs md:max-w-md mx-auto">
        <ECImage img={activeEntry} srcOptions={activeEntry?.srcOptions} />
      </div>
      <div className="mt-8">
        <ul className="flex flex-wrap flex-gap-4 justify-center">
          {entries.map((entry) => (
            <li
              className={`flex justify-center w-16 h-16 bg-white rounded-sm ${
                activeEntry.src === entry.src ? 'bg-opacity-100' : 'bg-opacity-50'
              }`}
              key={entry.thumbSrc}
            >
              <div
                onClick={() => {
                  setActiveEntry(entry);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setActiveEntry(entry);
                  }
                }}
                className="flex outline-none p-1"
                tabIndex={0}
                role="button"
              >
                <ECImage
                  img={entry}
                  srcOptions={entry?.thumbSrcOptions}
                  pictureClassName="flex flex-col justify-center items-center"
                  className="max-h-full"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Gallery;
