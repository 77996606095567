import { ProductVariant } from 'api/generated-types';
import { capitalize, removeCatOrDogFromStartOfString } from 'services/analytics/mapper/helper';
import { ProductProtocol } from 'services/analytics/protocols';
import baseUrl from 'utils/baseUrl';

const parseUniversalKeyForProductTracking = (
  universalKey: string,
  index: number,
): string | null => {
  if (universalKey.startsWith('MKT_FLYER')) {
    if (index === 2) {
      return 'MKT_FLYER';
    }
    return null;
  }
  if (universalKey.startsWith('BUNDLE') && index === 2) {
    return 'BUNDLE';
  }
  const indexUniKey = universalKey.startsWith('BUNDLE') ? index + 1 : index;
  return universalKey.split('_').length >= 3
    ? universalKey.split('_')[indexUniKey].toUpperCase()
    : null;
};

const getCategoryFromUniversalKey = (universalKey: string): string | null =>
  parseUniversalKeyForProductTracking(universalKey, 2);

const getAgeFromUniversalKey = (universalKey: string): string | null =>
  parseUniversalKeyForProductTracking(universalKey, 1);

const getRangeFromUniversalKey = (universalKey: string): string | null =>
  parseUniversalKeyForProductTracking(universalKey, 0);

const mapProductToSegmentProtocol = (
  productVariant: ProductVariant,
  locale: string,
  minimisedInfo = false,
): ProductProtocol => {
  const product = {
    product_id: productVariant?.universalKey,
    universal_key: productVariant?.universalKey,
    shopify_variant_id: Number(productVariant?.identifiers?.shopifyProductVariantId),
    name: productVariant?.product?.content?.tracking || productVariant?.product?.content?.title,
    name_localized: productVariant?.product?.content?.title,
    sku: productVariant?.identifiers?.eanCode,
    categories: productVariant?.universalKey
      ? getCategoryFromUniversalKey(productVariant?.universalKey)
      : null,
    ages: productVariant?.universalKey
      ? getAgeFromUniversalKey(productVariant?.universalKey)
      : null,
    brand: 'Edgard & Cooper',
    range: productVariant?.universalKey
      ? getRangeFromUniversalKey(productVariant?.universalKey)
      : null,
    variant: productVariant?.content?.tracking || productVariant?.content?.title,
    product_url: [baseUrl, locale, 'products', productVariant?.product?.content?.handle].join('/'),
    image_url: `https:${productVariant?.product?.content?.featuredImage?.src}`,
    currency: productVariant?.actuals?.price?.currencyCode,
    price: productVariant?.actuals?.price?.amount,
    flavours: productVariant?.product?.masterData?.flavours.map((item) => item.key),
  };

  // We are running against the message limit of segment 32KB due to all the product info on the collection page.
  // Remove some fields to shrink the message back within limits
  // https://segment.com/docs/connections/sources/catalog/libraries/server/http-api/#max-request-size
  if (minimisedInfo) {
    product.brand = undefined;
    product.image_url = undefined;
    product.product_url = undefined;
    product.flavours = undefined;
  }

  return product;
};

const mapProductsToSegmentProtocol = (
  productVariants: ProductVariant[],
  locale: string,
  minimisedInfo = false,
): ProductProtocol[] => {
  const products = productVariants.map((productVariant) =>
    mapProductToSegmentProtocol(productVariant, locale, minimisedInfo),
  );
  return products;
};

const addPositionToProductList = (productsProtocolled) => {
  if (productsProtocolled.length) {
    return productsProtocolled.map((product, index) => ({
      ...product,
      position: index + 1,
    }));
  }
  return productsProtocolled;
};

const mapProductFilterstoSegmentProtocol = (activeFilters) => {
  const filter = {
    filters: [],
  };

  activeFilters
    .filter((filterWithOption) => filterWithOption.options.length > 0)
    .forEach((filterWithOption) => {
      filter[`filter_${filterWithOption.filter.slugQueryKey.replace('-', '_')}`] =
        filterWithOption.options.map((value) =>
          capitalize(removeCatOrDogFromStartOfString(value.value)),
        );
      filter.filters.push(capitalize(filterWithOption.filter.slugQueryKey));
    });

  return filter;
};

export {
  mapProductToSegmentProtocol,
  mapProductsToSegmentProtocol,
  addPositionToProductList,
  mapProductFilterstoSegmentProtocol,
};
